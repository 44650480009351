import { useCallback } from 'react';
import { FollowUp } from '@/types/followUp';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';
import { followUpStatusService } from '@/services/followUps/status';

export const useFollowUpStatusUpdate = (onSuccess: () => void) => {
  const { toast } = useToast();

  const handleUpdateFollowUp = useCallback(async (id: string, field: keyof FollowUp, value: string): Promise<void> => {
    try {
      // Special handling for status updates
      if (field === 'status') {
        await followUpStatusService.updateStatus(id, value as FollowUp['status']);
      } else {
        // For other fields
        const { error } = await supabase
          .from('follow_ups')
          .update({ [field]: value })
          .eq('id', id);

        if (error) {
          console.error(`Error updating follow-up ${field}:`, error);
          toast({
            title: "Error",
            description: `Failed to update ${field}`,
            variant: "destructive",
          });
          return;
        }
      }

      toast({
        title: "Success",
        description: `${field} updated successfully`,
      });

      onSuccess();
    } catch (error) {
      console.error(`Error updating follow-up ${field}:`, error);
      toast({
        title: "Error",
        description: `Failed to update ${field}`,
        variant: "destructive",
      });
    }
  }, [toast, onSuccess]);

  return { handleUpdateFollowUp };
};
