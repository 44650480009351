
import { createContext, useContext, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

interface RegistrationContextType {
  formData: {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
  };
  loading: boolean;
  disclaimerAccepted: boolean;
  handleFormChange: (field: string, value: string) => void;
  handleDisclaimerChange: (checked: boolean) => void;
  handleSubmit: (e: React.FormEvent) => Promise<void>;
}

const RegistrationContext = createContext<RegistrationContextType | undefined>(undefined);

export const useRegistration = () => {
  const context = useContext(RegistrationContext);
  if (!context) {
    throw new Error('useRegistration must be used within a RegistrationProvider');
  }
  return context;
};

interface RegistrationProviderProps {
  children: ReactNode;
}

export const RegistrationProvider = ({ children }: RegistrationProviderProps) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const handleFormChange = (field: string, value: string) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleDisclaimerChange = (checked: boolean) => {
    setDisclaimerAccepted(checked);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Starting registration process...');
    
    if (!disclaimerAccepted) {
      toast({
        title: "Error",
        description: "Please accept the disclaimer to continue",
        variant: "destructive",
      });
      return;
    }

    if (loading) {
      console.log('Already processing registration');
      return;
    }

    if (!formData.email || !formData.password || !formData.name) {
      console.log('Missing required fields');
      toast({
        title: "Error",
        description: "Please fill in all required fields",
        variant: "destructive",
      });
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      console.log('Passwords do not match');
      toast({
        title: "Error",
        description: "Passwords do not match",
        variant: "destructive",
      });
      return;
    }

    setLoading(true);
    console.log('Starting Supabase signup process');

    try {
      const { data: authData, error: signUpError } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          data: {
            username: formData.name,
          },
        },
      });

      console.log('Signup response:', { authData, error: signUpError });

      if (signUpError) {
        console.error('Signup error:', signUpError);
        throw signUpError;
      }

      if (!authData.user) {
        console.error('No user data returned from signup');
        throw new Error('No user data returned from signup');
      }

      console.log('Creating profile for user:', authData.user.id);

      const { error: profileError } = await supabase
        .from('profiles')
        .upsert([
          {
            id: authData.user.id,
            username: formData.name,
            email: formData.email,
            subscription_status: 'inactive',
            registration_completed: false,
            payment_completed: false
          }
        ], { onConflict: 'id' });

      if (profileError) {
        console.error('Profile creation error:', profileError);
        throw profileError;
      }

      console.log('Profile created successfully');
      
      toast({
        title: "Success",
        description: "Account created successfully! Please select a plan to continue.",
      });

      // Navigate to plans page instead of registration complete
      navigate('/plans');

    } catch (error: any) {
      console.error('Registration error:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to create account",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const value = {
    formData,
    loading,
    disclaimerAccepted,
    handleFormChange,
    handleDisclaimerChange,
    handleSubmit,
  };

  return (
    <RegistrationContext.Provider value={value}>
      {children}
    </RegistrationContext.Provider>
  );
};
