
import { Input } from "@/components/ui/input";

interface FormNextStepProps {
  nextStep: string;
  nextStepDate: string | null;
  nextStepWeek: string | null;
  onNextStepChange: (value: string) => void;
  onNextStepDateChange: (value: string) => void;
  onNextStepWeekChange: (value: string) => void;
}

const FormNextStep = ({
  nextStep,
  nextStepDate,
  nextStepWeek,
  onNextStepChange,
  onNextStepDateChange,
  onNextStepWeekChange,
}: FormNextStepProps) => {
  // Helper function to convert displayed date format (mm/dd/yy) to input format (yyyy-mm-dd)
  const formatDisplayDate = (inputDate: string | null) => {
    if (!inputDate) return '';
    
    // If already in yyyy-mm-dd format, return as is (for the date input)
    if (/^\d{4}-\d{2}-\d{2}$/.test(inputDate)) {
      return inputDate;
    }
    
    // If in mm/dd/yy format, convert to yyyy-mm-dd
    const [month, day, yearShort] = inputDate.split('/');
    const year = `20${yearShort}`; // Assuming 20xx years
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };

  return (
    <>
      <div className="space-y-1.5">
        <label className="text-xs font-medium">Next Step</label>
        <Input
          type="text"
          value={nextStep}
          onChange={(e) => onNextStepChange(e.target.value)}
          placeholder="Enter next step"
          required
          className="h-8 text-sm"
        />
      </div>
      <div className="space-y-1.5">
        <label className="text-xs font-medium">Next Step Timing</label>
        <div className="flex items-center gap-3">
          <div className="flex-1">
            <Input
              type="date"
              value={formatDisplayDate(nextStepDate)}
              onChange={(e) => onNextStepDateChange(e.target.value)}
              placeholder="Select next step date"
              className="h-8 text-sm"
            />
          </div>
          <span className="text-xs font-medium text-muted-foreground">or</span>
          <div className="flex-1">
            <Input
              type="number"
              min="1"
              max="52"
              value={nextStepWeek || ''}
              onChange={(e) => onNextStepWeekChange(e.target.value)}
              placeholder="Enter week number"
              className="h-8 text-sm"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FormNextStep;
