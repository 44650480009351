
import { Button } from "@/components/ui/button";
import { Trash2, ChevronDown, ChevronUp } from "lucide-react";

interface FollowUpActionsProps {
  isExpanded: boolean;
  isMobile: boolean;
  onDelete: () => void;
  onToggle: () => void;
}

const FollowUpActions = ({
  isExpanded,
  isMobile,
  onDelete,
  onToggle
}: FollowUpActionsProps) => {
  return (
    <div className="flex justify-end gap-1">
      <Button
        variant="ghost"
        size="sm"
        className="h-6 w-6 p-0 text-gray-400 hover:text-red-600"
        onClick={(e) => {
          e.stopPropagation();
          console.log('Delete button clicked');
          onDelete();
        }}
      >
        <Trash2 className="h-3 w-3" />
      </Button>
      
      {isMobile && (
        <Button
          variant="ghost"
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            onToggle();
          }}
          className="h-6 w-6 p-0"
        >
          {isExpanded ? (
            <ChevronUp className="h-3 w-3" />
          ) : (
            <ChevronDown className="h-3 w-3" />
          )}
        </Button>
      )}
    </div>
  );
};

export default FollowUpActions;
