
import React, { memo } from "react";
import { AlertCircle } from "lucide-react";

interface StatusErrorProps {
  error: string | null;
}

const StatusError: React.FC<StatusErrorProps> = ({ error }) => {
  if (!error) return null;
  
  return (
    <div className="absolute -bottom-5 left-0 text-xs text-red-600 flex items-center gap-1 whitespace-nowrap">
      <AlertCircle className="h-3 w-3" />
      <span>{error}</span>
    </div>
  );
};

// Memoize to prevent unnecessary re-renders
export default memo(StatusError);
