
import { useState } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Client } from "@/types/client";
import { ClientsList } from "@/components/clients/ClientsList";
import ClientForm from "@/components/clients/ClientForm";
import ClientsPanelHeader from "@/components/clients/ClientsPanelHeader";
import ClientSearchBar from "@/components/clients/ClientSearchBar";
import ClientListPagination from "@/components/clients/ClientListPagination";
import { useClientsData } from "@/hooks/useClientsData";

interface ClientsListPanelProps {
  selectedIndustry: string;
  onClientSelect: (client: Client) => void;
  selectedClient: Client | null;
}

const ClientsListPanel = ({ 
  selectedIndustry, 
  onClientSelect, 
  selectedClient 
}: ClientsListPanelProps) => {
  const [showForm, setShowForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [industries] = useState<{ name: string; color: string; }[]>([
    { name: "Technology", color: "bg-blue-400" },
    { name: "Healthcare", color: "bg-green-400" },
    { name: "Finance", color: "bg-purple-400" },
    { name: "Manufacturing", color: "bg-yellow-400" },
    { name: "Retail", color: "bg-red-400" },
    { name: "Other", color: "bg-gray-400" }
  ]);

  const { 
    clients, 
    currentPage, 
    setCurrentPage, 
    fetchClients, 
    itemsPerPage 
  } = useClientsData(selectedIndustry, searchQuery);

  const totalPages = Math.ceil(clients.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentClients = clients.slice(startIndex, endIndex);

  const handleSuccess = () => {
    setShowForm(false);
  };

  const handleCancel = () => {
    setShowForm(false);
  };

  const handleAddClient = () => {
    setShowForm(true);
  };

  // Reset client selection when industry is removed
  if (!selectedIndustry && selectedClient) {
    onClientSelect(null);
  }

  return (
    <Card className="flex flex-col border shadow-sm">
      <ClientsPanelHeader onAddClient={handleAddClient} />
      <CardContent className="p-0 flex-1 flex flex-col">
        <ClientSearchBar 
          searchQuery={searchQuery} 
          setSearchQuery={setSearchQuery} 
        />
        {showForm ? (
          <div className="p-3">
            <ClientForm onSuccess={handleSuccess} onCancel={handleCancel} />
          </div>
        ) : (
          <>
            <div className="flex-1">
              <ClientsList
                clients={currentClients}
                selectedClient={selectedClient}
                onClientSelect={onClientSelect}
                industries={industries}
                onClientDeleted={fetchClients}
              />
            </div>
            <ClientListPagination 
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ClientsListPanel;
