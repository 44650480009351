
import { Calendar } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import AddStageDialog from "./AddStageDialog";

interface StagesHeaderProps {
  onAddStage: (name: string) => Promise<void>;
}

const StagesHeader = ({ onAddStage }: StagesHeaderProps) => {
  const navigate = useNavigate();
  
  return (
    <div className="flex items-center justify-between mb-6">
      <div className="flex items-center gap-4">
        <h1 className="text-2xl font-bold">Pipeline</h1>
        <Button 
          variant="outline" 
          size="sm"
          onClick={() => navigate('/weeks')}
          className="flex items-center gap-1"
        >
          <Calendar className="h-4 w-4" />
          <span>Weeks</span>
        </Button>
      </div>
      
      <AddStageDialog onAddStage={onAddStage} />
    </div>
  );
};

export default StagesHeader;
