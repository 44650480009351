
import { useState, useCallback, useEffect, useRef } from 'react';
import { FollowUp } from '@/types/followUp';
import { followUpService } from '@/services/followUps';
import { useRealtimeUpdates } from './useRealtimeUpdates';
import { supabase } from '@/integrations/supabase/client';

export const useFollowUpFetch = (clientId: number | undefined) => {
  const [followUps, setFollowUps] = useState<FollowUp[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isMountedRef = useRef(true);
  const lastFetchTimeRef = useRef<number | null>(null);
  const pendingRefreshRef = useRef<NodeJS.Timeout | null>(null);
  const forceRefreshRef = useRef<boolean>(false);
  const updatingRef = useRef<boolean>(false);
  const fetchCountRef = useRef<number>(0);
  const fetchQueueRef = useRef<{clientId: number, force: boolean}[]>([]);
  const processingQueueRef = useRef<boolean>(false);

  // Completely redesigned fetch processor that only processes one fetch at a time
  const processFetchQueue = useCallback(async () => {
    if (processingQueueRef.current || fetchQueueRef.current.length === 0) {
      return;
    }
    
    processingQueueRef.current = true;
    
    try {
      // Get the most recent fetch request, prioritizing force refreshes
      let nextFetch = fetchQueueRef.current.find(item => item.force === true);
      
      // If no force refresh, get the latest request
      if (!nextFetch) {
        nextFetch = fetchQueueRef.current[fetchQueueRef.current.length - 1];
      }
      
      // Clear the queue
      fetchQueueRef.current = [];
      
      if (!nextFetch || !nextFetch.clientId) {
        processingQueueRef.current = false;
        return;
      }
      
      // Set updating flag to prevent concurrent fetches
      updatingRef.current = true;
      
      // Track fetch count for debugging
      const fetchCount = ++fetchCountRef.current;
      
      // Update last fetch time for throttling
      lastFetchTimeRef.current = Date.now();
      
      try {
        setIsLoading(fetchCount === 1); // Only show loading on first fetch
        console.log(`Processing queued fetch for client: ${nextFetch.clientId}, Force: ${nextFetch.force}, Count: ${fetchCount}`);
        
        // Get the current user ID
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          console.error('No authenticated user found');
          return;
        }
        
        // Add a small delay to ensure UI has settled
        await new Promise(resolve => setTimeout(resolve, 100));
        
        // Fetch data with skipBackgroundFetch=true to prevent recursive fetching
        const fetchedFollowUps = await followUpService.fetchClientFollowUps(nextFetch.clientId, user.id, true);
        
        if (isMountedRef.current) {
          console.log(`Fetched ${fetchedFollowUps.length} follow-ups for fetch #${fetchCount}`);
          
          // Set state with a small delay to avoid UI conflicts
          setFollowUps(fetchedFollowUps);
        }
      } catch (error) {
        console.error(`Error in fetch queue processing (fetch #${fetchCount}):`, error);
      } finally {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      }
    } finally {
      // Add a significant delay before allowing next queue processing
      setTimeout(() => {
        updatingRef.current = false;
        processingQueueRef.current = false;
        
        // If new items were added to queue while processing, process them after a delay
        if (fetchQueueRef.current.length > 0) {
          setTimeout(processFetchQueue, 1000);
        }
      }, 2000);
    }
  }, []);

  // Queue-based fetch follow-ups implementation
  const fetchFollowUps = useCallback(async (forceRefresh = false) => {
    if (!clientId) {
      setFollowUps([]);
      return;
    }

    // Extreme throttling for non-forced refreshes
    const now = Date.now();
    if (
      !forceRefresh && 
      lastFetchTimeRef.current && 
      now - lastFetchTimeRef.current < 6000 // Doubled from 3000 to 6000ms
    ) {
      console.log('Extreme throttling of fetch requests, will queue refresh in 6s');
      
      // Clear any existing pending refresh
      if (pendingRefreshRef.current) {
        clearTimeout(pendingRefreshRef.current);
      }
      
      // Schedule a refresh after the throttle period
      pendingRefreshRef.current = setTimeout(() => {
        pendingRefreshRef.current = null;
        fetchFollowUps(true);
      }, 6000); // Doubled from 3000 to 6000ms
      
      return;
    }

    // Add to fetch queue instead of executing immediately
    console.log(`Queueing fetch for client: ${clientId}, Force refresh: ${forceRefresh}`);
    fetchQueueRef.current.push({ clientId, force: forceRefresh });
    
    // Process the queue if not already processing
    if (!processingQueueRef.current) {
      // Add a small delay before processing to batch rapid requests
      setTimeout(processFetchQueue, 100);
    }
  }, [clientId, processFetchQueue]);

  // Invalidate client cache - completely redesigned
  const invalidateClientCache = useCallback((clientId: number) => {
    console.log('Manually invalidating follow-up cache for client:', clientId);
    
    // Clear any pending refresh
    if (pendingRefreshRef.current) {
      clearTimeout(pendingRefreshRef.current);
      pendingRefreshRef.current = null;
    }
    
    // Queue a forced refresh with high priority
    fetchQueueRef.current.push({ clientId, force: true });
    
    // Process queue if not already processing
    if (!processingQueueRef.current) {
      // Add a small delay to batch rapid invalidations
      setTimeout(processFetchQueue, 100);
    }
  }, [processFetchQueue]);

  // Setup event listener for manual invalidation with improved throttling
  useEffect(() => {
    const lastInvalidationTimeRef = {current: 0};
    
    const handleInvalidateCache = (event: CustomEvent) => {
      const eventClientId = event.detail?.clientId;
      const source = event.detail?.source || 'unknown';
      const now = Date.now();
      
      // Skip if invalidation happened very recently
      if (now - lastInvalidationTimeRef.current < 6000) { // Doubled from 3000 to 6000ms
        console.log('Extreme throttling of cache invalidation, too recent');
        return;
      }
      
      lastInvalidationTimeRef.current = now;
      
      console.log(
        'Received cache invalidation event:',
        'clientId:', eventClientId,
        'current clientId:', clientId,
        'source:', source
      );
      
      if (eventClientId && clientId === eventClientId) {
        // Queue a fetch instead of executing immediately
        fetchQueueRef.current.push({ clientId: eventClientId, force: true });
        
        // Process queue if not already processing, but with a delay
        if (!processingQueueRef.current) {
          setTimeout(processFetchQueue, 1000);
        }
      }
    };

    window.addEventListener('invalidate-followup-cache', handleInvalidateCache as EventListener);
    
    return () => {
      window.removeEventListener('invalidate-followup-cache', handleInvalidateCache as EventListener);
    };
  }, [clientId, processFetchQueue]);

  // Define a callback function for realtime updates with extreme throttling
  const handleRealtimeUpdate = useCallback((payload: any) => {
    console.log('Realtime update received:', payload.eventType);

    // Check if we're already processing or updated recently
    const now = Date.now();
    if (updatingRef.current || (lastFetchTimeRef.current && now - lastFetchTimeRef.current < 5000)) {
      console.log('Throttling realtime update, fetch already in progress or too recent');
      return;
    }

    // Queue a refresh instead of immediate execution
    if (clientId) {
      fetchQueueRef.current.push({ clientId, force: false });
      
      // Process queue with a significant delay
      if (!processingQueueRef.current) {
        setTimeout(processFetchQueue, 2000);
      }
    }
  }, [clientId, processFetchQueue]);

  // Pass the client ID and callback function to useRealtimeUpdates
  useRealtimeUpdates(clientId, handleRealtimeUpdate);

  // Fetch follow-ups when clientId changes
  useEffect(() => {
    isMountedRef.current = true;
    fetchCountRef.current = 0;
    
    // Clear any pending operations when client changes
    if (pendingRefreshRef.current) {
      clearTimeout(pendingRefreshRef.current);
      pendingRefreshRef.current = null;
    }
    
    // Reset fetch queue when client changes
    fetchQueueRef.current = [];
    processingQueueRef.current = false;
    
    if (clientId) {
      // Slight delay on initial fetch to allow components to mount properly
      const initialFetchTimeout = setTimeout(() => {
        fetchFollowUps(true); // Force refresh on client change
      }, 500);
      
      return () => {
        clearTimeout(initialFetchTimeout);
      };
    }
    
    return () => {
      isMountedRef.current = false;
    };
  }, [clientId, fetchFollowUps]);

  return {
    followUps,
    isLoading,
    fetchFollowUps,
    setFollowUps,
    invalidateClientCache
  };
};
