
import { createFollowUp } from './createFollowUp';
import { updateFollowUp } from './updateFollowUp';
import { deleteFollowUp } from './deleteFollowUp';
import { fetchClientFollowUps } from './fetchClientFollowUps';

// Export all CRUD functions as a combined service object
export const followUpCrudService = {
  createFollowUp,
  updateFollowUp,
  deleteFollowUp,
  fetchClientFollowUps
};
