
import React, { useState } from "react";
import { FollowUp } from "@/types/followUp";
import FollowUpStatusCell from "./FollowUpStatusCell";
import FollowUpEditableCell from "./FollowUpEditableCell";
import FollowUpActionCell from "./FollowUpActionCell";
import FollowUpMobileDetails from "./FollowUpMobileDetails";
import FollowUpActions from "./FollowUpActions";
import { formatDate } from "./DateFormatter";

interface TableRowProps {
  followUp: FollowUp;
  isMobile: boolean;
  onUpdateFollowUp: (id: string, field: keyof FollowUp, value: string) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  isDeleting?: boolean;
}

const TableRow: React.FC<TableRowProps> = ({ 
  followUp, 
  isMobile, 
  onUpdateFollowUp,
  onDelete,
  isDeleting = false
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleDelete = async () => {
    if (isDeleting) return;
    try {
      console.log("TableRow requesting delete for followUp ID:", followUp.id);
      await onDelete(followUp.id);
    } catch (error) {
      console.error("Error in TableRow delete handler:", error);
    }
  };

  if (isDeleting) {
    return null;
  }

  const combinedActionDescription = followUp.description 
    ? `${followUp.action}: ${followUp.description}` 
    : followUp.action;

  return (
    <>
      <tr 
        className={`border-b hover:bg-gray-50 ${isExpanded ? 'bg-gray-50' : ''} transition-colors cursor-pointer`}
        onClick={() => isMobile && toggleExpanded()}
      >
        <td className="px-1 py-1 w-20 text-xs">
          {formatDate(followUp.date)}
        </td>
        <td className="px-1 py-1 w-24">
          <FollowUpStatusCell 
            followUp={followUp} 
            onChange={(id, status) => onUpdateFollowUp(id, 'status', status)} 
          />
        </td>
        <td className="px-1 py-1">
          <FollowUpEditableCell 
            value={combinedActionDescription} 
            onSave={(value) => {
              // Split value back into action and description when saving
              const [action, ...descriptionParts] = value.split(': ');
              const description = descriptionParts.join(': ');
              onUpdateFollowUp(followUp.id, 'action', action);
              if (description) {
                onUpdateFollowUp(followUp.id, 'description', description);
              }
            }}
            type="text"
          />
        </td>
        <td className="hidden md:table-cell px-1 py-1">
          <FollowUpEditableCell 
            value={followUp.next_step} 
            onSave={(value) => onUpdateFollowUp(followUp.id, 'next_step', value)}
            type="text"
          />
        </td>
        <td className="px-1 py-1">
          <FollowUpActions 
            isExpanded={isExpanded}
            isMobile={isMobile}
            onDelete={() => onDelete(followUp.id)}
            onToggle={toggleExpanded}
          />
        </td>
      </tr>
      {isMobile && isExpanded && (
        <FollowUpMobileDetails 
          followUp={followUp}
          onUpdate={onUpdateFollowUp}
        />
      )}
    </>
  );
};

export default TableRow;

