
import { useCallback } from 'react';
import { FollowUp } from '@/types/followUp';
import { useFollowUpSave } from './mutations/useFollowUpSave';
import { useFollowUpDelete } from './mutations/useFollowUpDelete';
import { useFollowUpPriorityUpdate } from './mutations/useFollowUpPriorityUpdate';
import { useFollowUpStatusUpdate } from './mutations/useFollowUpStatusUpdate';
import { useFollowUpEvents } from './mutations/useFollowUpEvents';

export const useFollowUpMutations = (
  clientId: number | undefined,
  onSuccess: () => void
) => {
  // Use the individual mutation hooks
  const { handleSave } = useFollowUpSave(clientId, onSuccess);
  const { handleDelete } = useFollowUpDelete(onSuccess);
  const { handleUpdatePriority } = useFollowUpPriorityUpdate(onSuccess);
  const { handleUpdateFollowUp } = useFollowUpStatusUpdate(onSuccess);
  
  // Set up event listeners
  useFollowUpEvents(onSuccess);

  return {
    handleSave,
    handleDelete,
    handleUpdatePriority,
    handleUpdateFollowUp
  };
};
