
// Barrel file for followUpService modules
import { supabase } from "@/integrations/supabase/client";
import { followUpAuthService } from './followUpAuthService';
import { clientLookupService } from './client'; // Corrected import
import { followUpCrudService } from './crud';
import { followUpTodoService } from './followUpTodoService';
import { followUpStatusService } from './status';

// Export all services as a single object
export const followUpService = {
  ...followUpAuthService,
  ...clientLookupService, // Changed to use correct import
  ...followUpCrudService,
  ...followUpTodoService,
  ...followUpStatusService,
  
  // Add a direct method to update color with extensive fallback mechanisms
  updateColor: async (id: string, color: string): Promise<boolean> => {
    console.log('followUpService.updateColor called with:', { id, color });
    try {
      // First try the specialized bypass function
      const { data: bypassData, error: bypassError } = await supabase
        .rpc('update_follow_up_color_bypass', {
          p_follow_up_id: id,
          p_color: color
        });

      if (!bypassError && bypassData === true) {
        console.log('Direct color update successful with bypass function');
        
        // Dispatch a color update event for UI updating
        window.dispatchEvent(new CustomEvent('follow-up-color-updated', {
          detail: { id, color, source: 'direct' }
        }));
        
        return true;
      }
      
      console.log('Direct update failed in barrel file, delegating to service implementation');
      return await followUpStatusService.updateColor(id, color);
    } catch (error) {
      console.error('Error in followUpService.updateColor:', error);
      return followUpStatusService.updateColor(id, color);
    }
  }
};
