
import { useFollowUps } from "@/hooks/useFollowUps";
import { Client } from "@/types/client";
import { useEffect } from "react";
import { useFollowUpScroll } from "@/hooks/useFollowUpScroll";
import { useFollowUpClientTracking } from "@/hooks/useFollowUpClientTracking";
import { useFollowUpPanelEvents } from "@/hooks/useFollowUpPanelEvents";
import FollowUpPanelContent from "@/components/follow-up/panel/FollowUpPanelContent";

interface FollowUpPanelProps {
  selectedClient: Client | null;
  activePanel: string;
}

const FollowUpPanel = ({ selectedClient, activePanel }: FollowUpPanelProps) => {
  console.log("FollowUpPanel rendering with client:", selectedClient?.company, "and activePanel:", activePanel);
  
  const { scrollPanelIntoView } = useFollowUpScroll();
  
  const {
    currentClient,
    followUps,
    sortConfig,
    showForm,
    isLoading,
    isSubmitting,
    setShowForm,
    handleSort,
    handleSave,
    handleDelete,
    handleUpdatePriority,
    handleUpdateFollowUp,
    fetchFollowUps,
    invalidateClientCache
  } = useFollowUps(selectedClient);

  // Use custom hooks to manage panel behaviors
  useFollowUpClientTracking({
    selectedClient,
    activePanel,
    fetchFollowUps,
    scrollPanelIntoView,
    invalidateClientCache
  });

  useFollowUpPanelEvents({
    selectedClient,
    activePanel,
    fetchFollowUps,
    scrollPanelIntoView,
    invalidateClientCache
  });

  // Add a specific class to scroll the panel into view when it becomes active
  const isActive = activePanel === 'follow-up' && !!selectedClient;
  
  // Add CSS to help with scrolling to the follow-up panel
  useEffect(() => {
    // Add a small style tag for the follow-up-active class if it doesn't exist
    if (!document.getElementById('follow-up-styles')) {
      const style = document.createElement('style');
      style.id = 'follow-up-styles';
      style.innerHTML = `
        .follow-up-active {
          scroll-margin-top: 16px;
        }
      `;
      document.head.appendChild(style);
    }
    
    return () => {
      const styleElement = document.getElementById('follow-up-styles');
      if (styleElement) {
        styleElement.remove();
      }
    };
  }, []);
  
  return (
    <FollowUpPanelContent
      isActive={isActive}
      currentClient={currentClient}
      followUps={followUps}
      sortConfig={sortConfig}
      showForm={showForm}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      setShowForm={setShowForm}
      handleSort={handleSort}
      handleSave={handleSave}
      handleDelete={handleDelete}
      handleUpdatePriority={handleUpdatePriority}
      handleUpdateFollowUp={handleUpdateFollowUp}
    />
  );
};

export default FollowUpPanel;
