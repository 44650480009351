
import { useCallback, useEffect, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Client } from "@/types/client";

interface UseFollowUpPanelEventsProps {
  selectedClient: Client | null;
  activePanel: string;
  fetchFollowUps: (force?: boolean) => void;
  scrollPanelIntoView: () => void;
  invalidateClientCache: (clientId: number) => void;
}

export const useFollowUpPanelEvents = ({
  selectedClient,
  activePanel,
  fetchFollowUps,
  scrollPanelIntoView,
  invalidateClientCache
}: UseFollowUpPanelEventsProps) => {
  const visibilityCheckedRef = useRef(false);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  
  // Check for URL parameters directly in the panel
  useEffect(() => {
    const showFollowUp = searchParams.get('followUp') === 'true';
    const clientId = searchParams.get('clientId');
    
    if (showFollowUp && clientId && selectedClient?.id === parseInt(clientId, 10)) {
      console.log('FollowUpPanel: URL params indicate this panel should be visible');
      if (activePanel === 'follow-up') {
        scrollPanelIntoView();
        fetchFollowUps(true);
      }
    }
  }, [searchParams, selectedClient, activePanel, scrollPanelIntoView, fetchFollowUps]);

  // Listen for the force-panel-activate event
  useEffect(() => {
    const handleForcePanelActivate = (event: CustomEvent) => {
      if (event.detail?.panel === 'follow-up' && selectedClient?.id) {
        console.log('FollowUpPanel: Forced activation detected from', event.detail?.source || 'unknown source');
        fetchFollowUps(true);
        scrollPanelIntoView();
      }
    };

    // Listen for scroll requests
    const handleScrollRequest = () => {
      console.log('FollowUpPanel: Received scroll request');
      if (selectedClient?.id) {
        scrollPanelIntoView();
      }
    };

    // Listen for panel visibility events
    const handlePanelVisible = (event: CustomEvent) => {
      if (event.detail?.clientId === selectedClient?.id) {
        console.log('FollowUpPanel: Panel visibility event received');
        visibilityCheckedRef.current = true;
      }
    };

    // Listen for navigation state change events
    const handleNavigationStateChange = () => {
      if (selectedClient?.id && activePanel === 'follow-up') {
        console.log('FollowUpPanel: Navigation state change detected');
        fetchFollowUps(true);
        scrollPanelIntoView();
      }
    };

    window.addEventListener('force-panel-activate', handleForcePanelActivate as EventListener);
    window.addEventListener('scroll-to-followup', handleScrollRequest as EventListener);
    window.addEventListener('followup-panel-visible', handlePanelVisible as EventListener);
    window.addEventListener('popstate', handleNavigationStateChange);
    
    return () => {
      window.removeEventListener('force-panel-activate', handleForcePanelActivate as EventListener);
      window.removeEventListener('scroll-to-followup', handleScrollRequest as EventListener);
      window.removeEventListener('followup-panel-visible', handlePanelVisible as EventListener);
      window.removeEventListener('popstate', handleNavigationStateChange);
    };
  }, [selectedClient, fetchFollowUps, scrollPanelIntoView, activePanel]);

  // Check if we need to scroll after a small delay (fallback)
  useEffect(() => {
    if (activePanel === 'follow-up' && selectedClient && !visibilityCheckedRef.current) {
      const timeoutId = setTimeout(() => {
        console.log('FollowUpPanel: Fallback visibility check executing');
        scrollPanelIntoView();
        visibilityCheckedRef.current = true;
      }, 500);
      
      return () => clearTimeout(timeoutId);
    }
  }, [activePanel, selectedClient, scrollPanelIntoView]);

  return { visibilityCheckedRef };
};
