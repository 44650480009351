
import { useState, useEffect, useRef } from "react";
import { ChevronLeft, ChevronRight, CalendarDays, Workflow } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Header from "@/components/Header";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { ScrollArea } from "@/components/ui/scroll-area";
import ColorPicker from "@/components/clients/ColorPicker";
import { followUpService } from "@/services/followUps";

interface WeekEvent {
  id: string;
  text: string;
  client: string;
  clientId: number;
  week: number;
  month: number;
  year: number;
  status: string;
  color: string;
  action: string;
  sequenceNumber?: number;
}

const Weeks = () => {
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  const [events, setEvents] = useState<WeekEvent[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingColor, setIsUpdatingColor] = useState(false);
  const [updatingEventId, setUpdatingEventId] = useState<string | null>(null);
  const navigate = useNavigate();
  const { toast } = useToast();
  const eventsRef = useRef<WeekEvent[]>([]);

  useEffect(() => {
    eventsRef.current = events;
  }, [events]);

  const monthsData = [
    { name: "January", weeks: getWeeksInMonth(0, currentYear) },
    { name: "February", weeks: getWeeksInMonth(1, currentYear) },
    { name: "March", weeks: getWeeksInMonth(2, currentYear) },
    { name: "April", weeks: getWeeksInMonth(3, currentYear) },
    { name: "May", weeks: getWeeksInMonth(4, currentYear) },
    { name: "June", weeks: getWeeksInMonth(5, currentYear) },
    { name: "July", weeks: getWeeksInMonth(6, currentYear) },
    { name: "August", weeks: getWeeksInMonth(7, currentYear) },
    { name: "September", weeks: getWeeksInMonth(8, currentYear) },
    { name: "October", weeks: getWeeksInMonth(9, currentYear) },
    { name: "November", weeks: getWeeksInMonth(10, currentYear) },
    { name: "December", weeks: getWeeksInMonth(11, currentYear) },
  ];

  function getWeeksInMonth(month: number, year: number): number[] {
    const weeks: number[] = [];
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    
    let currentDate = new Date(firstDay);
    while (currentDate.getDay() !== 1) {
      currentDate.setDate(currentDate.getDate() - 1);
    }
    
    while (currentDate <= lastDay) {
      const weekNumber = getWeekNumber(currentDate);
      if (!weeks.includes(weekNumber)) {
        weeks.push(weekNumber);
      }
      currentDate.setDate(currentDate.getDate() + 7);
    }
    
    return weeks;
  }

  function getWeekNumber(date: Date): number {
    const target = new Date(date);
    target.setHours(0, 0, 0, 0);
    target.setDate(target.getDate() + 3 - (target.getDay() + 6) % 7);
    const jan4 = new Date(target.getFullYear(), 0, 4);
    const weekNumber = 1 + Math.round(((target.getTime() - jan4.getTime()) / 86400000 - 3 + (jan4.getDay() + 6) % 7) / 7);
    return weekNumber;
  }

  const convertToPastTense = (action: string): string => {
    if (!action) return "";
    
    if (action.toLowerCase() === "call") return "Called";
    if (action.toLowerCase() === "email") return "eMailed";
    
    return action;
  };

  const fetchCompletedEvents = async () => {
    try {
      setIsLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data: followUps, error } = await supabase
        .from('follow_ups')
        .select(`
          id,
          action,
          description,
          client_id,
          status,
          date,
          color,
          week,
          clients(id, company)
        `)
        .eq('user_id', user.id)
        .eq('status', 'completed');

      if (error) {
        console.error('Error fetching follow-ups:', error);
        throw error;
      }

      if (followUps) {
        const processedEvents: WeekEvent[] = followUps.map(followUp => {
          const eventDate = new Date(followUp.date);
          const month = eventDate.getMonth();
          const year = eventDate.getFullYear();
          
          const week = followUp.week || getWeekNumber(eventDate);
          
          return {
            id: followUp.id,
            text: followUp.description || "",
            action: followUp.action || "",
            client: followUp.clients ? followUp.clients.company : 'Unknown',
            clientId: followUp.client_id,
            week,
            month,
            year,
            status: followUp.status,
            color: followUp.color || 'bg-green-500'
          };
        }).filter(event => event.year === currentYear);
        
        console.log('Processed events before sequence:', processedEvents.length);
        const eventsWithSequence = addSequenceNumbers(processedEvents);
        console.log('Events with sequence:', eventsWithSequence.length);
        
        setEvents(eventsWithSequence);
      }
    } catch (error) {
      console.error('Error fetching completed events:', error);
      toast({
        title: "Error",
        description: "Failed to fetch completed events",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const addSequenceNumbers = (events: WeekEvent[]): WeekEvent[] => {
    const eventsByWeekAndClient: Record<string, WeekEvent[]> = {};
    
    events.forEach(event => {
      const key = `${event.week}-${event.client}`;
      if (!eventsByWeekAndClient[key]) {
        eventsByWeekAndClient[key] = [];
      }
      eventsByWeekAndClient[key].push(event);
    });
    
    console.log('Event groups created:', Object.keys(eventsByWeekAndClient).length);
    
    const result: WeekEvent[] = [];
    
    Object.entries(eventsByWeekAndClient).forEach(([key, clientEvents]) => {
      console.log(`Processing group ${key} with ${clientEvents.length} events`);
      
      if (clientEvents.length === 1) {
        result.push(clientEvents[0]);
      } else {
        clientEvents.forEach((event, index) => {
          result.push({
            ...event,
            sequenceNumber: index + 1
          });
        });
      }
    });
    
    console.log('Final events count:', result.length);
    return result;
  };

  const handleYearChange = (increment: number) => {
    setCurrentYear(prev => prev + increment);
  };

  const resetEventsForNewYear = async () => {
    if (currentYear > new Date().getFullYear()) {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;

        await supabase
          .from('follow_ups')
          .update({ status: 'archived' })
          .eq('user_id', user.id)
          .eq('status', 'completed');

        setEvents([]);
        toast({
          title: "Success",
          description: `All completed events have been archived for the new year ${currentYear}`,
        });
      } catch (error) {
        console.error('Error archiving events:', error);
        toast({
          title: "Error",
          description: "Failed to archive events for the new year",
          variant: "destructive",
        });
      }
    }
  };

  const navigateToClientFollowUp = (clientId: number, e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    console.log('Navigating to client follow up with ID:', clientId);
    
    // Use URL parameters to ensure state persistence and proper activation
    navigate(`/index?followUp=true&clientId=${clientId}`, { 
      state: { 
        selectedClient: { id: clientId },
        activePanel: 'follow-up',
        forceActivate: true,
        source: 'weeks-page'
      },
      replace: false
    });
  };

  const handleChangeColor = async (eventId: string, color: string, e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    if (isUpdatingColor && updatingEventId === eventId) {
      return false;
    }
    
    try {
      setIsUpdatingColor(true);
      setUpdatingEventId(eventId);
      
      setEvents(prevEvents => 
        prevEvents.map(event => 
          event.id === eventId ? { ...event, color } : event
        )
      );

      console.log(`Updating follow-up ID: ${eventId} with color: ${color}`);
      
      const success = await followUpService.updateColor(eventId, color);

      if (!success) {
        console.error('Failed to update color in service call');
        
        const { error: directError } = await supabase
          .from('follow_ups')
          .update({ color })
          .eq('id', eventId);
          
        if (directError) {
          console.error('Final direct update attempt also failed:', directError);
          
          await fetchCompletedEvents(); 
          
          toast({
            title: "Error",
            description: "Failed to update bullet color",
            variant: "destructive",
          });
        } else {
          console.log('Final direct update succeeded');
          toast({
            title: "Success",
            description: "Bullet color updated",
          });
        }
      } else {
        toast({
          title: "Success",
          description: "Bullet color updated",
        });
      }
    } catch (error) {
      console.error('Error updating bullet color:', error);
      
      await fetchCompletedEvents();
      
      toast({
        title: "Error",
        description: "Failed to update bullet color",
        variant: "destructive",
      });
    } finally {
      setIsUpdatingColor(false);
      setUpdatingEventId(null);
    }
    
    return false;
  };

  useEffect(() => {
    fetchCompletedEvents();
    
    if (currentYear > new Date().getFullYear()) {
      resetEventsForNewYear();
    }
    
    const channel = supabase
      .channel('weeks-follow-ups-changes')
      .on(
        'postgres_changes',
        { 
          event: '*', 
          schema: 'public', 
          table: 'follow_ups',
          filter: 'status=eq.completed'
        },
        (payload) => {
          console.log('Follow-up changed in Weeks component:', payload);
          
          if (payload.eventType === 'UPDATE' && 
              payload.new && 
              payload.old && 
              'color' in payload.new && 
              'color' in payload.old) {
            console.log('Follow-up color update detected, refreshing data');
            fetchCompletedEvents();
          } else {
            fetchCompletedEvents();
          }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [currentYear]);

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <div className="container mx-auto p-4 max-w-7xl">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-4">
            <h1 className="text-2xl font-bold">Weekly Log</h1>
            <Button 
              variant="outline" 
              size="sm"
              onClick={() => navigate('/stages')}
              className="flex items-center gap-1"
            >
              <Workflow className="h-4 w-4" />
              <span>Pipeline</span>
            </Button>
          </div>
          
          <div className="flex items-center gap-2">
            <Button 
              variant="outline" 
              size="sm"
              onClick={() => handleYearChange(-1)}
            >
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <span className="font-medium">{currentYear}</span>
            <Button 
              variant="outline" 
              size="sm"
              onClick={() => handleYearChange(1)}
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center my-12">
            <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary"></div>
          </div>
        ) : (
          <div className="space-y-6">
            {monthsData.map((month, idx) => (
              <Card key={idx} className="overflow-hidden">
                <CardHeader className="bg-gray-50/80 p-2">
                  <CardTitle className="text-sm font-medium text-gray-700 flex items-center gap-2">
                    <CalendarDays className="h-4 w-4 text-gray-600" />
                    {month.name}
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-0">
                  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-3 p-3">
                    {month.weeks.map((weekNum) => {
                      const weekEvents = events
                        .filter(e => e.week === weekNum)
                        .sort((a, b) => a.client.localeCompare(b.client));
                      
                      return (
                        <div key={weekNum} className="border rounded shadow-sm p-2 min-h-[140px] bg-white">
                          <div className="text-xs font-medium text-gray-700 border-b pb-1 mb-1 flex items-center gap-1">
                            <CalendarDays className="h-3 w-3 text-gray-500" />
                            {currentYear} Week {weekNum}
                          </div>
                          <ScrollArea className="h-[120px] pr-1">
                            <div>
                              {weekEvents.length > 0 ? (
                                weekEvents.map((event, eventIdx) => {
                                  const displayText = event.sequenceNumber
                                    ? `${event.client} ${event.sequenceNumber}`
                                    : event.client;
                                    
                                  return (
                                    <div 
                                      key={event.id} 
                                      className="flex items-center gap-1 text-[9px] leading-none py-0"
                                      title={`${convertToPastTense(event.action)} ${event.text}`}
                                    >
                                      <div className="flex-shrink-0">
                                        <ColorPicker 
                                          currentColor={event.color} 
                                          onColorSelect={(color) => handleChangeColor(event.id, color)}
                                          directSelect={true}
                                        />
                                      </div>
                                      <button 
                                        className="truncate text-left hover:text-primary hover:underline cursor-pointer transition-colors"
                                        onClick={(e) => navigateToClientFollowUp(event.clientId, e)}
                                        type="button"
                                      >
                                        {displayText}
                                      </button>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="text-[9px] text-gray-400 italic">No events</div>
                              )}
                            </div>
                          </ScrollArea>
                        </div>
                      );
                    })}
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Weeks;
