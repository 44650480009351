
import { supabase } from "@/integrations/supabase/client";
import { FollowUp } from "@/types/followUp";

/**
 * Updates an existing follow-up
 */
export async function updateFollowUp(followUpData: Partial<FollowUp>, followUpId: string, userId: string) {
  console.log('Attempting to update follow-up:', { followUpData, followUpId, userId });
  
  const { data, error } = await supabase
    .from('follow_ups')
    .update({
      ...followUpData,
      user_id: userId
    })
    .eq('id', followUpId)
    .select()
    .single();

  if (error) {
    console.error('Error updating follow-up:', error.message, error.details, error.hint);
    throw error;
  }
  
  // If we're updating a stage, ensure the client_stages table is also updated
  if (followUpData.stage) {
    try {
      const { data: followUpRecord } = await supabase
        .from('follow_ups')
        .select('client_id')
        .eq('id', followUpId)
        .single();

      if (followUpRecord) {
        // Check if this stage already exists for this client
        const { data: existingStages } = await supabase
          .from('client_stages')
          .select('id')
          .eq('client_id', followUpRecord.client_id)
          .eq('user_id', userId)
          .eq('current_stage', followUpData.stage)
          .eq('is_active', true);
        
        // If no active stage exists for this client and stage, create one
        if (!existingStages || existingStages.length === 0) {
          await supabase
            .from('client_stages')
            .insert({
              client_id: followUpRecord.client_id,
              user_id: userId,
              current_stage: followUpData.stage,
              is_active: true,
              bullet_color: 'bg-gray-400'
            });
        }
      }
    } catch (stageErr) {
      console.error('Error syncing stage change:', stageErr);
      // We still return success because the follow-up was updated
    }
  }
  
  console.log('Follow-up updated successfully:', data);
  return data;
}
