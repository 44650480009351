
import { Client } from "@/types/client";
import { Stage } from "@/types/stage";
import StageClientItem from "./StageClientItem";

interface StageClientListProps {
  clients: Client[];
  stages: Stage[];
  currentStage: string;
  onDuplicateClient: (client: Client, targetStage: string) => void;
  onDeleteClientFromStage: (clientId: number, stage: string) => void;
  onColorChange: (clientId: number, color: string) => void;
  onClientClick: (clientId: number) => void;
}

const StageClientList = ({
  clients,
  stages,
  currentStage,
  onDuplicateClient,
  onDeleteClientFromStage,
  onColorChange,
  onClientClick
}: StageClientListProps) => {
  // Ensure clients is always an array even if undefined or null
  const safeClients = Array.isArray(clients) ? clients : [];
  
  // Sort clients by bullet color
  const sortedClients = [...safeClients].sort((a, b) => {
    // Extract the color values from the bullet_color strings
    const colorA = a.bullet_color || 'bg-gray-400';
    const colorB = b.bullet_color || 'bg-gray-400';
    
    // Sort by colors - prioritizing non-gray colors first
    if (colorA.includes('gray') && !colorB.includes('gray')) {
      return 1; // B comes before A
    } else if (!colorA.includes('gray') && colorB.includes('gray')) {
      return -1; // A comes before B
    } else {
      // If both are gray or both are not gray, sort alphabetically by color name
      return colorA.localeCompare(colorB);
    }
  });
  
  return (
    <div className="flex-1 overflow-y-auto p-2">
      {sortedClients.length === 0 ? (
        <div className="text-center text-gray-400 text-xs mt-4">
          No clients in this stage
        </div>
      ) : (
        sortedClients.map(client => (
          <StageClientItem
            key={`${client.id}-${currentStage}`}
            client={client}
            stages={stages}
            currentStage={currentStage}
            onDuplicateClient={onDuplicateClient}
            onDeleteClientFromStage={onDeleteClientFromStage}
            onColorChange={onColorChange}
            onClientClick={onClientClick}
          />
        ))
      )}
    </div>
  );
};

export default StageClientList;
