import { FollowUp } from "@/types/followUp";
import { supabase } from "@/integrations/supabase/client";
import { clientLookupService } from "./client/clientLookupService";

export const followUpTodoService = {
  /**
   * Finds and deletes a follow-up related to a todo
   */
  async findAndDeleteTodoFollowUp(clientName: string, action: string, description: string, userId: string) {
    console.log('Finding follow-up to delete for:', { clientName, action, description });
    
    try {
      const clientId = await this.findClientIdByName(clientName, userId);
      
      if (!clientId) {
        console.error('Error finding client');
        return false;
      }
      
      const { data, error } = await supabase
        .from('follow_ups')
        .select('id, description')
        .eq('client_id', clientId)
        .eq('action', action)
        .eq('status', 'completed')
        .ilike('description', `%${description}%`);

      if (error) {
        console.error('Error finding follow-up:', error);
        return false;
      }

      if (!data || data.length === 0) {
        console.log('No matching follow-up found to delete');
        return false;
      }

      console.log('Found follow-ups to delete:', data);
      
      for (const followUp of data) {
        await this.deleteFollowUp(followUp.id);
      }
      
      window.dispatchEvent(new CustomEvent('invalidate-followup-cache', {
        detail: { 
          clientId,
          source: 'todo-unchecked',
          priority: 'high'
        }
      }));
      
      return true;
    } catch (error) {
      console.error('Error in findAndDeleteTodoFollowUp:', error);
      return false;
    }
  },

  /**
   * Creates a new follow-up for a rechecked todo
   */
  async createNewFollowUpForRecheckedTodo(followUpData: Omit<FollowUp, 'id'>, week: number) {
    console.log('Creating new follow-up for rechecked todo:', followUpData, 'in week:', week);
    
    try {
      const { data: existingStageFollowUp, error: stageCheckError } = await supabase
        .from('follow_ups')
        .select('id, description')
        .eq('client_id', followUpData.client_id)
        .eq('stage', followUpData.stage)
        .limit(1);
      
      if (!stageCheckError && existingStageFollowUp && existingStageFollowUp.length > 0) {
        console.log('Found existing follow-up with same stage:', existingStageFollowUp[0]);
        
        const { error: updateError } = await supabase
          .from('follow_ups')
          .update({ 
            description: `${existingStageFollowUp[0].description}\n${followUpData.description}`.trim(),
            date: followUpData.date,
            status: 'completed',
            week
          })
          .eq('id', existingStageFollowUp[0].id);
          
        if (updateError) {
          console.error('Error updating existing follow-up:', updateError);
          throw updateError;
        }
        
        window.dispatchEvent(new CustomEvent('follow-up-updated-from-todo', {
          detail: { id: existingStageFollowUp[0].id, clientId: followUpData.client_id }
        }));
        
        window.dispatchEvent(new CustomEvent('invalidate-followup-cache', {
          detail: { 
            clientId: followUpData.client_id,
            source: 'todo-completed',
            priority: 'high'
          }
        }));
        
        return { id: existingStageFollowUp[0].id, success: true };
      }
      
      const { data: existingFollowUps, error: checkError } = await supabase
        .from('follow_ups')
        .select('id, description')
        .eq('client_id', followUpData.client_id)
        .eq('week', week)
        .eq('action', followUpData.action)
        .eq('status', 'completed');
      
      if (checkError) {
        console.error('Error checking for existing follow-ups:', checkError);
      } else if (existingFollowUps && existingFollowUps.length > 0) {
        console.log('Found existing follow-ups:', existingFollowUps);
        
        const { error: updateError } = await supabase
          .from('follow_ups')
          .update({ 
            description: `${existingFollowUps[0].description}\n${followUpData.description}`.trim()
          })
          .eq('id', existingFollowUps[0].id);
          
        if (updateError) {
          console.error('Error updating existing follow-up:', updateError);
          throw updateError;
        }
        
        window.dispatchEvent(new CustomEvent('follow-up-updated-from-todo', {
          detail: { id: existingFollowUps[0].id, clientId: followUpData.client_id }
        }));
        
        window.dispatchEvent(new CustomEvent('invalidate-followup-cache', {
          detail: { 
            clientId: followUpData.client_id,
            source: 'todo-completed',
            priority: 'high'
          }
        }));
        
        return { id: existingFollowUps[0].id, success: true };
      }
      
      const { data: stageExists } = await supabase
        .from('stages')
        .select('name')
        .eq('user_id', followUpData.user_id)
        .eq('name', followUpData.stage)
        .limit(1);
        
      if (!stageExists || stageExists.length === 0) {
        console.log('Creating new stage:', followUpData.stage);
        
        const { error: stageError } = await supabase
          .from('stages')
          .insert({
            name: followUpData.stage,
            user_id: followUpData.user_id,
            order_index: 999
          });
          
        if (stageError) {
          console.error('Error creating stage:', stageError);
        }
      }
      
      const dataToInsert = {
        ...followUpData,
        week
      };
      
      console.log('Creating new follow-up with data:', dataToInsert);
      
      const { data, error } = await supabase
        .from('follow_ups')
        .insert([dataToInsert])
        .select()
        .single();
        
      if (error) {
        console.error('Error creating follow-up:', error);
        
        if (error.code === '23505') {
          console.log('Constraint error, trying with unique stage name');
          
          const sanitizedDesc = (followUpData.description || 'task')
            .toLowerCase()
            .replace(/[^a-z0-9]/g, '_')
            .substring(0, 20);
          const uniqueTaskId = Date.now().toString().substring(8);
          const uniqueStageName = `task_${sanitizedDesc}_${uniqueTaskId}`;
          
          await supabase
            .from('stages')
            .insert({
              name: uniqueStageName,
              user_id: followUpData.user_id,
              order_index: 999
            });
          
          const { data: retryData, error: retryError } = await supabase
            .from('follow_ups')
            .insert([{
              ...dataToInsert,
              stage: uniqueStageName
            }])
            .select()
            .single();
            
          if (retryError) {
            console.error('Second attempt also failed:', retryError);
            throw retryError;
          }
          
          window.dispatchEvent(new CustomEvent('follow-up-created-from-todo', {
            detail: { id: retryData.id, clientId: followUpData.client_id }
          }));
          
          window.dispatchEvent(new CustomEvent('invalidate-followup-cache', {
            detail: { 
              clientId: followUpData.client_id,
              source: 'todo-completed-new',
              priority: 'high'
            }
          }));
          
          return { id: retryData.id, success: true };
        }
        
        throw error;
      }
      
      window.dispatchEvent(new CustomEvent('follow-up-created-from-todo', {
        detail: { id: data.id, clientId: followUpData.client_id }
      }));
      
      window.dispatchEvent(new CustomEvent('invalidate-followup-cache', {
        detail: { 
          clientId: followUpData.client_id,
          source: 'todo-completed-new',
          priority: 'high'
        }
      }));
      
      console.log('Successfully created new follow-up:', data);
      return { id: data.id, success: true };
    } catch (error) {
      console.error('Error in createNewFollowUpForRecheckedTodo:', error);
      return { id: null, success: false };
    }
  },

  /**
   * Helper method to find client ID by name
   */
  async findClientIdByName(clientName: string, userId: string): Promise<number | null> {
    console.log('Finding client ID for:', { clientName });
    
    try {
      const { data: exactMatches } = await supabase
        .from('clients')
        .select('id')
        .eq('user_id', userId)
        .eq('company', clientName)
        .limit(1);
        
      if (exactMatches && exactMatches.length > 0) {
        console.log('Found exact client match:', exactMatches[0].id);
        return exactMatches[0].id;
      }
      
      const { data: caseInsensitiveMatches } = await supabase
        .from('clients')
        .select('id')
        .eq('user_id', userId)
        .ilike('company', clientName)
        .limit(1);
        
      if (caseInsensitiveMatches && caseInsensitiveMatches.length > 0) {
        console.log('Found case-insensitive client match:', caseInsensitiveMatches[0].id);
        return caseInsensitiveMatches[0].id;
      }
      
      console.log('No matching client found');
      return null;
    } catch (error) {
      console.error('Error finding client ID:', error);
      return null;
    }
  },

  /**
   * Deletes a follow-up by ID (needed for the todo deletion)
   */
  async deleteFollowUp(id: string) {
    console.log('Deleting follow-up for todo:', id);
    
    const { error: remindsError } = await supabase
      .from('reminds')
      .delete()
      .eq('follow_up_id', id);

    if (remindsError) {
      console.error('Error deleting associated reminds:', remindsError);
      throw remindsError;
    }

    const { error } = await supabase
      .from('follow_ups')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting follow-up:', error);
      throw error;
    }
    
    console.log('Follow-up deleted successfully for todo');
  }
};
