
import { Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogClose } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";

interface AddStageDialogProps {
  onAddStage: (name: string) => Promise<void>;
}

const AddStageDialog = ({ onAddStage }: AddStageDialogProps) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="default" size="sm">
          <Plus className="h-4 w-4 mr-2" />
          Add Stage
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add New Stage</DialogTitle>
        </DialogHeader>
        <form onSubmit={(e) => {
          e.preventDefault();
          const form = e.target as HTMLFormElement;
          const nameInput = form.elements.namedItem('name') as HTMLInputElement;
          onAddStage(nameInput.value);
          const closeButton = form.querySelector('button[type="button"]') as HTMLButtonElement;
          closeButton.click();
        }}>
          <div className="grid gap-4 py-4">
            <div className="grid gap-2">
              <Label htmlFor="name">Stage Name</Label>
              <Input id="name" name="name" placeholder="Enter stage name" />
            </div>
          </div>
          <div className="flex justify-end gap-3">
            <DialogClose asChild>
              <Button type="button" variant="outline">Cancel</Button>
            </DialogClose>
            <Button type="submit">Add Stage</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddStageDialog;
