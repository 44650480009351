
import { useState, useEffect, useRef, useCallback } from "react";
import { FollowUp } from "@/types/followUp";
import { useToast } from "@/hooks/use-toast";
import { followUpStatusService } from "@/services/followUps/status";

interface UseStatusUpdateProps {
  followUp: FollowUp;
  onChange?: (id: string, status: FollowUp['status']) => Promise<void>;
}

export const useStatusUpdate = ({ followUp, onChange }: UseStatusUpdateProps) => {
  const [currentStatus, setCurrentStatus] = useState<FollowUp['status']>(followUp.status);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const updateCountRef = useRef(0);
  const { toast } = useToast();
  const updateTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const throttleTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isInitialMountRef = useRef(true);
  const lastStatusUpdateRef = useRef<{time: number, status: string} | null>(null);
  const ignoreNextEventRef = useRef(false);
  const eventLockRef = useRef(false);

  // Sync status with props, but only if we're not in the middle of an update
  useEffect(() => {
    if (isInitialMountRef.current) {
      isInitialMountRef.current = false;
      return;
    }

    if (followUp.status !== currentStatus && !isUpdating) {
      console.log(`Updating status from ${currentStatus} to ${followUp.status} based on props change`);
      setCurrentStatus(followUp.status);
    }
  }, [followUp.status, currentStatus, isUpdating]);

  // Clean up timeouts on unmount
  useEffect(() => {
    return () => {
      if (updateTimeoutRef.current) {
        clearTimeout(updateTimeoutRef.current);
      }
      if (throttleTimeoutRef.current) {
        clearTimeout(throttleTimeoutRef.current);
      }
    };
  }, []);

  // Listen for external status updates with improved duplicate detection
  useEffect(() => {
    const handleStatusUpdate = (event: CustomEvent) => {
      // Skip ALL event processing during active update
      if (isUpdating || eventLockRef.current) {
        console.log('Skipping event handling during active update or lock');
        return;
      }
      
      // Skip if this is our own event that we should ignore
      if (ignoreNextEventRef.current) {
        ignoreNextEventRef.current = false;
        return;
      }
      
      // Skip if this event is for a different follow-up
      if (event.detail?.id !== followUp.id) return;
      
      // Skip if no status change or if it came from this component
      if (!event.detail?.status || event.detail?.source === 'local-update') return;
      
      // Skip if we've seen this exact update recently (within 10 seconds)
      const now = Date.now();
      if (lastStatusUpdateRef.current && 
          lastStatusUpdateRef.current.status === event.detail.status &&
          (now - lastStatusUpdateRef.current.time < 10000)) {
        console.log(`StatusCell: Ignoring duplicate status update for ${followUp.id}`);
        return;
      }
      
      // Record this update to prevent duplicates
      lastStatusUpdateRef.current = {
        time: now,
        status: event.detail.status
      };
      
      console.log(`StatusCell: Processing status update event for ${followUp.id}: ${event.detail.status} from source: ${event.detail.source}`);
      
      // Set event lock to prevent other events from being processed
      eventLockRef.current = true;
      
      // Update state only if actually different, with a brief delay
      if (currentStatus !== event.detail.status) {
        setCurrentStatus(event.detail.status);
      }
      
      // Release event lock after a delay
      setTimeout(() => {
        eventLockRef.current = false;
      }, 5000);
    };

    window.addEventListener('follow-up-status-updated', handleStatusUpdate as EventListener);
    
    return () => {
      window.removeEventListener('follow-up-status-updated', handleStatusUpdate as EventListener);
    };
  }, [followUp.id, currentStatus, isUpdating]);

  // Process status update success with minimal events
  const handleUpdateSuccess = useCallback(async (value: string, updateId: number) => {
    // Only process if this is still the most recent update
    if (updateId !== updateCountRef.current) return;
    
    // Record this update to prevent duplicates from events
    lastStatusUpdateRef.current = {
      time: Date.now(),
      status: value
    };
    
    // Set the ignore flag to prevent handling our own event
    ignoreNextEventRef.current = true;
    
    // Trigger onChange callback if provided (just once)
    if (onChange) {
      await onChange(followUp.id, value as FollowUp['status']);
    }
    
    // Show success toast only once
    toast({
      title: "Status updated",
      description: `Status changed to ${value}`,
    });
  }, [followUp.id, onChange, toast]);

  // Process status update failure
  const handleUpdateFailure = useCallback((value: string, err: any) => {
    console.error("Error updating status:", err);
    setError("Failed to update status");
    
    // Revert to original status
    setCurrentStatus(followUp.status);
    
    // Show error toast
    toast({
      title: "Error",
      description: "Failed to update status. Please try again.",
      variant: "destructive",
    });
    
    // Remove the update lock
    eventLockRef.current = false;
    
    // Schedule a single retry attempt with longer delay
    updateTimeoutRef.current = setTimeout(async () => {
      try {
        const retrySuccess = await followUpStatusService.updateStatus(followUp.id, value as FollowUp['status']);
        
        if (retrySuccess) {
          setError(null);
          
          // Only update if different
          if (currentStatus !== value) {
            setCurrentStatus(value as FollowUp['status']);
          }
          
          // Record this update to prevent duplicates
          lastStatusUpdateRef.current = {
            time: Date.now(),
            status: value
          };
          
          if (onChange) {
            await onChange(followUp.id, value as FollowUp['status']);
          }
          
          toast({
            title: "Status updated",
            description: `Status changed to ${value} (retry successful)`,
          });
        }
      } catch (retryErr) {
        console.error("Retry also failed:", retryErr);
      } finally {
        setIsUpdating(false);
      }
    }, 3000); // Longer delay for retry
  }, [followUp.status, followUp.id, onChange, toast, currentStatus]);

  // Completely redesigned status change handler with lock mechanism
  const handleChange = useCallback((value: string) => {
    // Don't do anything if the status hasn't changed
    if (value === currentStatus) return;
    
    // If already updating, don't allow changes
    if (isUpdating || eventLockRef.current) {
      console.log('Blocking status change during active update or lock');
      return;
    }
    
    // Clear any pending throttle timeout
    if (throttleTimeoutRef.current) {
      clearTimeout(throttleTimeoutRef.current);
    }
    
    // Set event lock to prevent other status changes
    eventLockRef.current = true;
    
    // Update UI immediately for better user experience, but only once
    setCurrentStatus(value as FollowUp['status']);
    setIsUpdating(true);
    setError(null);
    
    // Set a long timeout to process the status change
    throttleTimeoutRef.current = setTimeout(() => {
      const updateId = ++updateCountRef.current;
      
      try {
        console.log(`StatusCell: Updating follow-up status from ${followUp.status} to ${value} for ID ${followUp.id}`);
        
        // Record this update attempt
        lastStatusUpdateRef.current = {
          time: Date.now(),
          status: value
        };
        
        // Tell the event handler to ignore the next event since we'll handle it here
        ignoreNextEventRef.current = true;
        
        // Dispatch only one local event with status
        window.dispatchEvent(new CustomEvent('follow-up-status-updated', {
          detail: { 
            id: followUp.id, 
            status: value, 
            source: 'local-update',
            updateId: updateId 
          }
        }));
        
        // Attempt to update status
        followUpStatusService.updateStatus(followUp.id, value as FollowUp['status'])
          .then(success => {
            if (success) {
              handleUpdateSuccess(value, updateId);
            } else {
              throw new Error("Status update failed");
            }
          })
          .catch(err => {
            handleUpdateFailure(value, err);
          })
          .finally(() => {
            // Only update isUpdating if this is still the most recent update
            if (updateId === updateCountRef.current) {
              setIsUpdating(false);
              
              // Release event lock after a delay
              setTimeout(() => {
                eventLockRef.current = false;
              }, 5000);
            }
          });
      } catch (err) {
        handleUpdateFailure(value, err);
      }
    }, 500);
  }, [currentStatus, followUp.id, followUp.status, handleUpdateSuccess, handleUpdateFailure, isUpdating]);

  return {
    currentStatus,
    isUpdating,
    error,
    handleChange
  };
};
