
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";

interface IndustrySelectorProps {
  industry: string;
  industries: string[];
  onIndustryChange: (value: string) => void;
}

const IndustrySelector = ({ industry, industries: propIndustries, onIndustryChange }: IndustrySelectorProps) => {
  const [industries, setIndustries] = useState<string[]>(propIndustries);
  
  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;
        
        const { data, error } = await supabase
          .from('industries')
          .select('name')
          .eq('user_id', user.id);
          
        if (error) throw error;
        
        if (data && data.length > 0) {
          const industryNames = data.map(i => i.name).filter(Boolean);
          setIndustries(industryNames);
        }
      } catch (error) {
        console.error("Error fetching industries:", error);
      }
    };
    
    fetchIndustries();
  }, [propIndustries]);
  
  if (!industries.length) return null;

  // Sort industries alphabetically
  const sortedIndustries = [...industries].sort((a, b) => a.localeCompare(b));

  return (
    <div className="space-y-2">
      <label className="text-sm font-medium">Industry</label>
      <Select value={industry} onValueChange={onIndustryChange}>
        <SelectTrigger>
          <SelectValue placeholder="Select an industry" />
        </SelectTrigger>
        <SelectContent>
          {sortedIndustries.map((ind) => (
            <SelectItem key={ind} value={ind}>
              {ind}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default IndustrySelector;
