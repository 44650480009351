
import { Button } from "@/components/ui/button";
import AddStageDialog from "./AddStageDialog";
import { useToast } from "@/hooks/use-toast";

interface StagesEmptyStateProps {
  onAddStage: (name: string) => Promise<void>;
  fetchData: () => Promise<void>;
}

const StagesEmptyState = ({ onAddStage, fetchData }: StagesEmptyStateProps) => {
  const { toast } = useToast();
  
  return (
    <div className="flex flex-col items-center justify-center p-8 bg-gray-50 rounded-lg border border-dashed">
      <p className="text-gray-500 mb-4">No stages found. Add your first stage to get started.</p>
      <Button onClick={() => {
        toast({
          title: "Creating default stages",
          description: "Please wait while we set up your pipeline...",
        });
        fetchData();
      }}>
        Create Default Stages
      </Button>
    </div>
  );
};

export default StagesEmptyState;
