
import { supabase } from "@/integrations/supabase/client";

export const actionService = {
  /**
   * Updates the action of a follow-up
   */
  async updateAction(id: string, action: string) {
    console.log('Attempting to update follow-up action:', { id, action });
    
    const { error } = await supabase
      .from('follow_ups')
      .update({ action })
      .eq('id', id);

    if (error) {
      console.error('Error updating follow-up action:', error.message, error.details);
      throw error;
    }
    
    console.log('Follow-up action updated successfully');
    
    // Dispatch a custom event to notify components to refresh
    window.dispatchEvent(new CustomEvent('follow-up-action-updated', {
      detail: { id, action }
    }));
  }
};
