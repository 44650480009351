
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface IndustrySelectorProps {
  value: string;
  industries: string[];
  onChange: (value: string) => void;
}

const IndustrySelector = ({ value, industries, onChange }: IndustrySelectorProps) => {
  // Ensure we have a valid value
  const currentValue = value?.trim() || "";
  
  // Filter out empty industry values, ensure unique items, and sort alphabetically
  const filteredIndustries = [...new Set(industries.filter(i => i?.trim()))];
  const sortedIndustries = [...filteredIndustries].sort((a, b) => a.localeCompare(b));
  
  // If the current value is not in the list but not empty, add it
  if (currentValue && !sortedIndustries.includes(currentValue)) {
    sortedIndustries.unshift(currentValue);
  }
  
  return (
    <div className="space-y-1.5">
      <Label className="text-xs font-medium">Industry</Label>
      <Select
        value={currentValue || undefined}
        onValueChange={onChange}
      >
        <SelectTrigger className="h-8 text-sm">
          <SelectValue placeholder="Select industry" />
        </SelectTrigger>
        <SelectContent>
          {sortedIndustries.length === 0 ? (
            <SelectItem value="none" disabled>No industries available</SelectItem>
          ) : (
            sortedIndustries.map((industry) => (
              <SelectItem key={industry} value={industry}>
                {industry}
              </SelectItem>
            ))
          )}
        </SelectContent>
      </Select>
    </div>
  );
};

export default IndustrySelector;
