
import { useState, useEffect, useMemo } from "react";
import { Client } from "@/types/client";

export const useClientFiltering = (clients: Client[], searchTerm: string) => {
  const [localClients, setLocalClients] = useState<Client[]>(clients);
  const [clientsInStage, setClientsInStage] = useState<number[]>([]);
  
  // Update local clients when props change
  useEffect(() => {
    setLocalClients(clients);
  }, [clients]);
  
  // Track clients in this stage
  useEffect(() => {
    if (localClients && localClients.length > 0) {
      const ids = localClients.map(client => client.id);
      setClientsInStage(ids);
    } else {
      setClientsInStage([]);
    }
  }, [localClients]);
  
  // Filter clients based on search term
  const filteredClients = useMemo(() => {
    if (!searchTerm.trim()) {
      return localClients;
    }
    
    const normalizedSearchTerm = searchTerm.toLowerCase().trim();
    console.log(`Filtering clients with search term: "${normalizedSearchTerm}"`);
    
    return localClients.filter(client => 
      client.company.toLowerCase().includes(normalizedSearchTerm)
    );
  }, [localClients, searchTerm]);
  
  return {
    localClients,
    setLocalClients,
    clientsInStage,
    filteredClients
  };
};
