
import { User, LogOut, Pencil, Database, ArrowLeft, Lock } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useState, useEffect } from "react";
import { ProfileEditForm } from "./ProfileEditForm";
import { ProfileInfo } from "./ProfileInfo";
import { PasswordChangeForm } from "./PasswordChangeForm";

interface UserMenuProps {
  username: string;
}

export const UserMenu = ({ username }: UserMenuProps) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [isEditing, setIsEditing] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userInfo, setUserInfo] = useState({
    email: '',
    company_name: '',
    role: '',
    username: username
  });

  useEffect(() => {
    const getUserInfo = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        const { data: profile } = await supabase
          .from('profiles')
          .select('email, company_name, role, username, is_admin')
          .eq('id', session.user.id)
          .maybeSingle();
        
        if (profile) {
          setUserInfo(profile);
          setIsAdmin(profile.is_admin || false);
        }
      }
    };

    getUserInfo();
  }, []);

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      toast({
        title: "Error",
        description: "Failed to log out. Please try again.",
        variant: "destructive",
      });
    } else {
      toast({
        title: "Success",
        description: "Logged out successfully",
      });
      navigate('/login');
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className="flex items-center space-x-2 hover:bg-gray-100 px-3 py-2 rounded-md transition-colors">
          <User className="h-4 w-4" />
          <span className="text-sm font-medium">{userInfo.username}</span>
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {location.pathname === '/admin' && (
          <DropdownMenuItem onClick={() => navigate('/dashboard')}>
            <ArrowLeft className="mr-2 h-4 w-4" />
            <span>Back to Dashboard</span>
          </DropdownMenuItem>
        )}
        <Dialog open={isEditing} onOpenChange={setIsEditing}>
          <DialogTrigger asChild>
            <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
              <Pencil className="mr-2 h-4 w-4" />
              <span>Edit Profile</span>
            </DropdownMenuItem>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Edit Profile</DialogTitle>
            </DialogHeader>
            <ProfileEditForm
              initialData={{
                username: userInfo.username,
                company_name: userInfo.company_name || '',
                role: userInfo.role || ''
              }}
              onSuccess={() => {
                setIsEditing(false);
                const getUserInfo = async () => {
                  const { data: { session } } = await supabase.auth.getSession();
                  if (session) {
                    const { data: profile } = await supabase
                      .from('profiles')
                      .select('email, company_name, role, username, is_admin')
                      .eq('id', session.user.id)
                      .maybeSingle();
                    
                    if (profile) {
                      setUserInfo(profile);
                      setIsAdmin(profile.is_admin || false);
                    }
                  }
                };
                getUserInfo();
              }}
            />
          </DialogContent>
        </Dialog>

        <Dialog open={isChangingPassword} onOpenChange={setIsChangingPassword}>
          <DialogTrigger asChild>
            <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
              <Lock className="mr-2 h-4 w-4" />
              <span>Change Password</span>
            </DropdownMenuItem>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Change Password</DialogTitle>
            </DialogHeader>
            <PasswordChangeForm onSuccess={() => setIsChangingPassword(false)} />
          </DialogContent>
        </Dialog>

        <ProfileInfo userInfo={userInfo} />
        {isAdmin && (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => navigate('/admin')}>
              <Database className="mr-2 h-4 w-4" />
              <span>Backup Control</span>
            </DropdownMenuItem>
          </>
        )}
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={handleLogout} className="text-red-600">
          <LogOut className="mr-2 h-4 w-4" />
          <span>Logout</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
