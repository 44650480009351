
import { useEffect, useRef } from 'react';
import { supabase } from "@/integrations/supabase/client";

// Define a more specific payload type to match Supabase's real-time updates
interface RealtimePayload {
  schema: string;
  table: string;
  commit_timestamp: string;
  eventType: 'INSERT' | 'UPDATE' | 'DELETE';
  new: Record<string, any> | {};
  old: Record<string, any> | {};
}

export const useRealtimeUpdates = (
  clientId: number | undefined,
  onDataChange: (payload: RealtimePayload) => void
) => {
  const channelRef = useRef<ReturnType<typeof supabase.channel> | null>(null);
  const lastProcessedUpdatesRef = useRef<Map<string, number>>(new Map());
  const processingUpdateRef = useRef<boolean>(false);
  const pendingUpdatesRef = useRef<RealtimePayload[]>([]);
  const processingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const isSubscribedRef = useRef<boolean>(false);
  const payloadQueueRef = useRef<RealtimePayload[]>([]);
  const isProcessingQueueRef = useRef<boolean>(false);

  useEffect(() => {
    if (!clientId) return;

    // Clean up any existing subscription
    if (channelRef.current) {
      supabase.removeChannel(channelRef.current);
      channelRef.current = null;
      isSubscribedRef.current = false;
    }

    console.log('Setting up realtime subscription for follow-ups with clientId:', clientId);
    
    // Process single payload at a time on a fixed schedule
    const processPayloadQueue = () => {
      if (isProcessingQueueRef.current || payloadQueueRef.current.length === 0) {
        return;
      }
      
      isProcessingQueueRef.current = true;
      
      try {
        // Get the newest payload from the queue
        const payload = payloadQueueRef.current.pop();
        
        // Clear the queue - we only process the most recent update
        payloadQueueRef.current = [];
        
        if (payload) {
          console.log('Processing single payload from queue:', payload.eventType);
          onDataChange(payload);
        }
      } finally {
        // Set a long delay before processing next payload
        setTimeout(() => {
          isProcessingQueueRef.current = false;
        }, 5000);
      }
    };
    
    // Setup a very slow interval to process queued payloads
    const slowProcessingInterval = setInterval(processPayloadQueue, 10000);
    
    // Create a unique channel name that includes clientId to prevent conflicts
    const channelName = `follow-ups-changes-${clientId}-${Date.now()}`;
    
    // Helper function to get record ID from a payload
    function getRecordId(payload: RealtimePayload): string | null {
      const id = payload.new && typeof payload.new === 'object' ? 
        (payload.new as any).id : 
        payload.old && typeof payload.old === 'object' ? 
          (payload.old as any).id : null;
      
      return id ? String(id) : null;
    }
    
    // Generate a unique update key for deduplication
    function generateUpdateKey(payload: RealtimePayload): string {
      const id = getRecordId(payload);
      
      // For status updates, include the status values
      if (payload.eventType === 'UPDATE' && 
          payload.new && typeof payload.new === 'object' &&
          payload.old && typeof payload.old === 'object') {
          
        const newStatus = (payload.new as any).status;
        const oldStatus = (payload.old as any).status;
        
        if (newStatus !== oldStatus) {
          return `status-${id}-${oldStatus}-${newStatus}`;
        }
      }
      
      return `${payload.eventType}-${id}`;
    }
    
    // Subscribe to all changes on the follow_ups table for this client
    const channel = supabase
      .channel(channelName)
      .on(
        'postgres_changes',
        {
          event: '*', // Listen for all events (INSERT, UPDATE, DELETE)
          schema: 'public',
          table: 'follow_ups',
          filter: `client_id=eq.${clientId}`
        },
        (payload: RealtimePayload) => {
          // Skip processing if not fully subscribed yet
          if (!isSubscribedRef.current) {
            return;
          }
          
          const now = Date.now();
          const updateKey = generateUpdateKey(payload);
          
          // Extreme deduplication - check if we've processed this exact update recently
          const lastProcessed = lastProcessedUpdatesRef.current.get(updateKey);
          if (lastProcessed && (now - lastProcessed < 20000)) { // Doubled from 10000 to 20000ms
            console.log('Ignoring duplicate realtime update:', updateKey);
            return;
          }
          
          // Record this update to prevent duplicates
          lastProcessedUpdatesRef.current.set(updateKey, now);
          
          // Clean up old entries from the map to prevent memory leaks
          lastProcessedUpdatesRef.current.forEach((timestamp, key) => {
            if (now - timestamp > 30000) { // Doubled from 15000 to 30000ms
              lastProcessedUpdatesRef.current.delete(key);
            }
          });
          
          console.log('Received realtime update:', payload.eventType);
          
          // For all updates, add to queue but don't process immediately
          payloadQueueRef.current.push(payload);
        }
      )
      .subscribe((status) => {
        console.log('Realtime subscription status:', status);
        if (status === 'SUBSCRIBED') {
          isSubscribedRef.current = true;
          // Add a delay before processing any updates to ensure stable UI
          setTimeout(() => {
            if (payloadQueueRef.current.length > 0) {
              processPayloadQueue();
            }
          }, 5000);
        }
      });

    channelRef.current = channel;
    
    // Clean up subscription and interval when component unmounts or clientId changes
    return () => {
      console.log('Cleaning up realtime subscription for client:', clientId);
      isSubscribedRef.current = false;
      
      if (channelRef.current) {
        supabase.removeChannel(channelRef.current);
        channelRef.current = null;
      }
      
      clearInterval(slowProcessingInterval);
      
      if (processingIntervalRef.current) {
        clearInterval(processingIntervalRef.current);
        processingIntervalRef.current = null;
      }
    };
  }, [clientId, onDataChange]);

  // Return a function to manually remove the channel if needed
  return {
    removeChannel: () => {
      isSubscribedRef.current = false;
      
      if (channelRef.current) {
        supabase.removeChannel(channelRef.current);
        channelRef.current = null;
      }
      
      if (processingIntervalRef.current) {
        clearInterval(processingIntervalRef.current);
        processingIntervalRef.current = null;
      }
    }
  };
};
