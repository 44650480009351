
import { useState } from "react";
import { Client } from "@/types/client";
import { useAuthCheck } from "./dashboard/useAuthCheck";
import { useClientFetching } from "./dashboard/useClientFetching";
import { useClientSelection } from "./dashboard/useClientSelection";
import { useNavigationState } from "./dashboard/useNavigationState";
import { useDashboardRefresh } from "./dashboard/useDashboardRefresh";

export const useDashboardState = () => {
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [selectedIndustry, setSelectedIndustry] = useState<string>("");
  const [activePanel, setActivePanel] = useState<string>("");

  // Check authentication status
  useAuthCheck();

  // Client fetching functionality
  const { fetchClientById } = useClientFetching(
    setSelectedClient,
    setSelectedIndustry,
    setActivePanel
  );

  // Client selection handlers
  const { handleClientSelect, handleClientSelectFromRemind } = useClientSelection(
    setSelectedClient,
    setSelectedIndustry,
    setActivePanel
  );

  // Dashboard refresh functionality
  const { handleRefresh } = useDashboardRefresh(
    setSelectedClient,
    setSelectedIndustry,
    setActivePanel
  );

  // Handle navigation state
  useNavigationState({
    fetchClientById,
    setActivePanel,
    setSelectedClient,
    setSelectedIndustry
  });

  return {
    selectedClient,
    selectedIndustry,
    activePanel,
    handleClientSelect,
    handleClientSelectFromRemind,
    handleRefresh,
    fetchClientById,
    setSelectedIndustry,
    setActivePanel
  };
};
