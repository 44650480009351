
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { getStatusClassName } from "./utils/statusUtils";
import React, { memo } from "react";
import { FollowUp } from "@/types/followUp";

interface StatusSelectProps {
  currentStatus: FollowUp['status'];
  isDisabled?: boolean;
  onValueChange: (value: string) => void;
}

const StatusSelect = ({ 
  currentStatus, 
  isDisabled = false,
  onValueChange
}: StatusSelectProps) => {
  // Use React.useMemo to prevent unnecessary re-rendering
  const statusClassName = React.useMemo(() => 
    getStatusClassName(currentStatus), 
    [currentStatus]
  );

  return (
    <Select 
      value={currentStatus} 
      onValueChange={onValueChange}
      disabled={isDisabled}
    >
      <SelectTrigger 
        className={`h-7 px-2 text-xs font-medium border ${statusClassName}`}
      >
        <SelectValue placeholder="Select status" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="completed">Completed</SelectItem>
        <SelectItem value="pending">Pending</SelectItem>
      </SelectContent>
    </Select>
  );
};

// Memoize to prevent unnecessary re-renders
export default memo(StatusSelect);
