
import { Input } from "@/components/ui/input";
import IndustrySelector from "./IndustrySelector";
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";

interface FormDateIndustryProps {
  date: string;
  industry: string;
  industries: string[];
  onDateChange: (date: string) => void;
  onIndustryChange: (industry: string) => void;
}

const FormDateIndustry = ({
  date,
  industry,
  industries: defaultIndustries,
  onDateChange,
  onIndustryChange,
}: FormDateIndustryProps) => {
  const [industries, setIndustries] = useState<string[]>(defaultIndustries);
  
  // Fetch industries from database
  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;
        
        // Fetch industries from the main industries table
        const { data: industryData, error } = await supabase
          .from('industries')
          .select('name')
          .eq('user_id', user.id);
          
        if (error) throw error;
        
        if (industryData && industryData.length > 0) {
          const industryNames = industryData.map(i => i.name).filter(Boolean);
          setIndustries(industryNames);
        }
      } catch (error) {
        console.error("Error fetching industries:", error);
      }
    };
    
    fetchIndustries();
  }, [defaultIndustries]);

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="space-y-1.5">
        <label className="text-xs font-medium">Date (mm/dd/yy)</label>
        <Input
          type="date"
          value={date}
          onChange={(e) => onDateChange(e.target.value)}
          className="h-8 text-sm"
        />
      </div>
      <IndustrySelector
        value={industry}
        industries={industries}
        onChange={onIndustryChange}
      />
    </div>
  );
};

export default FormDateIndustry;
