
import { useState } from 'react';
import { FollowUp } from "@/types/followUp";
import { useToast } from "@/hooks/use-toast";

export const useFollowUpTable = (
  onUpdateFollowUp?: (id: string, field: keyof FollowUp, value: string) => Promise<void>,
  onDelete?: (id: string) => Promise<boolean>
) => {
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const [deletingRows, setDeletingRows] = useState<Set<string>>(new Set());
  const { toast } = useToast();

  const toggleRow = (id: string) => {
    setExpandedRows(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const handleCellUpdate = async (id: string, field: keyof FollowUp, value: string) => {
    console.log('Attempting to update follow-up:', { id, field, value });
    if (onUpdateFollowUp) {
      try {
        await onUpdateFollowUp(id, field, value);
        
        console.log('Update successful');
        toast({
          title: "Success",
          description: `Follow-up ${field} updated successfully`,
        });
      } catch (error) {
        console.error('Error updating follow-up:', error);
        toast({
          title: "Error",
          description: `Failed to update ${field}. Please try again.`,
          variant: "destructive",
        });
      }
    } else {
      console.warn('onUpdateFollowUp callback is not provided');
    }
  };

  const handleDelete = async (id: string) => {
    console.log('Table handler: attempting to delete follow-up:', id);
    if (!onDelete) {
      console.warn('onDelete callback is not provided');
      return;
    }
    
    // Mark this row as being deleted to prevent multiple clicks
    setDeletingRows(prev => {
      const updated = new Set(prev);
      updated.add(id);
      return updated;
    });
    
    try {
      const success = await onDelete(id);
      console.log('Delete callback completed, success:', success);
      
      // If deletion was successful, we'll just leave it in the deleting state
      // since the parent component should re-render with the item removed
    } catch (error) {
      console.error('Error in TableRow when deleting follow-up:', error);
      
      // Remove from deleting state if there was an error
      setDeletingRows(prev => {
        const updated = new Set(prev);
        updated.delete(id);
        return updated;
      });
      
      toast({
        title: "Error",
        description: "Failed to delete follow-up. Please try again.",
        variant: "destructive",
      });
    }
  };

  return {
    expandedRows,
    deletingRows,
    toggleRow,
    handleCellUpdate,
    handleDelete
  };
};
