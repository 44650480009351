
import { useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
  SheetClose,
} from "@/components/ui/sheet";
import {
  CalendarDays,
  ListTodo,
  Users,
  FileText,
  Building,
  LayoutGrid,
  Activity,
  Info,
  Mail,
  LogIn,
  UserPlus,
} from "lucide-react";

interface MobileMenuProps {
  isLoggedIn: boolean;
}

const MobileMenu = ({ isLoggedIn }: MobileMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <Button variant="ghost" className="md:hidden px-0 text-foreground">
          <Menu className="h-6 w-6" />
          <span className="sr-only">Toggle menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="w-[240px] sm:w-[280px]">
        <div className="py-6 flex flex-col space-y-2">
          {isLoggedIn ? (
            // Logged-in user menu
            <>
              <SheetClose asChild>
                <Link
                  to="/index"
                  className="flex items-center rounded-lg px-3 py-2 text-foreground bg-accent/10 hover:bg-accent/20 transition"
                >
                  <LayoutGrid className="mr-2 h-4 w-4" />
                  Dashboard
                </Link>
              </SheetClose>

              <SheetClose asChild>
                <Link
                  to="/prospecting"
                  className="flex items-center rounded-lg px-3 py-2 text-foreground hover:bg-accent/10 transition"
                >
                  <Activity className="mr-2 h-4 w-4" />
                  Prospecting
                </Link>
              </SheetClose>

              <SheetClose asChild>
                <Link
                  to="/weeks"
                  className="flex items-center rounded-lg px-3 py-2 text-foreground hover:bg-accent/10 transition"
                >
                  <CalendarDays className="mr-2 h-4 w-4" />
                  Weekly Log
                </Link>
              </SheetClose>

              <SheetClose asChild>
                <Link
                  to="/stages"
                  className="flex items-center rounded-lg px-3 py-2 text-foreground hover:bg-accent/10 transition"
                >
                  <ListTodo className="mr-2 h-4 w-4" />
                  Pipeline
                </Link>
              </SheetClose>

              <SheetClose asChild>
                <Link
                  to="/trade-shows"
                  className="flex items-center rounded-lg px-3 py-2 text-foreground hover:bg-accent/10 transition"
                >
                  <Building className="mr-2 h-4 w-4" />
                  Trade Shows
                </Link>
              </SheetClose>

              <SheetClose asChild>
                <Link
                  to="/reports"
                  className="flex items-center rounded-lg px-3 py-2 text-foreground hover:bg-accent/10 transition"
                >
                  <FileText className="mr-2 h-4 w-4" />
                  Reports
                </Link>
              </SheetClose>
            </>
          ) : (
            // Non-logged-in user menu
            <>
              <SheetClose asChild>
                <Link
                  to="/about"
                  className="flex items-center rounded-lg px-3 py-2 text-foreground hover:bg-accent/10 transition"
                >
                  <Info className="mr-2 h-4 w-4" />
                  About
                </Link>
              </SheetClose>

              <SheetClose asChild>
                <Link
                  to="/contact"
                  className="flex items-center rounded-lg px-3 py-2 text-foreground hover:bg-accent/10 transition"
                >
                  <Mail className="mr-2 h-4 w-4" />
                  Contact
                </Link>
              </SheetClose>

              <SheetClose asChild>
                <Link
                  to="/login"
                  className="flex items-center rounded-lg px-3 py-2 text-foreground hover:bg-accent/10 transition"
                >
                  <LogIn className="mr-2 h-4 w-4" />
                  Login
                </Link>
              </SheetClose>

              <SheetClose asChild>
                <Link
                  to="/register"
                  className="flex items-center rounded-lg px-3 py-2 text-foreground bg-accent/10 hover:bg-accent/20 transition"
                >
                  <UserPlus className="mr-2 h-4 w-4" />
                  Register
                </Link>
              </SheetClose>
            </>
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default MobileMenu;
