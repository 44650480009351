
import { supabase } from "@/integrations/supabase/client";
import { FollowUp } from "@/types/followUp";

export const priorityService = {
  /**
   * Updates the priority of a follow-up
   */
  async updatePriority(id: string, priority: FollowUp['priority']) {
    console.log('Attempting to update follow-up priority:', { id, priority });
    
    // Make sure empty strings are converted to "none"
    const sanitizedPriority = priority || "none";
    
    const { data, error } = await supabase
      .from('follow_ups')
      .update({ priority: sanitizedPriority })
      .eq('id', id)
      .select();

    if (error) {
      console.error('Error updating follow-up priority:', error.message, error.details);
      throw error;
    }
    
    console.log('Follow-up priority updated successfully:', data);
    
    // Dispatch custom event for real-time notification
    window.dispatchEvent(new CustomEvent('follow-up-status-updated', {
      detail: { id, field: 'priority', value: sanitizedPriority, source: 'direct-update' }
    }));
    
    return data;
  }
};
