import { useState } from "react";
import { Camera } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useIsMobile } from "@/hooks/use-mobile";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

const QuickCaptureButton = () => {
  const { toast } = useToast();
  const [isCapturing, setIsCapturing] = useState(false);
  const [showPermissionAlert, setShowPermissionAlert] = useState(false);
  const [showPromptDialog, setShowPromptDialog] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState<string>("");
  const [selectedTradeShow, setSelectedTradeShow] = useState<number | null>(null);
  const [industries, setIndustries] = useState<string[]>([]);
  const [tradeShows, setTradeShows] = useState<any[]>([]);
  const [capturedFile, setCapturedFile] = useState<File | null>(null);
  const isMobile = useIsMobile();

  const fetchIndustries = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('show_industries')
        .select('name')
        .eq('user_id', user.id);

      if (error) throw error;
      setIndustries(data.map(i => i.name));
    } catch (error) {
      console.error('Error fetching industries:', error);
    }
  };

  const fetchTradeShows = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('trade_shows')
        .select('*')
        .eq('user_id', user.id);

      if (error) throw error;
      setTradeShows(data || []);
    } catch (error) {
      console.error('Error fetching trade shows:', error);
    }
  };

  const handleCameraClick = () => {
    const input = document.getElementById('camera-input') as HTMLInputElement;
    if (input) {
      input.click();
    }
  };

  const handleCapture = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      const permissionError = await checkCameraPermission();
      if (permissionError) {
        setShowPermissionAlert(true);
        return;
      }
      return;
    }

    setCapturedFile(file);
    // Show dialog for industry and trade show selection
    await Promise.all([fetchIndustries(), fetchTradeShows()]);
    setShowPromptDialog(true);
  };

  const handleSaveCapture = async () => {
    if (!capturedFile || !selectedTradeShow) {
      toast({
        title: "Error",
        description: "Please select a trade show and ensure image is captured",
        variant: "destructive",
      });
      return;
    }

    try {
      setIsCapturing(true);
      setShowPermissionAlert(false);

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        toast({
          title: "Authentication error",
          description: "Please log in to capture business cards",
          variant: "destructive",
        });
        return;
      }

      // Create storage bucket if it doesn't exist
      const { data: bucketData, error: bucketError } = await supabase
        .storage
        .getBucket('business_cards');

      if (!bucketData) {
        await supabase
          .storage
          .createBucket('business_cards', { public: true });
      }

      // Upload business card image
      const fileExt = capturedFile.name.split('.').pop();
      const fileName = `${crypto.randomUUID()}.${fileExt}`;

      const { error: uploadError } = await supabase.storage
        .from('business_cards')
        .upload(fileName, capturedFile);

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('business_cards')
        .getPublicUrl(fileName);

      // Create contact with image URL
      const { error: contactError } = await supabase
        .from('trade_show_contacts')
        .insert({
          company: 'New Contact',
          trade_show_id: selectedTradeShow,
          business_card_url: publicUrl,
          user_id: user.id
        });

      if (contactError) throw contactError;

      // Add industry
      if (selectedIndustry) {
        const { data: contactData } = await supabase
          .from('trade_show_contacts')
          .select('id')
          .eq('business_card_url', publicUrl)
          .single();

        if (contactData) {
          await supabase
            .from('trade_show_contact_industries')
            .insert({
              contact_id: contactData.id,
              industry_name: selectedIndustry,
              user_id: user.id
            });
        }
      }

      toast({
        title: "Success",
        description: "Business card captured successfully",
      });

      // Reset form
      setCapturedFile(null);
      setSelectedIndustry("");
      setSelectedTradeShow(null);
      setShowPromptDialog(false);

    } catch (error) {
      console.error('Error capturing business card:', error);
      toast({
        title: "Error",
        description: "Failed to capture business card",
        variant: "destructive",
      });
    } finally {
      setIsCapturing(false);
    }
  };

  const checkCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach(track => track.stop());
      return false;
    } catch (err) {
      console.error('Camera permission error:', err);
      return true;
    }
  };

  if (!isMobile) return null;

  return (
    <>
      {showPermissionAlert && (
        <div className="fixed top-4 left-4 right-4 z-50">
          <Alert variant="destructive">
            <AlertTitle>Camera Access Required</AlertTitle>
            <AlertDescription>
              To capture business cards, please:
              <ol className="list-decimal ml-4 mt-2">
                <li>Open your device settings</li>
                <li>Find this website in your browser settings</li>
                <li>Enable camera access</li>
                <li>Return to this app and try again</li>
              </ol>
            </AlertDescription>
          </Alert>
        </div>
      )}

      <Dialog open={showPromptDialog} onOpenChange={setShowPromptDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Capture Business Card</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 pt-4">
            <div className="space-y-2">
              <Label>Show Industry</Label>
              <Select 
                value={selectedIndustry} 
                onValueChange={setSelectedIndustry}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select industry" />
                </SelectTrigger>
                <SelectContent>
                  {industries.map((industry) => (
                    <SelectItem key={industry} value={industry}>
                      {industry}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label>Trade Show</Label>
              <Select 
                value={selectedTradeShow?.toString()} 
                onValueChange={(value) => setSelectedTradeShow(Number(value))}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select trade show" />
                </SelectTrigger>
                <SelectContent>
                  {tradeShows.map((show) => (
                    <SelectItem key={show.id} value={show.id.toString()}>
                      {show.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          <DialogFooter>
            <Button 
              onClick={handleSaveCapture} 
              disabled={isCapturing || !selectedTradeShow}
            >
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <div className="fixed bottom-20 right-4 z-40">
        <input
          type="file"
          accept="image/*"
          capture="environment"
          onChange={handleCapture}
          className="hidden"
          id="camera-input"
          aria-label="Capture business card"
        />
        <Button 
          size="lg"
          className="rounded-full shadow-lg h-16 w-16 bg-primary hover:bg-primary/90 active:bg-primary/80"
          disabled={isCapturing}
          aria-label="Take photo"
          onClick={handleCameraClick}
        >
          <Camera className="h-8 w-8" />
        </Button>
      </div>
    </>
  );
};

export default QuickCaptureButton;
