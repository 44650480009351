
import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import ProspectForm from "./ProspectForm";
import { Users, Plus, Pencil, Trash2, Circle } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface ProspectsListProps {
  onSelectProspect: (id: number) => void;
  selectedIndustry: string | null;
  industries: string[];
  onIndustryAdded: () => void;
}

const colorOptions = [
  { name: 'Gray', value: 'bg-gray-300' },
  { name: 'Blue', value: 'bg-blue-500' },
  { name: 'Green', value: 'bg-green-500' },
  { name: 'Yellow', value: 'bg-yellow-500' },
  { name: 'Red', value: 'bg-red-500' },
  { name: 'Orange', value: 'bg-orange-500' },
];

const ProspectsList = ({ 
  onSelectProspect, 
  selectedIndustry,
  industries,
  onIndustryAdded
}: ProspectsListProps) => {
  const [prospects, setProspects] = useState<any[]>([]);
  const [isAddingProspect, setIsAddingProspect] = useState(false);
  const [editingProspect, setEditingProspect] = useState<any>(null);
  const { toast } = useToast();

  const fetchProspects = async () => {
    try {
      console.log('Fetching prospects...');
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.log('No user found');
        return;
      }

      let query = supabase
        .from('prospects')
        .select('*')
        .eq('user_id', user.id);

      if (selectedIndustry) {
        query = query.eq('industry', selectedIndustry);
      }

      const { data, error } = await query;

      if (error) {
        console.error('Error fetching prospects:', error);
        throw error;
      }
      
      console.log('Fetched prospects:', data);
      const sortedProspects = (data || []).sort((a, b) => 
        a.company.localeCompare(b.company)
      );
      
      setProspects(sortedProspects);
    } catch (error) {
      console.error('Error fetching prospects:', error);
      toast({
        title: "Error",
        description: "Failed to load prospects",
        variant: "destructive",
      });
    }
  };

  const handleColorChange = async (prospectId: number, color: string) => {
    try {
      const { error } = await supabase
        .from('prospects')
        .update({ color })
        .eq('id', prospectId);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Color updated successfully",
      });
      fetchProspects();
    } catch (error) {
      console.error('Error updating color:', error);
      toast({
        title: "Error",
        description: "Failed to update color",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    fetchProspects();
  }, [selectedIndustry]);

  const handleProspectAdded = () => {
    setIsAddingProspect(false);
    setEditingProspect(null);
    fetchProspects();
    onIndustryAdded();
  };

  const handleDeleteProspect = async (id: number) => {
    try {
      const { error } = await supabase
        .from('prospects')
        .delete()
        .eq('id', id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Prospect deleted successfully",
      });
      fetchProspects();
    } catch (error) {
      console.error('Error deleting prospect:', error);
      toast({
        title: "Error",
        description: "Failed to delete prospect",
        variant: "destructive",
      });
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between bg-gray-50/80 border-b p-2">
        <div className="flex items-center gap-1.5">
          <Users className="h-4 w-4 text-gray-500" />
          <h2 className="text-base font-semibold">Prospects</h2>
        </div>
        <Dialog open={isAddingProspect} onOpenChange={setIsAddingProspect}>
          <DialogTrigger asChild>
            <Button 
              variant="ghost" 
              size="sm"
              className="h-6 w-6 p-0"
            >
              <Plus className="h-3 w-3" />
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add New Prospect</DialogTitle>
            </DialogHeader>
            <ProspectForm 
              onSuccess={() => {
                setIsAddingProspect(false);
                fetchProspects();
                onIndustryAdded();
              }} 
              selectedIndustry={selectedIndustry}
              industries={industries}
            />
          </DialogContent>
        </Dialog>
      </div>
      <div className="p-1">
        <div className="space-y-0">
          {prospects.map((prospect) => (
            <div
              key={prospect.id}
              className="flex items-center justify-between group hover:bg-gray-50/50"
            >
              <Button
                variant="ghost"
                className="w-full justify-start px-1 py-0 h-6 text-xs font-normal"
                onClick={() => onSelectProspect(prospect.id)}
              >
                <div className="flex items-center gap-1.5">
                  <DropdownMenu>
                    <DropdownMenuTrigger className="focus:outline-none">
                      <div className={`h-2.5 w-2.5 rounded-full ${prospect.color || 'bg-gray-300'}`}></div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="start">
                      {colorOptions.map((color) => (
                        <DropdownMenuItem
                          key={color.value}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleColorChange(prospect.id, color.value);
                          }}
                          className="flex items-center gap-2 text-xs py-1"
                        >
                          <div className={`h-2.5 w-2.5 rounded-full ${color.value}`}></div>
                          {color.name}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                  <div className="text-left">
                    <div className="text-xs leading-none">{prospect.company}</div>
                    {prospect.contact_name && (
                      <div className="text-[10px] text-gray-500 leading-tight mt-0">{prospect.contact_name}</div>
                    )}
                  </div>
                </div>
              </Button>
              <div className="flex gap-0.5 opacity-0 group-hover:opacity-100 transition-opacity">
                <Dialog>
                  <DialogTrigger asChild>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="h-5 w-5 p-0"
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingProspect(prospect);
                      }}
                    >
                      <Pencil className="h-2.5 w-2.5" />
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Edit Prospect</DialogTitle>
                    </DialogHeader>
                    <ProspectForm 
                      onSuccess={() => {
                        setEditingProspect(null);
                        fetchProspects();
                        onIndustryAdded();
                      }}
                      selectedIndustry={selectedIndustry}
                      industries={industries}
                      initialData={prospect}
                    />
                  </DialogContent>
                </Dialog>
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="h-5 w-5 p-0"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Trash2 className="h-2.5 w-2.5" />
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                      <AlertDialogDescription>
                        This action cannot be undone. This will permanently delete the prospect.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction onClick={() => handleDeleteProspect(prospect.id)}>
                        Delete
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProspectsList;
