import { useState, useEffect } from "react";
import { Client } from "@/types/client";
import { Stage } from "@/types/stage";
import StageClientList from "./StageClientList";
import { useClientNavigation } from "@/hooks/stages/useClientNavigation";
import { useClientFiltering } from "@/hooks/stages/useClientFiltering";
import StagePanelHeader from "./StagePanelHeader";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

interface StagePanelProps {
  title: string;
  stageId: string;
  stages: Stage[];
  clients: Client[];
  onColorChange: (clientId: number, color: string, stageName: string) => Promise<void>;
  onEditStage: (stageId: number, newName: string) => Promise<void>;
  onDeleteStage: (stageId: number) => Promise<void>;
  onDuplicateClient: (client: Client, targetStage: string) => Promise<void>;
  onDeleteClientFromStage: (clientId: number, stage: string) => void;
  onAddClientToStage: (client: Client, stage: string) => Promise<void>;
  globalIsAddingClient: boolean;
  setGlobalIsAddingClient: (value: boolean) => void;
}

const StagePanel = ({
  title,
  stageId,
  stages,
  clients,
  onColorChange,
  onEditStage,
  onDeleteStage,
  onDuplicateClient,
  onDeleteClientFromStage,
  onAddClientToStage,
  globalIsAddingClient,
  setGlobalIsAddingClient
}: StagePanelProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [allClients, setAllClients] = useState<Client[]>([]);
  const [isLocalAddingClient, setIsLocalAddingClient] = useState(false);
  const { navigateToClient } = useClientNavigation();
  const { toast } = useToast();
  
  const { 
    localClients, 
    setLocalClients, 
    clientsInStage, 
    filteredClients 
  } = useClientFiltering(clients, searchTerm);
  
  useEffect(() => {
    const fetchAllClients = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;

        const { data, error } = await supabase
          .from('clients')
          .select('*')
          .eq('user_id', user.id)
          .order('company', { ascending: true });

        if (error) throw error;
        
        if (data) {
          setAllClients(data);
        }
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchAllClients();
  }, []);
  
  useEffect(() => {
    setLocalClients(clients);
  }, [clients, setLocalClients]);

  const handleEditStage = (newName: string) => {
    onEditStage(parseInt(stageId, 10), newName);
  };

  const handleDeleteStage = () => {
    onDeleteStage(parseInt(stageId, 10));
  };

  const handleClientClick = (clientId: number) => {
    navigateToClient(clientId);
  };
  
  const handleColorChange = async (clientId: number, color: string) => {
    try {
      console.log(`StagePanel: Color change request for client ${clientId} to ${color} in stage ${title}`);
      
      // Update the local state immediately for better UX, but only for this stage's clients
      setLocalClients(prev => 
        prev.map(client => {
          if (client.id === clientId && client.current_stage === title) {
            return { ...client, bullet_color: color };
          }
          return client;
        })
      );
      
      // Then call the parent handler with the stage name to persist the change
      await onColorChange(clientId, color, title);
      
      console.log(`StagePanel: Color updated successfully for client ${clientId} in stage ${title}`);
      
      toast({
        title: "Success",
        description: "Color updated successfully",
      });
    } catch (error) {
      console.error('StagePanel: Error changing client color:', error);
      
      // Revert the local state change on error
      setLocalClients(clients);
      
      toast({
        title: "Error",
        description: "Failed to update color",
        variant: "destructive",
      });
    }
  };

  const handleAddClient = async (client: Client): Promise<boolean> => {
    try {
      setIsLocalAddingClient(true);
      setGlobalIsAddingClient(true);
      
      console.log(`Adding client ${client.company} to stage ${title}`);
      await onAddClientToStage(client, title);
      
      return true;
    } catch (error) {
      console.error("Error in handleAddClient:", error);
      return false;
    } finally {
      setIsLocalAddingClient(false);
      setGlobalIsAddingClient(false);
    }
  };

  return (
    <div className="bg-white rounded-md shadow-sm border border-gray-200 h-[300px] flex flex-col">
      <StagePanelHeader
        title={title}
        stageId={stageId}
        clientCount={filteredClients.length}
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
        onEditStage={handleEditStage}
        onDeleteStage={handleDeleteStage}
        onAddClient={handleAddClient}
        availableClients={allClients}
        clientsInStage={clientsInStage}
        isAddingClient={isLocalAddingClient || globalIsAddingClient}
      />
      
      <StageClientList 
        clients={filteredClients} 
        stages={stages}
        currentStage={title}
        onDuplicateClient={onDuplicateClient}
        onDeleteClientFromStage={onDeleteClientFromStage}
        onColorChange={handleColorChange}
        onClientClick={handleClientClick}
      />
    </div>
  );
};

export default StagePanel;
