
import { useCallback } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Client } from "@/types/client";

export const useClientFetching = (
  setSelectedClient: (client: Client | null) => void,
  setSelectedIndustry: (industry: string) => void,
  setActivePanel: (panel: string) => void
) => {
  const fetchClientById = useCallback(async (clientId: number) => {
    try {
      console.log("Dashboard: Fetching client by ID:", clientId);
      const { data: client, error } = await supabase
        .from('clients')
        .select('*')
        .eq('id', clientId)
        .single();

      if (error) {
        console.error('Error fetching client:', error);
        return;
      }

      if (client) {
        console.log("Dashboard: Client fetched successfully:", client);
        setSelectedClient(client);
        setSelectedIndustry(client.industry || "");
        setActivePanel('follow-up');
        
        // Dispatch events with minimal delay
        setTimeout(() => {
          window.dispatchEvent(new CustomEvent('force-panel-activate', {
            detail: { 
              panel: 'follow-up',
              clientId: clientId,
              source: 'fetchClientById'
            }
          }));
          
          // Request scrolling to panel
          window.dispatchEvent(new CustomEvent('scroll-to-followup', {
            detail: { 
              clientId: clientId,
              source: 'fetchClientById'
            }
          }));
        }, 0);
      }
    } catch (error) {
      console.error('Error fetching client:', error);
    }
  }, [setSelectedClient, setSelectedIndustry, setActivePanel]);

  return { fetchClientById };
};
