
import { useCallback } from 'react';
import { FollowUp } from '@/types/followUp';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';
import { followUpService } from '@/services/followUps';

export const useFollowUpSave = (
  clientId: number | undefined,
  onSuccess: () => void
) => {
  const { toast } = useToast();

  const handleSave = useCallback(async (followUpData: Omit<FollowUp, 'id'>): Promise<void> => {
    if (!clientId) {
      toast({
        title: "Error",
        description: "No client selected",
        variant: "destructive",
      });
      return;
    }

    try {
      console.log('Saving follow-up for client:', clientId, 'Data:', followUpData);
      
      const { data: session } = await supabase.auth.getSession();
      if (!session?.session?.user?.id) {
        toast({
          title: "Error",
          description: "No active session",
          variant: "destructive",
        });
        return;
      }

      // Ensure we have all required fields
      const dataToSave = {
        ...followUpData,
        user_id: session.session.user.id,
        client_id: clientId, // Always use the clientId from props
        status: followUpData.status || 'pending',
        priority: followUpData.priority || 'medium',
        // Ensure stage is set - this is often the cause of saving errors
        stage: followUpData.stage || 'Intro',
        // Make sure date is set properly
        date: followUpData.date || new Date().toISOString().split('T')[0],
      };

      // Log the complete data we're about to save - helpful for debugging
      console.log('Complete follow-up data to save:', dataToSave);

      // Use the followUpService to create the follow-up via the CRUD service
      const result = await followUpService.createFollowUp(dataToSave);

      if (result) {
        console.log('Follow-up saved successfully:', result);
        toast({
          title: "Success",
          description: "Follow-up saved successfully",
        });
        
        // Trigger a custom event to invalidate the cache
        window.dispatchEvent(new CustomEvent('invalidate-followup-cache', {
          detail: {
            clientId,
            forceRefresh: true,
            source: 'follow-up-add',
            priority: 'high'
          }
        }));
        
        onSuccess();
      } else {
        throw new Error("Failed to save follow-up");
      }
    } catch (error: any) {
      console.error('Error saving follow-up:', error);
      console.log('Error details:', {
        message: error.message,
        code: error.code,
        details: error.details,
        hint: error.hint
      });
      
      // Provide more specific error messages
      let errorMessage = "Failed to save follow-up";
      
      if (error.code === '23505') {
        // This is a much less alarming message now that we've fixed the underlying issue
        errorMessage = "This follow-up entry already exists. Please try with different details.";
        
        // Refresh to see the current state
        window.dispatchEvent(new CustomEvent('invalidate-followup-cache', {
          detail: {
            clientId,
            forceRefresh: true,
            source: 'follow-up-error-recovery',
            priority: 'critical'
          }
        }));
      } else if (error.code === '23502' && error.message?.includes('null value in column')) {
        // Identify which column is null
        const columnMatch = error.message.match(/column "([^"]+)"/);
        const columnName = columnMatch ? columnMatch[1] : 'unknown';
        errorMessage = `Missing required field: ${columnName}. Please fill in all required fields.`;
      } else {
        errorMessage = error.message || "Failed to save follow-up";
      }
      
      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive",
      });
      
      throw error; // Re-throw so the calling component can handle it
    }
  }, [clientId, toast, onSuccess]);

  return { handleSave };
};
