
import { useCallback, useRef } from "react";
import { useToast } from "@/hooks/use-toast";

export const useDashboardRefresh = (
  setSelectedClient: React.Dispatch<React.SetStateAction<any>>,
  setSelectedIndustry: React.Dispatch<React.SetStateAction<string>>,
  setActivePanel: React.Dispatch<React.SetStateAction<string>>
) => {
  const { toast } = useToast();
  const isRefreshingRef = useRef(false);
  const lastRefreshTimeRef = useRef(0);
  const refreshTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleRefresh = useCallback(async () => {
    // Prevent multiple rapid refreshes with much stronger throttling
    const now = Date.now();
    
    // If already refreshing or refreshed very recently, ignore this request completely
    if (isRefreshingRef.current || (now - lastRefreshTimeRef.current < 10000)) { // Increased from 5000 to 10000ms
      console.log('Dashboard refresh heavily throttled - ignoring refresh request');
      return;
    }
    
    // Clear any pending refresh timeout
    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current);
      refreshTimeoutRef.current = null;
    }
    
    try {
      isRefreshingRef.current = true;
      lastRefreshTimeRef.current = now;
      
      // Add a brief delay before state updates to let any rendering complete
      await new Promise(resolve => setTimeout(resolve, 100));
      
      // Perform all state resets atomically in a single batch
      console.log('Executing dashboard refresh');
      
      // Update all state at once
      setSelectedClient(null);
      setSelectedIndustry("");
      setActivePanel("");
      
      // Only show toast after a delay to ensure UI has settled
      setTimeout(() => {
        toast({
          title: "Dashboard refreshed",
          description: "All data has been updated.",
        });
      }, 500);
    } catch (error) {
      console.error('Error refreshing dashboard:', error);
      toast({
        title: "Refresh failed",
        description: "Please try again.",
        variant: "destructive",
      });
    } finally {
      // Set a much longer timeout to reset the refreshing flag
      refreshTimeoutRef.current = setTimeout(() => {
        isRefreshingRef.current = false;
        refreshTimeoutRef.current = null;
      }, 10000); // Doubled from 5000 to 10000ms
    }
  }, [toast, setSelectedClient, setSelectedIndustry, setActivePanel]);

  return { handleRefresh };
};
