import { useState } from "react";
import Header from "@/components/Header";
import { useStages } from "@/hooks/useStages";
import StagesLoadingState from "@/components/stages/StagesLoadingState";
import StagesEmptyState from "@/components/stages/StagesEmptyState";
import StagesHeader from "@/components/stages/StagesHeader";
import StagesGrid from "@/components/stages/StagesGrid";
import { Client } from "@/types/client"; // Add this import

const Stages = () => {
  const [isAddingClient, setIsAddingClient] = useState(false);
  
  const { 
    clients, 
    stages, 
    isLoading,
    handleDeleteClientFromStage,
    handleDuplicateClient,
    handleColorChange,
    handleAddStage,
    handleEditStage,
    handleDeleteStage,
    handleAddClientToStage
  } = useStages();

  // Filter stages to exclude Task_ stages and completed stages
  const filteredStages = stages.filter(stage => {
    if (stage.name.toLowerCase().startsWith('task_')) {
      return false;
    }
    
    if (["Completed", "Delivered", "Done"].includes(stage.name)) {
      return false;
    }
    
    return true;
  });
  
  // De-duplicate clients by stage
  const uniqueClientsByStage = new Map();
  
  clients.forEach(client => {
    const key = `${client.id}-${client.current_stage}`;
    
    if (!uniqueClientsByStage.has(key) || 
        new Date(client.created_at) > new Date(uniqueClientsByStage.get(key).created_at)) {
      uniqueClientsByStage.set(key, client);
    }
  });
  
  const deduplicatedClients = Array.from(uniqueClientsByStage.values());

  if (isLoading) {
    return (
      <div className="min-h-screen bg-background">
        <Header />
        <StagesLoadingState />
      </div>
    );
  }

  // Function to fetch data - wrapped to match Promise<void> expected type
  const fetchDataAsync = async () => {
    // Just a wrapper to ensure the return type is Promise<void>
    return Promise.resolve();
  };

  if (filteredStages.length === 0) {
    return (
      <div className="min-h-screen bg-background">
        <Header />
        <div className="container mx-auto p-4 max-w-7xl">
          <StagesHeader onAddStage={handleAddStage} />
          <StagesEmptyState 
            onAddStage={handleAddStage} 
            fetchData={fetchDataAsync}
          />
        </div>
      </div>
    );
  }

  // Wrapper for handleDuplicateClient to ensure it returns Promise<void>
  const handleDuplicateClientWrapper = async (client: Client, targetStage: string) => {
    await handleDuplicateClient(client, targetStage);
  };

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <div className="container mx-auto p-4 max-w-7xl">
        <StagesHeader onAddStage={handleAddStage} />
        <StagesGrid 
          stages={stages}
          filteredStages={filteredStages}
          clients={deduplicatedClients}
          handleColorChange={handleColorChange}
          handleEditStage={handleEditStage}
          handleDeleteStage={handleDeleteStage}
          handleDuplicateClient={handleDuplicateClientWrapper}
          handleDeleteClientFromStage={handleDeleteClientFromStage}
          handleAddClientToStage={handleAddClientToStage}
          isAddingClient={isAddingClient}
          setIsAddingClient={setIsAddingClient}
        />
      </div>
    </div>
  );
};

export default Stages;
