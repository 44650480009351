
import { MessageSquarePlus, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { CardHeader, CardTitle } from "@/components/ui/card";
import { Client } from "@/types/client";

interface FollowUpHeaderProps {
  selectedClient: Client | null;
  showForm: boolean;
  onAddNew: () => void;
  onCancel: () => void;
}

const FollowUpHeader = ({ selectedClient, showForm, onAddNew, onCancel }: FollowUpHeaderProps) => {
  return (
    <CardHeader className="bg-gray-50/80 border-b py-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <MessageSquarePlus className="w-5 h-5 text-gray-600" />
          <div>
            <CardTitle className="text-lg font-medium text-gray-700">Follow Up</CardTitle>
            {selectedClient && (
              <p className="text-sm text-gray-600">{selectedClient.company}</p>
            )}
          </div>
        </div>
        <Button
          onClick={showForm ? onCancel : onAddNew}
          className="bg-gray-100 text-gray-700 hover:bg-gray-200"
          size="icon"
          disabled={!selectedClient}
        >
          {showForm ? (
            <span className="h-4 w-4">×</span>
          ) : (
            <Plus className="h-4 w-4" />
          )}
        </Button>
      </div>
    </CardHeader>
  );
};

export default FollowUpHeader;
