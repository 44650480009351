
import { useState, useEffect, useRef } from "react";
import { X } from "lucide-react";
import { Client } from "@/types/client";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { Loader2 } from "lucide-react";

interface ClientAddDropdownProps {
  onAddClient: (client: Client) => Promise<boolean>;
  availableClients: Client[];
  clientsInStage: number[];
  onClose: () => void;
  isAddingClient?: boolean;
}

const ClientAddDropdown = ({
  onAddClient,
  availableClients,
  clientsInStage,
  onClose,
  isAddingClient = false
}: ClientAddDropdownProps) => {
  const [addClientQuery, setAddClientQuery] = useState("");
  const [filteredClients, setFilteredClients] = useState<Client[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [localIsAdding, setLocalIsAdding] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectedItemRef = useRef<HTMLDivElement>(null);
  const { toast } = useToast();

  // Filter clients
  const filterClients = (query: string) => {
    if (!availableClients || availableClients.length === 0) return [];
    
    // Filter out clients already in this stage
    const clientsNotInStage = availableClients.filter(
      client => !clientsInStage.includes(client.id)
    );
    
    if (!query.trim()) {
      return clientsNotInStage.slice(0, 50); // Limit results for performance
    }
    
    // Case-insensitive search
    const lowerQuery = query.toLowerCase();
    return clientsNotInStage
      .filter(client => client.company.toLowerCase().includes(lowerQuery))
      .slice(0, 20);
  };

  // Update filtered clients when search query changes
  useEffect(() => {
    const filtered = filterClients(addClientQuery);
    setFilteredClients(filtered);
    setShowDropdown(filtered.length > 0 && !isAddingClient && !localIsAdding);
    
    // Reset selected index when filter changes
    setSelectedIndex(0);
  }, [addClientQuery, availableClients, clientsInStage, isAddingClient, localIsAdding]);

  // Show initial results when opened
  useEffect(() => {
    // Show initial results if query is empty
    if (availableClients && availableClients.length > 0) {
      const initialFiltered = filterClients("");
      setFilteredClients(initialFiltered);
      setShowDropdown(initialFiltered.length > 0 && !isAddingClient && !localIsAdding);
    }
  }, [availableClients, isAddingClient, localIsAdding]);

  // Focus input when component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // Scroll selected item into view
  useEffect(() => {
    if (selectedItemRef.current && dropdownRef.current) {
      selectedItemRef.current.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth'
      });
    }
  }, [selectedIndex]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current && 
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current && 
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAddClientSearch = (value: string) => {
    setAddClientQuery(value);
  };

  const handleClientSelect = async (client: Client) => {
    try {
      if (isAddingClient || localIsAdding) {
        toast({
          title: "Please wait",
          description: "Currently adding a client...",
        });
        return;
      }
      
      // Hide dropdown immediately for better UX
      setShowDropdown(false);
      setLocalIsAdding(true);
      
      console.log(`Selected client: ${client.company} (ID: ${client.id}) for adding to stage`);
      
      // Add the client to the stage
      const success = await onAddClient(client);
      
      if (success) {
        setAddClientQuery("");
      }
    } catch (error) {
      console.error("Error adding client:", error);
      toast({
        title: "Error",
        description: "Failed to add client to stage",
        variant: "destructive",
      });
    } finally {
      setLocalIsAdding(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (filteredClients.length === 0) return;
    
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedIndex(prev => (prev + 1) % filteredClients.length);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedIndex(prev => (prev - 1 + filteredClients.length) % filteredClients.length);
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (filteredClients[selectedIndex]) {
        handleClientSelect(filteredClients[selectedIndex]);
      }
    } else if (e.key === 'Escape') {
      e.preventDefault();
      onClose();
    }
  };

  const isLoading = isAddingClient || localIsAdding;

  return (
    <div className="relative">
      <div className="flex items-center">
        <Input
          ref={inputRef}
          type="text"
          placeholder="Search clients..."
          value={addClientQuery}
          onChange={(e) => handleAddClientSearch(e.target.value)}
          onKeyDown={handleKeyDown}
          className="h-6 text-xs w-[150px]"
          autoFocus
          disabled={isLoading}
        />
        <Button 
          variant="ghost" 
          size="icon" 
          className="h-6 w-6 ml-0.5"
          onClick={onClose}
          disabled={isLoading}
        >
          <X className="h-3 w-3" />
        </Button>
      </div>
      
      {showDropdown && (
        <div 
          ref={dropdownRef}
          className="absolute top-7 right-0 z-10 w-[220px] max-h-[200px] overflow-y-auto bg-white border rounded-md shadow-md"
        >
          {filteredClients.length === 0 ? (
            <div className="px-2 py-1 text-xs text-gray-500 italic">
              No available clients found
            </div>
          ) : (
            <div className="py-1">
              {filteredClients.map((client, index) => (
                <div 
                  key={client.id}
                  ref={index === selectedIndex ? selectedItemRef : null}
                  className={`px-2 py-1.5 hover:bg-gray-100 cursor-pointer text-xs border-b last:border-0 ${selectedIndex === index ? 'bg-gray-100' : ''}`}
                  onClick={() => handleClientSelect(client)}
                  tabIndex={0}
                  role="button"
                  aria-label={`Add ${client.company}`}
                >
                  {client.company}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      
      {isLoading && (
        <div className="absolute top-7 right-0 z-10 w-[220px] bg-white border rounded-md shadow-md p-2">
          <div className="text-xs flex items-center justify-center text-gray-600 gap-2">
            <Loader2 className="h-3 w-3 animate-spin" />
            Adding client...
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientAddDropdown;
