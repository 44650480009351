
import { useState, useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

interface ColorPickerButtonProps {
  currentColor: string;
  onColorSelect: (color: string) => Promise<void>;
  isCompact?: boolean;
}

const ColorPickerButton = ({ 
  currentColor, 
  onColorSelect, 
  isCompact = false 
}: ColorPickerButtonProps) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localColor, setLocalColor] = useState(currentColor || 'bg-gray-400');
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Update local color when currentColor prop changes
  useEffect(() => {
    setLocalColor(currentColor || 'bg-gray-400');
  }, [currentColor]);

  // Consistent color list with good options
  const colors = [
    { name: "Yellow", class: "bg-yellow-500" },
    { name: "Green", class: "bg-green-500" },
    { name: "Red", class: "bg-red-500" },
    { name: "Blue", class: "bg-blue-500" },
    { name: "Gray", class: "bg-gray-500" },
    { name: "Orange", class: "bg-orange-500" },
    { name: "Pink", class: "bg-pink-300" },
    { name: "Purple", class: "bg-purple-500" },
    { name: "Teal", class: "bg-teal-500" },
    { name: "Indigo", class: "bg-indigo-500" },
  ];

  const handleColorSelect = async (color: string, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    console.log("ColorPickerButton: Color selected:", color);
    setIsLoading(true);
    setLocalColor(color); // Update local state immediately for better UX
    
    try {
      await onColorSelect(color);
      console.log("ColorPickerButton: Color change successful");
      setOpen(false);
    } catch (error) {
      console.error("ColorPickerButton: Error selecting color:", error);
      // Reset to previous color on error
      setLocalColor(currentColor || 'bg-gray-400');
    } finally {
      setIsLoading(false);
    }
  };

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
    console.log("ColorPickerButton: Button clicked, opening popover");
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          ref={buttonRef}
          variant="ghost"
          className={`${isCompact ? 'h-4 w-4' : 'h-6 w-6'} p-0 hover:bg-transparent z-10`}
          onClick={handleButtonClick}
          type="button"
          disabled={isLoading}
          data-testid="color-picker-button"
        >
          {isLoading ? (
            <Loader2 className={`${isCompact ? 'h-2 w-2' : 'h-3 w-3'} animate-spin`} />
          ) : (
            <div 
              className={`${isCompact ? 'h-2 w-2' : 'h-3 w-3'} rounded-full ${localColor}`}
              data-testid="color-dot"
            />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent 
        className="w-fit p-2 bg-white shadow-md z-[100]"
        onClick={(e) => e.stopPropagation()}
        sideOffset={5}
        align="start"
      >
        <div className="flex flex-wrap gap-2 max-w-[180px]">
          {colors.map((color) => (
            <Button
              key={color.name}
              variant="ghost"
              className="h-6 w-6 p-0 hover:bg-transparent"
              onClick={(e) => handleColorSelect(color.class, e)}
              title={color.name}
              type="button"
            >
              <div className={`h-3 w-3 rounded-full ${color.class}`} />
            </Button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ColorPickerButton;
