import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface AddressFieldsProps {
  formData: {
    address: string;
    city: string;
    state: string;
    zip: string;
  };
  onChange: (field: string, value: string) => void;
}

export const AddressFields = ({ formData, onChange }: AddressFieldsProps) => {
  return (
    <>
      <div className="col-span-2">
        <Label htmlFor="address" className="text-xs">Address</Label>
        <Input
          id="address"
          value={formData.address}
          onChange={(e) => onChange("address", e.target.value)}
          className="h-7 text-xs"
        />
      </div>

      <div>
        <Label htmlFor="city" className="text-xs">City</Label>
        <Input
          id="city"
          value={formData.city}
          onChange={(e) => onChange("city", e.target.value)}
          className="h-7 text-xs"
        />
      </div>

      <div>
        <Label htmlFor="state" className="text-xs">State</Label>
        <Input
          id="state"
          value={formData.state}
          onChange={(e) => onChange("state", e.target.value)}
          className="h-7 text-xs"
        />
      </div>

      <div className="col-span-2">
        <Label htmlFor="zip" className="text-xs">ZIP Code</Label>
        <Input
          id="zip"
          value={formData.zip}
          onChange={(e) => onChange("zip", e.target.value)}
          className="h-7 text-xs"
        />
      </div>
    </>
  );
};