
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "@/components/auth/ProtectedRoute";
import Index from "@/pages/Index";
import Login from "@/pages/Login";
import Contact from "@/pages/Contact";
import Register from "@/pages/Register";
import ResetPassword from "@/pages/ResetPassword";
import Prospecting from "@/pages/Prospecting";
import About from "@/pages/About";
import Weeks from "@/pages/Weeks";
import Stages from "@/pages/Stages";
import TradeShows from "@/pages/TradeShows";
import Plans from "@/pages/Plans";
import Profile from "@/pages/Profile";
import Reports from "@/pages/Reports";
import RocketAnimation from "@/pages/RocketAnimation";
import Landing from "@/pages/Landing";
import Success from "@/pages/Success";
import RegistrationComplete from "@/pages/RegistrationComplete";
import Admin from "@/pages/Admin";
import { RegistrationProvider } from "@/components/auth/RegistrationContext";

function App() {
  return (
    <Router>
      <RegistrationProvider>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/success" element={<Success />} />
          <Route path="/registration-complete" element={<RegistrationComplete />} />
          <Route path="/rocket" element={<RocketAnimation />} />

          <Route path="/index" element={<ProtectedRoute><Index /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/prospecting" element={<ProtectedRoute><Prospecting /></ProtectedRoute>} />
          <Route path="/weeks" element={<ProtectedRoute><Weeks /></ProtectedRoute>} />
          <Route path="/stages" element={<ProtectedRoute><Stages /></ProtectedRoute>} />
          <Route path="/trade-shows" element={<ProtectedRoute><TradeShows /></ProtectedRoute>} />
          <Route path="/reports" element={<ProtectedRoute><Reports /></ProtectedRoute>} />
          <Route path="/admin" element={<ProtectedRoute><Admin /></ProtectedRoute>} />
        </Routes>
      </RegistrationProvider>
    </Router>
  );
}

export default App;
