
import { Building2, Mail, Phone, MapPin, Factory } from "lucide-react";
import { TradeShowContact } from "@/types/tradeShow";
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { formatPhoneNumber } from "@/lib/formatPhone";

interface ContactBasicDetailsProps {
  contact: TradeShowContact;
}

const ContactBasicDetails = ({ contact }: ContactBasicDetailsProps) => {
  const [industries, setIndustries] = useState<string[]>([]);

  useEffect(() => {
    fetchContactIndustries();
  }, [contact.id]);

  const fetchContactIndustries = async () => {
    try {
      const { data, error } = await supabase
        .from("trade_show_contact_industries")
        .select("industry_name")
        .eq("contact_id", contact.id);

      if (error) throw error;
      setIndustries(data.map(item => item.industry_name));
    } catch (error) {
      console.error("Error fetching contact industries:", error);
    }
  };

  return (
    <div className="grid grid-cols-2 gap-3">
      {contact.business_card_url && (
        <div className="col-span-2 bg-white/40 rounded-lg p-3 shadow-sm">
          <img 
            src={contact.business_card_url} 
            alt="Business Card" 
            className="w-full h-auto rounded-md shadow-sm"
          />
        </div>
      )}

      <div className="col-span-2 bg-white/40 rounded-lg p-3 shadow-sm">
        <div className="flex items-start gap-2">
          <Building2 className="w-4 h-4 text-gray-500 mt-1" />
          <div>
            <h3 className="text-base font-medium text-gray-900">{contact.company}</h3>
            {contact.contact && (
              <p className="text-sm text-gray-600">{contact.contact}</p>
            )}
          </div>
        </div>
      </div>

      {contact.email && (
        <div className="bg-white/40 rounded-lg p-3 shadow-sm">
          <div className="flex items-center gap-2">
            <Mail className="w-4 h-4 text-gray-500 flex-shrink-0" />
            <a 
              href={`mailto:${contact.email}`} 
              className="text-gray-600 hover:text-gray-700 transition-colors truncate text-sm"
            >
              {contact.email}
            </a>
          </div>
        </div>
      )}
      
      {contact.phone && (
        <div className="bg-white/40 rounded-lg p-3 shadow-sm">
          <div className="flex items-center gap-2">
            <Phone className="w-4 h-4 text-gray-500 flex-shrink-0" />
            <a 
              href={`tel:${contact.phone}`} 
              className="text-gray-600 hover:text-gray-700 transition-colors text-sm"
            >
              {formatPhoneNumber(contact.phone)}
            </a>
          </div>
        </div>
      )}

      {(contact.city || contact.state || contact.country) && (
        <div className="col-span-2 bg-white/40 rounded-lg p-3 shadow-sm">
          <div className="flex items-start gap-2">
            <MapPin className="w-4 h-4 text-gray-500 mt-0.5 flex-shrink-0" />
            <p className="text-sm text-gray-700">
              {[
                contact.city,
                contact.state,
                contact.country
              ].filter(Boolean).join(", ")}
            </p>
          </div>
        </div>
      )}

      {industries.length > 0 && (
        <div className="col-span-2 bg-white/40 rounded-lg p-3 shadow-sm">
          <div className="flex items-start gap-2">
            <Factory className="w-4 h-4 text-gray-500 mt-0.5 flex-shrink-0" />
            <div className="flex flex-wrap gap-2">
              {industries.map((industry, index) => (
                <span 
                  key={index}
                  className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                >
                  {industry}
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactBasicDetails;
