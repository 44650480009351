
import { useState, useCallback, useMemo } from 'react';
import { FollowUp } from '@/types/followUp';

export const useFollowUpSort = (followUps: FollowUp[]) => {
  const [sortConfig, setSortConfig] = useState<{ key: keyof FollowUp; direction: 'asc' | 'desc' } | null>(null);

  const handleSort = useCallback((key: keyof FollowUp) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig?.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  }, []);

  const getSortedFollowUps = useMemo(() => {
    if (!followUps.length) return [];
    
    // Set reference date to 03/04/25 for consistency
    const baseDate = new Date('2025-03-04');
    
    return [...followUps].sort((a, b) => {
      if (!sortConfig) {
        // Default sorting: most recent created_at first, then by status
        // Using base date as 03/04/25
        const dateA = a.created_at ? new Date(a.created_at) : baseDate;
        const dateB = b.created_at ? new Date(b.created_at) : baseDate;
        
        // Newest follow-ups first for drag-drop operations to appear at top
        if (dateA.getTime() !== dateB.getTime()) {
          return dateB.getTime() - dateA.getTime();
        }
        
        // Stage changes should be prioritized in display
        if (a.action?.includes('Moved from') && !b.action?.includes('Moved from')) {
          return -1;
        }
        if (!a.action?.includes('Moved from') && b.action?.includes('Moved from')) {
          return 1;
        }
        
        if (a.status === b.status) return 0;
        if (a.status === 'pending') return -1;
        if (b.status === 'pending') return 1;
        if (a.status === 'completed') return 1;
        if (b.status === 'completed') return -1;
        return 0;
      }
      
      if (sortConfig.key === 'date' || sortConfig.key === 'created_at') {
        const dateA = new Date(a[sortConfig.key] || baseDate);
        const dateB = new Date(b[sortConfig.key] || baseDate);
        return sortConfig.direction === 'asc' 
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      }

      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      
      if (aValue === bValue) return 0;
      if (aValue === null) return 1;
      if (bValue === null) return -1;
      
      const comparison = aValue < bValue ? -1 : 1;
      return sortConfig.direction === 'asc' ? comparison : -comparison;
    });
  }, [followUps, sortConfig]);

  return {
    sortConfig,
    handleSort,
    sortedFollowUps: getSortedFollowUps
  };
};
