
import { MoreHorizontal, Plus, Search, Edit, Trash } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface StageActionsProps {
  title: string;
  stageId: string;
  onEditStage: (name: string) => void;
  onDeleteStage: () => void;
  onOpenAddClient: () => void;
  onOpenSearch: () => void;
  isAddingClient?: boolean;
}

const StageActions = ({
  title,
  stageId,
  onEditStage,
  onDeleteStage,
  onOpenAddClient,
  onOpenSearch,
  isAddingClient = false
}: StageActionsProps) => {
  const handleEditStage = () => {
    const newName = window.prompt("Enter new stage name:", title);
    if (newName && newName.trim() !== "" && newName !== title) {
      onEditStage(newName);
    }
  };

  const handleDeleteStage = () => {
    if (window.confirm(`Are you sure you want to delete the "${title}" stage?`)) {
      onDeleteStage();
    }
  };

  return (
    <div className="flex space-x-1">
      <Button
        variant="ghost"
        size="icon"
        className="h-6 w-6"
        onClick={onOpenAddClient}
        disabled={isAddingClient}
      >
        <Plus className="h-3 w-3" />
      </Button>
      <Button variant="ghost" size="icon" className="h-6 w-6" onClick={onOpenSearch}>
        <Search className="h-3 w-3" />
      </Button>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon" className="h-6 w-6">
            <MoreHorizontal className="h-3 w-3" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={handleEditStage}>
            <Edit className="mr-2 h-3 w-3" /> Edit
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleDeleteStage}>
            <Trash className="mr-2 h-3 w-3" /> Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default StageActions;
