
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { RegistrationProvider } from "@/components/auth/RegistrationContext";
import { RegistrationForm } from "@/components/auth/RegistrationForm";
import { RegistrationHeader } from "@/components/auth/RegistrationHeader";
import { StripePricingTable } from "@/components/plans/StripePricingTable";
import Header from "@/components/Header";

const Register = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { toast } = useToast();
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState<string | null>(null);
  const [setupToken, setSetupToken] = useState<string | null>(null);

  useEffect(() => {
    const setupTokenParam = searchParams.get('setup_token');
    console.log('Setup token:', setupTokenParam);

    const verifySetupToken = async () => {
      if (!setupTokenParam) {
        console.log('No setup token found');
        setLoading(false);
        return;
      }

      try {
        const { data: subscription, error } = await supabase
          .from('pending_subscriptions')
          .select('subscription_status, email')
          .eq('setup_token', setupTokenParam)
          .single();

        console.log('Subscription data:', subscription, 'Error:', error);

        if (error) throw error;

        if (!subscription || subscription.subscription_status !== 'active') {
          throw new Error('Invalid or expired setup token');
        }

        setEmail(subscription.email);
        setSetupToken(setupTokenParam);
      } catch (error: any) {
        console.error('Setup token verification error:', error);
        toast({
          title: "Error",
          description: error.message,
          variant: "destructive",
        });
      } finally {
        setLoading(false);
      }
    };

    verifySetupToken();
  }, [searchParams, toast]);

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-md mx-auto">
          <RegistrationProvider>
            <RegistrationHeader registrationType="trial" />
            <RegistrationForm />
          </RegistrationProvider>
        </div>
      </div>
    </div>
  );
};

export default Register;
