
import { Badge } from "@/components/ui/badge";
import { Client } from "@/types/client";
import StageActions from "./StageActions";
import StageSearch from "./StageSearch";
import ClientAddDropdown from "./ClientAddDropdown";
import { useState, useEffect } from "react";

interface StagePanelHeaderProps {
  title: string;
  stageId: string;
  clientCount: number;
  searchTerm: string;
  onSearchChange: (term: string) => void;
  onEditStage: (name: string) => void;
  onDeleteStage: () => void;
  onAddClient: (client: Client) => Promise<boolean>;
  availableClients: Client[];
  clientsInStage: number[];
  isAddingClient: boolean;
}

const StagePanelHeader = ({
  title,
  stageId,
  clientCount,
  searchTerm,
  onSearchChange,
  onEditStage,
  onDeleteStage,
  onAddClient,
  availableClients,
  clientsInStage,
  isAddingClient
}: StagePanelHeaderProps) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isAddClientOpen, setIsAddClientOpen] = useState(false);

  // Close other components when one opens
  useEffect(() => {
    if (isAddClientOpen && isSearchOpen) {
      setIsSearchOpen(false);
    }
  }, [isAddClientOpen]);

  useEffect(() => {
    if (isSearchOpen && isAddClientOpen) {
      setIsAddClientOpen(false);
    }
  }, [isSearchOpen]);

  // Toggle add client dropdown
  const toggleAddClientMode = () => {
    setIsAddClientOpen(!isAddClientOpen);
    
    // If we're closing add client, make sure search is also closed
    if (isAddClientOpen) {
      setIsSearchOpen(false);
    }
  };

  // Toggle search
  const toggleSearch = () => {
    const newSearchOpen = !isSearchOpen;
    setIsSearchOpen(newSearchOpen);
    
    // If we're closing search, clear the search term
    if (!newSearchOpen) {
      onSearchChange("");
    }
    
    // If we're opening search, close add client
    if (newSearchOpen) {
      setIsAddClientOpen(false);
    }
  };

  // Handle adding a client
  const handleAddClient = async (client: Client) => {
    try {
      const result = await onAddClient(client);
      if (result) {
        setIsAddClientOpen(false);
      }
      return result;
    } catch (error) {
      console.error("Error adding client:", error);
      return false;
    }
  };

  return (
    <div className="p-2 border-b">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-1.5">
          <h3 className="text-sm font-medium truncate max-w-[100px] xs:max-w-[140px]">
            {title}
          </h3>
          <Badge variant="outline" className="h-5 px-1.5 text-[10px]">
            {clientCount}
          </Badge>
        </div>

        <div className="flex items-center">
          {isAddClientOpen ? (
            <ClientAddDropdown
              onAddClient={handleAddClient}
              availableClients={availableClients}
              clientsInStage={clientsInStage}
              onClose={toggleAddClientMode}
              isAddingClient={isAddingClient}
            />
          ) : isSearchOpen ? (
            <StageSearch
              searchTerm={searchTerm}
              onSearchChange={onSearchChange}
              onClose={toggleSearch}
            />
          ) : (
            <StageActions
              title={title}
              stageId={stageId}
              onEditStage={onEditStage}
              onDeleteStage={onDeleteStage}
              onOpenAddClient={toggleAddClientMode}
              onOpenSearch={toggleSearch}
              isAddingClient={isAddingClient}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StagePanelHeader;
