import React from "react";
import { FollowUp } from "@/types/followUp";
import TableRow from "./TableRow";
import EmptyState from "./EmptyState";

interface TableBodyProps {
  followUps: FollowUp[];
  isMobile: boolean;
  onUpdateFollowUp: (id: string, field: keyof FollowUp, value: string) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  deletingRows?: Set<string>;
}

const TableBody: React.FC<TableBodyProps> = ({
  followUps,
  isMobile,
  onUpdateFollowUp,
  onDelete,
  deletingRows = new Set()
}) => {
  console.log("TableBody rendering with followUps:", followUps.length);
  
  // Add a much larger delay between status updates to prevent race conditions
  const handleUpdateFollowUp = async (id: string, field: keyof FollowUp, value: string) => {
    try {
      if (field === 'status') {
        // Add a significant delay to ensure previous operations are complete
        await new Promise(resolve => setTimeout(resolve, 500));
      }
      return onUpdateFollowUp(id, field, value);
    } catch (error) {
      console.error("Error updating follow-up:", error);
      throw error;
    }
  };

  const handleDelete = async (id: string) => {
    try {
      console.log("TableBody calling delete handler for ID:", id);
      await onDelete(id);
    } catch (error) {
      console.error("Error in TableBody when deleting follow-up:", error);
    }
  };

  if (followUps.length === 0) {
    console.log("No follow-ups to display");
    return (
      <tbody>
        <EmptyState />
      </tbody>
    );
  }

  const visibleFollowUps = followUps.filter(followUp => !deletingRows.has(followUp.id));

  if (visibleFollowUps.length === 0) {
    return (
      <tbody>
        <tr>
          <td colSpan={5} className="text-center py-6 text-gray-500">
            Loading...
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody>
      {visibleFollowUps.map((followUp) => (
        <TableRow
          key={followUp.id}
          followUp={followUp}
          isMobile={isMobile}
          onUpdateFollowUp={onUpdateFollowUp}
          onDelete={handleDelete}
          isDeleting={deletingRows.has(followUp.id)}
        />
      ))}
    </tbody>
  );
};

// Add a custom comparison function to prevent unnecessary re-renders
const arePropsEqual = (prevProps: TableBodyProps, nextProps: TableBodyProps) => {
  // Only re-render if followUps array or isMobile has changed
  if (prevProps.isMobile !== nextProps.isMobile) return false;
  
  if (prevProps.followUps.length !== nextProps.followUps.length) return false;
  
  // Always re-render if deletingRows has changed
  if (prevProps.deletingRows?.size !== nextProps.deletingRows?.size) return false;
  
  // Check if any follow-up items have changed
  const prevIds = new Set(prevProps.followUps.map(f => f.id));
  const nextIds = new Set(nextProps.followUps.map(f => f.id));
  
  // First check if the IDs are the same
  if (prevIds.size !== nextIds.size) return false;
  
  // Then compare each follow-up item
  for (const followUp of nextProps.followUps) {
    const prevItem = prevProps.followUps.find(f => f.id === followUp.id);
    
    if (!prevItem) return false;
    
    // Check important properties
    if (
      prevItem.status !== followUp.status ||
      prevItem.description !== followUp.description ||
      prevItem.next_step !== followUp.next_step ||
      prevItem.date !== followUp.date ||
      prevProps.deletingRows?.has(followUp.id) !== nextProps.deletingRows?.has(followUp.id)
    ) {
      return false;
    }
  }
  
  return true;
};

export default React.memo(TableBody, arePropsEqual);
