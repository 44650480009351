
import PaginationControls from "@/components/PaginationControls";

interface ClientListPaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const ClientListPagination = ({ 
  currentPage, 
  totalPages, 
  onPageChange 
}: ClientListPaginationProps) => {
  if (totalPages <= 1) return null;
  
  return (
    <div className="p-3 border-t mt-auto">
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default ClientListPagination;
