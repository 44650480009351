import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Plus, Trash2 } from "lucide-react";

interface Contact {
  name: string;
  phone: string;
  mobile: string;
  email: string;
}

interface CompanyContactsFieldsProps {
  contacts: Contact[];
  onChange: (contacts: Contact[]) => void;
}

export const CompanyContactsFields = ({ contacts, onChange }: CompanyContactsFieldsProps) => {
  const addContact = () => {
    onChange([...contacts, { name: "", phone: "", mobile: "", email: "" }]);
  };

  const removeContact = (index: number) => {
    const newContacts = contacts.filter((_, i) => i !== index);
    onChange(newContacts);
  };

  const updateContact = (index: number, field: keyof Contact, value: string) => {
    const newContacts = contacts.map((contact, i) => {
      if (i === index) {
        return { ...contact, [field]: value };
      }
      return contact;
    });
    onChange(newContacts);
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between">
        <Label className="text-xs">Additional Contacts</Label>
        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={addContact}
          className="h-6 text-xs"
        >
          <Plus className="h-3 w-3 mr-1" />
          Add Contact
        </Button>
      </div>

      {contacts.map((contact, index) => (
        <div key={index} className="space-y-2 p-2 border rounded-md relative">
          <Button
            type="button"
            variant="ghost"
            size="icon"
            className="absolute right-1 top-1 h-5 w-5"
            onClick={() => removeContact(index)}
          >
            <Trash2 className="h-3 w-3 text-gray-500" />
          </Button>

          <div>
            <Label htmlFor={`contact-name-${index}`} className="text-xs">Name</Label>
            <Input
              id={`contact-name-${index}`}
              value={contact.name}
              onChange={(e) => updateContact(index, "name", e.target.value)}
              className="h-7 text-xs"
            />
          </div>

          <div className="grid grid-cols-2 gap-2">
            <div>
              <Label htmlFor={`contact-phone-${index}`} className="text-xs">Phone</Label>
              <Input
                id={`contact-phone-${index}`}
                value={contact.phone}
                onChange={(e) => updateContact(index, "phone", e.target.value)}
                className="h-7 text-xs"
              />
            </div>
            <div>
              <Label htmlFor={`contact-mobile-${index}`} className="text-xs">Mobile</Label>
              <Input
                id={`contact-mobile-${index}`}
                value={contact.mobile}
                onChange={(e) => updateContact(index, "mobile", e.target.value)}
                className="h-7 text-xs"
              />
            </div>
          </div>

          <div>
            <Label htmlFor={`contact-email-${index}`} className="text-xs">Email</Label>
            <Input
              id={`contact-email-${index}`}
              type="email"
              value={contact.email}
              onChange={(e) => updateContact(index, "email", e.target.value)}
              className="h-7 text-xs"
            />
          </div>
        </div>
      ))}
    </div>
  );
};