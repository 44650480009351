
import { supabase } from "@/integrations/supabase/client";
import { getCachedData, setCachedData, isRecentlyNavigated } from "@/hooks/followUps/followUpCache";

// Track in-flight requests to prevent duplicates
const pendingRequests: Record<string, Promise<any>> = {};
// Flag to track background fetch status
let isBackgroundFetchInProgress = false;
// Recursion protection
let fetchDepth = 0;
const MAX_FETCH_DEPTH = 2;

/**
 * Fetches follow-ups for a specific client
 */
export async function fetchClientFollowUps(clientId: number, userId: string, skipBackgroundFetch = false) {
  if (!clientId || !userId) {
    console.log('Missing client ID or user ID for follow-up fetch');
    return [];
  }
  
  // Prevent stack overflow with recursion depth check
  if (fetchDepth > MAX_FETCH_DEPTH) {
    console.error('Maximum fetch depth exceeded, returning cached data or empty array');
    return getCachedData(clientId) || [];
  }
  
  fetchDepth++;
  
  try {
    const requestKey = `${clientId}:${userId}`;
    
    // First check if we have cached data - use it immediately for fast rendering
    const cachedData = getCachedData(clientId);
    if (cachedData) {
      // If we've recently navigated, prefer the cache with high priority
      if (isRecentlyNavigated()) {
        console.log('Using high-priority cached data due to recent navigation');
        if (!skipBackgroundFetch) scheduleBackgroundRefresh(clientId, userId);
        return cachedData;
      }
      
      // Return cached data immediately but continue fetching in background
      // unless there's already a request in progress or background fetch flag is true
      if (!pendingRequests[requestKey] && !skipBackgroundFetch && !isBackgroundFetchInProgress) {
        scheduleBackgroundRefresh(clientId, userId);
      }
      
      return cachedData;
    }
    
    // If there's already a pending request for this client, return that Promise
    if (pendingRequests[requestKey]) {
      console.log('Reusing in-flight request for client:', clientId);
      return pendingRequests[requestKey];
    }
    
    console.log('Fetching follow-ups for client:', clientId);
    
    // Create a proper Promise that correctly handles resolution and rejection
    const promise = new Promise<any>((resolve, reject) => {
      // Use .then() to handle both success and error cases from the Supabase query
      // rather than using .catch() which TypeScript doesn't recognize on this object
      supabase
        .from('follow_ups')
        .select('*')
        .eq('user_id', userId)
        .eq('client_id', clientId)
        .order('created_at', { ascending: false })
        .then(({ data, error }) => {
          // Clear this request from pending when done
          delete pendingRequests[requestKey];
          
          if (error) {
            console.error('Error fetching follow-ups:', error);
            reject(error);
            return;
          }
          
          // Update cache with fresh data
          if (data) {
            setCachedData(clientId, data);
          }
          
          resolve(data || []);
        })
        .then(undefined, (error) => {
          // This is a replacement for .catch() - handling any rejected promise
          // Clean up on error too
          delete pendingRequests[requestKey];
          reject(error);
        });
    });
    
    // Store the promise so identical requests can reuse it
    pendingRequests[requestKey] = promise;
    
    return promise;
  } finally {
    fetchDepth--;
  }
}

// Helper function to schedule a background refresh with proper safeguards
function scheduleBackgroundRefresh(clientId: number, userId: string) {
  console.log('Scheduling background refresh for client:', clientId);
  
  // Don't schedule more background fetches if one is already in progress
  if (isBackgroundFetchInProgress) return;
  
  // Set background fetch flag to prevent recursion
  isBackgroundFetchInProgress = true;
  
  // We need to use a simple approach here rather than recursive calls
  setTimeout(async () => {
    try {
      // Execute a clean fetch without going through the same function
      const { data, error } = await supabase
        .from('follow_ups')
        .select('*')
        .eq('user_id', userId)
        .eq('client_id', clientId)
        .order('created_at', { ascending: false });
        
      if (error) {
        console.error('Background fetch error:', error);
      } else if (data) {
        // Update cache with fresh data
        setCachedData(clientId, data);
        
        // Trigger event for UI refresh
        window.dispatchEvent(new CustomEvent('background-fetch-complete', { 
          detail: { clientId, dataLength: data.length } 
        }));
      }
    } catch (err) {
      console.error('Error in background fetch:', err);
    } finally {
      // Reset the background fetch flag when complete
      isBackgroundFetchInProgress = false;
    }
  }, 500);
}
