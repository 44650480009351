
import { Client } from "@/types/client";
import { Stage } from "@/types/stage";
import { Copy, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog";

interface StageClientActionsProps {
  client: Client;
  stages: Stage[];
  currentStage: string;
  onDuplicateClient: (client: Client, targetStage: string) => void;
  onDeleteClientFromStage: (clientId: number, stage: string) => void;
}

const StageClientActions = ({
  client,
  stages,
  currentStage,
  onDuplicateClient,
  onDeleteClientFromStage
}: StageClientActionsProps) => {
  const filteredStages = stages.filter(stage => {
    if (stage.name.startsWith('Task_')) {
      return false;
    }
    
    if (["Completed", "Delivered", "Done"].includes(stage.name)) {
      return false;
    }
    
    if (stage.name === currentStage) {
      return false;
    }
    
    return true;
  });

  return (
    <div className="flex gap-0.5 opacity-0 group-hover:opacity-100 transition-opacity">
      {filteredStages.length > 0 && (
        <Select
          onValueChange={(value) => {
            const targetStage = stages.find(s => s.id.toString() === value);
            if (targetStage) {
              onDuplicateClient(client, targetStage.name);
            }
          }}
        >
          <SelectTrigger className="h-5 w-5 p-0 border-0 bg-transparent">
            <Copy className="h-2.5 w-2.5 text-gray-500" />
          </SelectTrigger>
          <SelectContent className="min-w-[120px]">
            <div className="text-xs font-medium px-2 py-1 text-gray-500">Clone to:</div>
            {filteredStages.map((stage) => (
              <SelectItem 
                key={stage.id} 
                value={stage.id.toString()}
                className="text-xs py-1"
              >
                {stage.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button variant="ghost" size="sm" className="h-5 w-5 p-0">
            <Trash2 className="h-2.5 w-2.5 text-gray-500" />
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Client from Stage</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to remove {client.company} from this stage?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={() => onDeleteClientFromStage(client.id, client.current_stage)}>
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default StageClientActions;
