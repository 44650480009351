
import { useState, useRef, useEffect } from "react";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import React from "react";

interface FollowUpEditableCellProps {
  value: string;
  onSave: (value: string) => void;
  type?: "text" | "textarea";
}

const FollowUpEditableCell = ({ value, onSave, type = "text" }: FollowUpEditableCellProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    setEditValue(value);
  }, [value]);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    if (editValue !== value) {
      onSave(editValue);
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      if (type === "text") {
        e.preventDefault();
        onSave(editValue);
        setIsEditing(false);
      }
    } else if (e.key === "Escape") {
      setEditValue(value);
      setIsEditing(false);
    }
  };

  if (isEditing) {
    if (type === "textarea") {
      return (
        <Textarea
          ref={inputRef as React.RefObject<HTMLTextAreaElement>}
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className="text-xs leading-tight p-1 min-h-[50px] text-left"
          autoFocus
        />
      );
    }
    
    return (
      <Input
        ref={inputRef as React.RefObject<HTMLInputElement>}
        type="text"
        value={editValue}
        onChange={(e) => setEditValue(e.target.value)}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        className="text-xs h-6 p-1 text-left"
        autoFocus
      />
    );
  }

  return (
    <div 
      onDoubleClick={handleDoubleClick}
      className="cursor-pointer text-left whitespace-pre-wrap text-xs"
    >
      {value || <span className="italic text-gray-400 text-xs">Click to edit</span>}
    </div>
  );
};

// Add custom equality check to prevent unnecessary re-renders
export default React.memo(FollowUpEditableCell, (prevProps, nextProps) => {
  // Only re-render if the value actually changed
  return prevProps.value === nextProps.value && 
         prevProps.type === nextProps.type;
});
