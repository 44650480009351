
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Loader2 } from "lucide-react";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    let mounted = true;

    const checkAuth = async () => {
      try {
        setIsLoading(true);
        const { data: { session } } = await supabase.auth.getSession();

        if (!mounted) return;

        if (!session) {
          console.log("No session found, redirecting to login");
          toast({
            title: "Authentication required",
            description: "Please login to access this page",
            variant: "destructive",
          });
          navigate("/login", { replace: true });
          return;
        }

        // Admin user has full access
        if (session.user.email === 'jstaack@staackllc.com') {
          console.log("Admin user detected, allowing access");
          setIsAuthenticated(true);
          setIsLoading(false);
          return;
        }

        // Check profile status
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('subscription_status, payment_completed')
          .eq('id', session.user.id)
          .single();

        if (!mounted) return;

        if (profileError) {
          console.error("Error checking profile:", profileError);
          navigate("/plans");
          return;
        }

        // If no active subscription, redirect to plans
        if (!profile || profile.subscription_status !== 'active') {
          console.log("No active subscription, redirecting to plans");
          toast({
            title: "Subscription Required",
            description: "Please subscribe to access this feature",
            variant: "destructive",
          });
          navigate("/plans");
          return;
        }

        console.log("Access granted: Active subscription verified");
        setIsAuthenticated(true);
        setIsLoading(false);
      } catch (error) {
        console.error("Error in checkAuth:", error);
        setIsLoading(false);
      }
    };

    checkAuth();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_OUT' || !session) {
        navigate("/login", { replace: true });
      }
    });

    return () => {
      mounted = false;
      subscription.unsubscribe();
    };
  }, [navigate, toast]);

  if (isLoading) {
    return (
      <div className="h-screen w-full flex items-center justify-center bg-background">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  return isAuthenticated ? <>{children}</> : null;
};

export default ProtectedRoute;
