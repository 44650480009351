
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

interface UseNavigationStateProps {
  fetchClientById: (clientId: number) => Promise<void>;
  setActivePanel: (panel: string) => void;
  setSelectedClient: (client: any) => void;
  setSelectedIndustry: (industry: string) => void;
}

export const useNavigationState = ({
  fetchClientById,
  setActivePanel,
  setSelectedClient,
  setSelectedIndustry
}: UseNavigationStateProps) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const handleLocation = async () => {
      try {
        // Check for client ID in URL parameters
        const clientId = searchParams.get('clientId');
        const followUp = searchParams.get('followUp');
        
        // Check for data in location state (from programmatic navigation)
        const state = location.state as any;
        
        if (state?.selectedClient?.id) {
          console.log("Navigation: Found client in location state:", state.selectedClient.id);
          
          if (state.activePanel) {
            console.log("Navigation: Setting active panel from state:", state.activePanel);
            setActivePanel(state.activePanel);
          }
          
          // Fetch client data by ID
          await fetchClientById(state.selectedClient.id);
          
        } else if (clientId && followUp === 'true') {
          console.log("Navigation: Found client in URL params:", clientId);
          // Fetch client by ID from URL parameters
          const clientIdNum = parseInt(clientId, 10);
          if (!isNaN(clientIdNum)) {
            await fetchClientById(clientIdNum);
          }
        } else {
          // Reset state for fresh dashboard view
          console.log("Navigation: No client specified, resetting state");
          setSelectedClient(null);
          setSelectedIndustry("");
          setActivePanel("");
        }
      } catch (error) {
        console.error("Error in navigation handling:", error);
      }
    };

    handleLocation();
  }, [location, searchParams, fetchClientById, setActivePanel, setSelectedClient, setSelectedIndustry]);
};
