
import { useCallback } from 'react';
import { useToast } from '@/hooks/use-toast';
import { followUpService } from '@/services/followUps';

export const useFollowUpDelete = (onSuccess: () => void) => {
  const { toast } = useToast();

  const handleDelete = useCallback(async (id: string): Promise<boolean> => {
    try {
      console.log('Deleting follow-up with ID:', id);
      await followUpService.deleteFollowUp(id);
      
      toast({
        title: "Success",
        description: "Follow-up deleted successfully",
      });
      
      // Ensure we call the success callback to refresh the list
      if (onSuccess) {
        onSuccess();
      }
      
      // Return true to indicate successful deletion
      return true;
    } catch (error) {
      console.error('Error deleting follow-up:', error);
      toast({
        title: "Error",
        description: "Failed to delete follow-up",
        variant: "destructive",
      });
      
      // Rethrow the error to let calling components handle it
      throw error;
    }
  }, [toast, onSuccess]);

  return { handleDelete };
};
