
/**
 * Utility functions for working with follow-up statuses
 */

/**
 * Get the appropriate Tailwind CSS class name for a given status
 */
export const getStatusClassName = (status: string): string => {
  switch (status) {
    case 'completed':
      return 'bg-green-100 text-green-800 border-green-300';
    case 'pending':
      return 'bg-blue-100 text-blue-800 border-blue-300';
    default:
      return 'bg-gray-100 text-gray-800 border-gray-300';
  }
};
