
import React from "react";
import { Card } from "@/components/ui/card";
import FollowUpHeader from "@/components/follow-up/FollowUpHeader";
import FollowUpContent from "@/components/follow-up/FollowUpContent";
import { Client } from "@/types/client";
import { FollowUp } from "@/types/followUp";
import { SortConfig } from "@/types/followUp";

interface FollowUpPanelContentProps {
  isActive: boolean;
  currentClient: Client | null;
  followUps: FollowUp[];
  sortConfig: SortConfig | null;
  showForm: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  setShowForm: (show: boolean) => void;
  handleSort: (key: keyof FollowUp) => void;
  handleSave: (followUpData: Omit<FollowUp, 'id'>) => Promise<void>;
  handleDelete: (id: string) => Promise<boolean>;
  handleUpdatePriority: (id: string, priority: string) => Promise<void>;
  handleUpdateFollowUp: (id: string, field: keyof FollowUp, value: string) => Promise<void>;
}

const FollowUpPanelContent: React.FC<FollowUpPanelContentProps> = ({
  isActive,
  currentClient,
  followUps,
  sortConfig,
  showForm,
  isLoading,
  isSubmitting,
  setShowForm,
  handleSort,
  handleSave,
  handleDelete,
  handleUpdatePriority,
  handleUpdateFollowUp
}) => {
  return (
    <Card className={`shadow-md ${isActive ? 'follow-up-active' : ''}`} id="follow-up-panel">
      <FollowUpHeader
        selectedClient={currentClient}
        showForm={showForm}
        onAddNew={() => setShowForm(true)}
        onCancel={() => setShowForm(false)}
      />
      <FollowUpContent
        selectedClient={currentClient}
        showForm={showForm}
        followUps={followUps}
        sortConfig={sortConfig}
        onSave={handleSave}
        onCancel={() => setShowForm(false)}
        onSort={handleSort}
        onDelete={handleDelete}
        onUpdatePriority={handleUpdatePriority}
        onUpdateFollowUp={handleUpdateFollowUp}
        isLoading={isLoading}
        isSubmitting={isSubmitting}
      />
    </Card>
  );
};

export default React.memo(FollowUpPanelContent);
