
import { useCallback } from 'react';
import { FollowUp } from '@/types/followUp';
import { useToast } from '@/hooks/use-toast';
import { followUpStatusService } from '@/services/followUps/status';

export const useFollowUpPriorityUpdate = (onSuccess: () => void) => {
  const { toast } = useToast();

  const handleUpdatePriority = useCallback(async (id: string, priority: string): Promise<void> => {
    try {
      console.log('useFollowUpPriorityUpdate: Updating priority for follow-up', id, 'to', priority);
      
      // Make sure empty value is converted to "none"
      const sanitizedPriority = priority || "none";
      
      await followUpStatusService.updatePriority(id, sanitizedPriority as FollowUp['priority']);
      
      toast({
        title: "Success",
        description: "Priority updated successfully",
      });
      
      onSuccess();
    } catch (error) {
      console.error('Error updating follow-up priority:', error);
      toast({
        title: "Error",
        description: "Failed to update priority",
        variant: "destructive",
      });
      throw error; // Re-throw to allow component-level error handling
    }
  }, [toast, onSuccess]);

  return { handleUpdatePriority };
};
