
import { Client } from "@/types/client";
import { Stage } from "@/types/stage";
import ColorPickerButton from "./ColorPickerButton";
import StageClientActions from "./StageClientActions";

interface StageClientItemProps {
  client: Client;
  stages: Stage[];
  currentStage: string;
  onDuplicateClient: (client: Client, targetStage: string) => void;
  onDeleteClientFromStage: (clientId: number, stage: string) => void;
  onColorChange: (clientId: number, color: string) => void;
  onClientClick: (clientId: number) => void;
}

const StageClientItem = ({
  client,
  stages,
  currentStage,
  onDuplicateClient,
  onDeleteClientFromStage,
  onColorChange,
  onClientClick
}: StageClientItemProps) => {
  
  const handleColorChange = async (color: string): Promise<void> => {
    console.log(`StageClientItem: Color change requested for client ${client.id} in stage ${currentStage} to ${color}`);
    
    try {
      await onColorChange(client.id, color);
      console.log(`StageClientItem: Color change successfully passed up for client ${client.id}`);
    } catch (error) {
      console.error("StageClientItem: Error in color change handler:", error);
      throw error;
    }
  };

  return (
    <li 
      className="flex items-center text-[11px] py-0 px-0 hover:bg-gray-50 transition-colors group leading-none"
      data-client-id={client.id}
      data-client-stage={currentStage}
    >
      <div className="flex items-center gap-0.5 flex-grow">
        <ColorPickerButton 
          currentColor={client.bullet_color || 'bg-gray-400'} 
          onColorSelect={handleColorChange} 
          isCompact={true}
        />
        <button
          onClick={() => onClientClick(client.id)}
          className="truncate flex-grow text-left text-gray-600 hover:text-gray-900"
          aria-label={`Open follow-up panel for ${client.company}`}
        >
          {client.company}
        </button>
      </div>
      <StageClientActions
        client={client}
        stages={stages}
        currentStage={currentStage}
        onDuplicateClient={onDuplicateClient}
        onDeleteClientFromStage={onDeleteClientFromStage}
      />
    </li>
  );
};

export default StageClientItem;
