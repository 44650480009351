
import { useStatusUpdate } from "./hooks/useStatusUpdate";
import { FollowUp } from "@/types/followUp";
import { cn } from "@/lib/utils";
import StatusSelect from "./StatusSelect";
import StatusError from "./StatusError";
import React, { memo, useState, useEffect } from "react";

interface FollowUpStatusCellProps {
  followUp: FollowUp;
  onChange?: (id: string, status: FollowUp['status']) => Promise<void>;
}

const FollowUpStatusCell = ({ followUp, onChange }: FollowUpStatusCellProps) => {
  const { currentStatus, isUpdating, error, handleChange } = useStatusUpdate({ 
    followUp, 
    onChange 
  });
  
  // Use local state to avoid UI flickering
  const [localStatus, setLocalStatus] = useState(currentStatus);
  
  // Only update local state when currentStatus changes and not during updating
  useEffect(() => {
    if (!isUpdating) {
      setLocalStatus(currentStatus);
    }
  }, [currentStatus, isUpdating]);

  return (
    <div className={cn(
      "relative",
      isUpdating && "opacity-70 pointer-events-none",
      error && "border-red-300"
    )}>
      <StatusSelect 
        currentStatus={localStatus}
        isDisabled={isUpdating}
        onValueChange={handleChange}
      />
      
      <StatusError error={error} />
    </div>
  );
};

// Improved comparison function with more specific checks
const arePropsEqual = (prevProps: FollowUpStatusCellProps, nextProps: FollowUpStatusCellProps) => {
  // Only re-render if the id or status has changed
  return (
    prevProps.followUp.id === nextProps.followUp.id &&
    prevProps.followUp.status === nextProps.followUp.status
  );
};

export default memo(FollowUpStatusCell, arePropsEqual);
