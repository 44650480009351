import React from "react";
import { FollowUp } from "@/types/followUp";
import { useIsMobile } from "@/hooks/use-mobile";
import TableHeader from "./table/TableHeader";
import TableBody from "./table/TableBody";
import EmptyState from "./table/EmptyState";
import { useFollowUpTable } from "./table/useFollowUpTable";

interface FollowUpTableProps {
  followUps: FollowUp[];
  sortConfig: { key: keyof FollowUp; direction: 'asc' | 'desc' } | null;
  handleSort: (key: keyof FollowUp) => void;
  handleDelete: (id: string) => Promise<boolean>;
  onUpdateFollowUp?: (id: string, field: keyof FollowUp, value: string) => Promise<void>;
}

const FollowUpTable = ({ 
  followUps, 
  sortConfig, 
  handleSort, 
  handleDelete,
  onUpdateFollowUp,
}: FollowUpTableProps) => {
  const isMobile = useIsMobile();
  const { handleCellUpdate, handleDelete: deleteFollowUp, deletingRows } = useFollowUpTable(onUpdateFollowUp, handleDelete);

  console.log("FollowUpTable rendering with", followUps.length, "follow-ups");

  return (
    <div className="overflow-x-auto -mx-2">
      <div className="md:hidden text-xs text-gray-500 italic px-2 py-1 text-left">
        Double-click on a cell to edit
      </div>
      <div className="md:px-0 px-2">
        <table className="w-full text-xs">
          <thead>
            <tr className="border-b">
              <th className="px-1 py-2 text-left w-24">Date</th>
              <th className="px-1 py-2 text-left w-24">Status</th>
              <th className="px-1 py-2 text-left">Action & Description</th>
              <th className="hidden md:table-cell px-1 py-2 text-left">Next Step</th>
              <th className="px-1 py-2 text-right w-10"></th>
            </tr>
          </thead>
          {followUps.length === 0 ? (
            <tbody>
              <EmptyState />
            </tbody>
          ) : (
            <TableBody
              followUps={followUps}
              isMobile={isMobile}
              onUpdateFollowUp={handleCellUpdate}
              onDelete={deleteFollowUp}
              deletingRows={deletingRows}
            />
          )}
        </table>
      </div>
    </div>
  );
};

// Prevent re-rendering unless necessary
const arePropsEqual = (prevProps: FollowUpTableProps, nextProps: FollowUpTableProps) => {
  // Check if the follow-ups array has changed in a meaningful way
  if (prevProps.followUps.length !== nextProps.followUps.length) return false;
  
  // Check if sort config has changed
  if (prevProps.sortConfig?.key !== nextProps.sortConfig?.key || 
      prevProps.sortConfig?.direction !== nextProps.sortConfig?.direction) {
    return false;
  }
  
  // Compare each follow-up's critical properties
  for (let i = 0; i < nextProps.followUps.length; i++) {
    const prevItem = prevProps.followUps[i];
    const nextItem = nextProps.followUps[i];
    
    if (
      prevItem.id !== nextItem.id ||
      prevItem.status !== nextItem.status ||
      prevItem.priority !== nextItem.priority ||
      prevItem.date !== nextItem.date
    ) {
      return false;
    }
  }
  
  return true;
};

export default React.memo(FollowUpTable, arePropsEqual);
