
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { LayoutDashboard, Building2, Hammer, FileText, Settings, Workflow, ChevronDown, Menu, CalendarDays } from "lucide-react";
import Logo from "@/components/Logo";
import { supabase } from "@/integrations/supabase/client";
import MobileMenu from "./header/MobileMenu";
import { UserMenu } from "./header/UserMenu";
import { Button } from "./ui/button";
import { useToast } from "@/hooks/use-toast";
import { useIsMobile } from "@/hooks/use-mobile";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

const Header = () => {
  const [username, setUsername] = useState<string>("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { toast } = useToast();
  const isMobile = useIsMobile();

  useEffect(() => {
    const getProfile = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session) {
          setIsLoggedIn(true);
          const { data: profile, error } = await supabase
            .from('profiles')
            .select('username, is_admin')
            .eq('id', session.user.id)
            .maybeSingle();
          
          if (error) {
            console.error('Error fetching profile:', error);
            toast({
              title: "Error",
              description: "Failed to load user profile",
              variant: "destructive",
            });
            return;
          }
          
          if (profile) {
            setUsername(profile.username);
            setIsAdmin(profile.is_admin || false);
          } else {
            // Set default values if no profile exists
            setUsername(session.user.email?.split('@')[0] || 'User');
            setIsAdmin(false);
            console.log('No profile found for user:', session.user.id);
          }
        }
      } catch (error) {
        console.error('Error in getProfile:', error);
        toast({
          title: "Error",
          description: "Failed to load user session",
          variant: "destructive",
        });
      }
    };

    getProfile();
  }, [toast]);

  return (
    <nav className="bg-white/80 backdrop-blur-md shadow-sm px-4 py-3 sticky top-0 z-50">
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <Logo />
        </div>
        
        <div className="flex items-center space-x-4">
          {isLoggedIn ? (
            <>
              <div className="hidden md:flex items-center space-x-2">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="flex items-center gap-1">
                      <LayoutDashboard className="h-5 w-5 mr-1" />
                      <span>Dashboard</span>
                      <ChevronDown className="h-4 w-4 ml-1" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-48 bg-white">
                    <DropdownMenuItem asChild>
                      <Link to="/index" className="flex items-center gap-2 cursor-pointer">
                        <LayoutDashboard className="h-4 w-4" />
                        <span>Dashboard</span>
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link to="/stages" className="flex items-center gap-2 cursor-pointer">
                        <Workflow className="h-4 w-4" />
                        <span>Pipeline</span>
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link to="/weeks" className="flex items-center gap-2 cursor-pointer">
                        <CalendarDays className="h-4 w-4" />
                        <span>Weekly Log</span>
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link to="/trade-shows" className="flex items-center gap-2 cursor-pointer">
                        <Building2 className="h-4 w-4" />
                        <span>Trade Shows</span>
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link to="/prospecting" className="flex items-center gap-2 cursor-pointer">
                        <Hammer className="h-4 w-4" />
                        <span>Prospecting</span>
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link to="/reports" className="flex items-center gap-2 cursor-pointer">
                        <FileText className="h-4 w-4" />
                        <span>Reports</span>
                      </Link>
                    </DropdownMenuItem>
                    {isAdmin && (
                      <DropdownMenuItem asChild>
                        <Link to="/admin" className="flex items-center gap-2 cursor-pointer">
                          <Settings className="h-4 w-4" />
                          <span>Admin</span>
                        </Link>
                      </DropdownMenuItem>
                    )}
                  </DropdownMenuContent>
                </DropdownMenu>
                <UserMenu username={username} />
              </div>
              <MobileMenu isLoggedIn={isLoggedIn} />
            </>
          ) : (
            <>
              <div className="hidden md:flex items-center space-x-4">
                <Link to="/login">
                  <Button variant="ghost">Login</Button>
                </Link>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="flex items-center gap-1">
                      <Menu className="h-5 w-5" />
                      <ChevronDown className="h-4 w-4 ml-1" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-48 bg-white">
                    <DropdownMenuItem asChild>
                      <Link to="/about" className="flex items-center gap-2 cursor-pointer">
                        <span>About</span>
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link to="/contact" className="flex items-center gap-2 cursor-pointer">
                        <span>Contact</span>
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link to="/register" className="flex items-center gap-2 cursor-pointer">
                        <span>Register</span>
                      </Link>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              <MobileMenu isLoggedIn={isLoggedIn} />
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;
