
import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Loader2 } from "lucide-react";
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";

interface ColorPickerProps {
  currentColor: string;
  onColorSelect: (color: string) => void;
  directSelect?: boolean;
}

const ColorPicker = ({ currentColor, onColorSelect, directSelect = false }: ColorPickerProps) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localColor, setLocalColor] = useState(currentColor);
  
  // Updated consistent color list with more options and better consistency
  const colors = [
    { name: "Yellow", class: "bg-yellow-500" },
    { name: "Green", class: "bg-green-500" },
    { name: "Red", class: "bg-red-500" },
    { name: "Blue", class: "bg-blue-500" },
    { name: "Gray", class: "bg-gray-500" },
    { name: "Orange", class: "bg-orange-500" },
    { name: "Pink", class: "bg-pink-300" },
    { name: "Purple", class: "bg-purple-500" },
    { name: "Teal", class: "bg-teal-500" },
    { name: "Indigo", class: "bg-indigo-500" },
  ];

  // Update local color when currentColor prop changes
  useEffect(() => {
    if (currentColor !== localColor) {
      setLocalColor(currentColor || 'bg-gray-400');
    }
  }, [currentColor, localColor]);

  const handleColorSelect = async (color: string, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    setIsLoading(true);
    setLocalColor(color); // Update local state immediately for better UX
    
    try {
      // Call the onColorSelect callback and await its completion
      await onColorSelect(color);
      if (directSelect) {
        // For dropdown version, explicitly close after selection
        const dropdown = document.querySelector('[data-state="open"]');
        if (dropdown) {
          dropdown.dispatchEvent(new Event('close', { bubbles: true }));
        }
      } else {
        // Close popover
        setOpen(false);
      }
      console.log("Color selected:", color);
    } catch (error) {
      console.error("Error selecting color:", error);
      // Revert to previous color on error only if the onColorSelect fails
      // but we've already updated the local state above for responsiveness
    } finally {
      setIsLoading(false);
    }
  };

  // When directSelect is true, show a dropdown menu for the pipeline view
  if (directSelect) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="h-4 w-4 p-0 hover:bg-transparent"
            type="button"
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader2 className="h-2 w-2 animate-spin" />
            ) : (
              <div className={`h-2 w-2 rounded-full ${localColor || 'bg-gray-400'}`} />
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="p-1 min-w-[140px] bg-white shadow-md z-[100]"
          onClick={(e) => e.stopPropagation()}
          align="start"
        >
          <div className="flex flex-wrap gap-1 max-w-[140px]">
            {colors.map((color) => (
              <Button
                key={color.name}
                variant="ghost"
                className="h-6 w-6 p-0 hover:bg-transparent"
                onClick={(e) => handleColorSelect(color.class, e)}
                title={color.name}
                type="button"
              >
                <div className={`h-3 w-3 rounded-full ${color.class}`} />
              </Button>
            ))}
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          className="h-4 w-4 p-0 hover:bg-transparent"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          type="button"
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader2 className="h-2 w-2 animate-spin" />
          ) : (
            <div className={`h-2 w-2 rounded-full ${localColor || 'bg-gray-400'}`} />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent 
        className="w-fit p-2 bg-white shadow-md z-[100]"
        onClick={(e) => e.stopPropagation()}
        sideOffset={5}
        align="start"
      >
        <div className="flex flex-wrap gap-2 max-w-[180px]">
          {colors.map((color) => (
            <Button
              key={color.name}
              variant="ghost"
              className="h-6 w-6 p-0 hover:bg-transparent"
              onClick={(e) => handleColorSelect(color.class, e)}
              title={color.name}
              type="button"
            >
              <div className={`h-3 w-3 rounded-full ${color.class}`} />
            </Button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ColorPicker;
