
import { useCallback } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Client } from "@/types/client";

export const useClientSelection = (
  setSelectedClient: (client: Client | null) => void,
  setSelectedIndustry: (industry: string) => void,
  setActivePanel: (panel: string) => void
) => {
  const handleClientSelect = useCallback((client: Client | null) => {
    console.log("Dashboard: Client selected manually:", client?.company);
    setSelectedClient(client);
    // Ensure follow-up panel is visible when client is selected manually
    if (client) {
      setActivePanel('follow-up');
      
      // Scroll to follow-up panel
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('scroll-to-followup', {
          detail: { 
            clientId: client.id,
            source: 'handleClientSelect'
          }
        }));
      }, 100);
    }
  }, [setSelectedClient, setActivePanel]);

  const handleClientSelectFromRemind = useCallback(async (clientId: number) => {
    try {
      if (!clientId) return;

      console.log("Dashboard: Client selected from remind with ID:", clientId);
      const { data: client, error } = await supabase
        .from('clients')
        .select('*')
        .eq('id', clientId)
        .single();

      if (error) {
        console.error('Error fetching client:', error);
        return;
      }

      if (client) {
        console.log("Dashboard: Setting client from remind:", client);
        setSelectedClient(client);
        setSelectedIndustry(client.industry || "");
        // Ensure follow-up panel is active
        setActivePanel('follow-up');
        
        // Scroll to follow-up panel
        setTimeout(() => {
          window.dispatchEvent(new CustomEvent('scroll-to-followup', {
            detail: { 
              clientId: client.id,
              source: 'handleClientSelectFromRemind'
            }
          }));
        }, 100);
      }
    } catch (error) {
      console.error('Error fetching client:', error);
    }
  }, [setSelectedClient, setSelectedIndustry, setActivePanel]);

  return {
    handleClientSelect,
    handleClientSelectFromRemind
  };
};
