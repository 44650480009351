
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { CalendarEvent } from "@/types/calendar";

export const useCalendarQueries = () => {
  const { toast } = useToast();

  const loadEvents = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('No user found');
        return null;
      }

      const { data: calendarEvents, error } = await supabase
        .from('calendar_events')
        .select('*')
        .eq('user_id', user.id);

      if (error) {
        console.error('Error loading calendar events:', error);
        toast({
          title: "Error",
          description: "Failed to load calendar events",
          variant: "destructive",
        });
        return null;
      }

      return calendarEvents?.map(event => ({
        week: event.week,
        day: event.day,
        text: event.text,
        status: event.status
      })) || [];
    } catch (error) {
      console.error('Error in loadEvents:', error);
      return null;
    }
  };

  return { loadEvents };
};
