
import { useState, useEffect } from "react";
import { Input } from "@/components/ui/input";
import { actionOptions } from "@/utils/followUpUtils";
import { supabase } from "@/integrations/supabase/client";

interface TodoInputProps {
  onAdd: (action: string, client: string, description: string) => void;
}

interface ClientOption {
  id: number;
  company: string;
}

const TodoInput = ({ onAdd }: TodoInputProps) => {
  const [action, setAction] = useState("");
  const [client, setClient] = useState("");
  const [description, setDescription] = useState("");
  const [actionSuggestions, setActionSuggestions] = useState<string[]>([]);
  const [clientSuggestions, setClientSuggestions] = useState<string[]>([]);
  const [actionOptionsList, setActionOptionsList] = useState<string[]>([]);
  const [clientOptions, setClientOptions] = useState<ClientOption[]>([]);
  const [showActionSuggestions, setShowActionSuggestions] = useState(false);
  const [showClientSuggestions, setShowClientSuggestions] = useState(false);

  // Fetch action types and clients on component mount
  useEffect(() => {
    fetchActionOptions();
    fetchClients();
  }, []);

  const fetchActionOptions = async () => {
    try {
      // Get action options from the follow-up utility
      setActionOptionsList(actionOptions);
    } catch (error) {
      console.error("Error fetching action types:", error);
    }
  };

  const fetchClients = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('clients')
        .select('id, company')
        .eq('user_id', user.id)
        .order('company');

      if (error) throw error;
      setClientOptions(data || []);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  // Filter actions based on input
  useEffect(() => {
    if (action && showActionSuggestions) {
      const filtered = actionOptionsList.filter(opt => 
        opt.toLowerCase().includes(action.toLowerCase())
      );
      setActionSuggestions(filtered.slice(0, 5)); // Limit to 5 suggestions
    } else {
      setActionSuggestions([]);
    }
  }, [action, actionOptionsList, showActionSuggestions]);

  // Filter clients based on input
  useEffect(() => {
    if (client && showClientSuggestions) {
      const filtered = clientOptions
        .filter(opt => opt.company.toLowerCase().includes(client.toLowerCase()))
        .map(opt => opt.company);
      setClientSuggestions(filtered.slice(0, 5)); // Limit to 5 suggestions
    } else {
      setClientSuggestions([]);
    }
  }, [client, clientOptions, showClientSuggestions]);

  const handleActionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAction(e.target.value);
    setShowActionSuggestions(true);
  };

  const handleClientChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClient(e.target.value);
    setShowClientSuggestions(true);
  };

  const handleActionSelect = (selectedAction: string) => {
    setAction(selectedAction);
    setShowActionSuggestions(false);
  };

  const handleClientSelect = (selectedClient: string) => {
    setClient(selectedClient);
    setShowClientSuggestions(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && action && client && description) {
      e.preventDefault();
      onAdd(action, client, description);
      setAction("");
      setClient("");
      setDescription("");
    }
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  return (
    <div className="flex flex-col space-y-1">
      <div className="flex gap-1">
        <div className="relative flex-1">
          <Input
            value={action}
            onChange={handleActionChange}
            onBlur={() => setTimeout(() => setShowActionSuggestions(false), 200)}
            onFocus={() => setShowActionSuggestions(true)}
            placeholder="Action"
            className="text-xs h-7"
          />
          {showActionSuggestions && actionSuggestions.length > 0 && (
            <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-md max-h-32 overflow-y-auto">
              {actionSuggestions.map((item, index) => (
                <div
                  key={`action-${index}`}
                  className="px-3 py-1 text-xs hover:bg-gray-100 cursor-pointer text-left"
                  onMouseDown={() => handleActionSelect(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="relative flex-1">
          <Input
            value={client}
            onChange={handleClientChange}
            onBlur={() => setTimeout(() => setShowClientSuggestions(false), 200)}
            onFocus={() => setShowClientSuggestions(true)}
            placeholder="Client"
            className="text-xs h-7"
          />
          {showClientSuggestions && clientSuggestions.length > 0 && (
            <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-md max-h-32 overflow-y-auto">
              {clientSuggestions.map((item, index) => (
                <div
                  key={`client-${index}`}
                  className="px-3 py-1 text-xs hover:bg-gray-100 cursor-pointer text-left"
                  onMouseDown={() => handleClientSelect(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <Input
        type="text"
        value={description}
        onChange={handleDescriptionChange}
        onKeyDown={handleKeyDown}
        placeholder="Description and press Enter"
        className="text-xs h-7"
      />
    </div>
  );
};

export default TodoInput;
