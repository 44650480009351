
import { useEffect, useRef, useCallback } from 'react';

export const useFollowUpEvents = (onSuccess: () => void) => {
  // Single timeout reference for better debouncing
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const lastRefreshTimeRef = useRef(0);
  const isProcessingRef = useRef(false);
  const processedEventsRef = useRef<Set<string>>(new Set());
  const batchedUpdatesRef = useRef<Map<string, number>>(new Map());
  
  // Complete redesign of the refresh function to eliminate blinking
  const triggerRefresh = useCallback((delay = 3000) => {
    // Skip if already processing a refresh
    if (isProcessingRef.current) {
      return;
    }
    
    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    
    const now = Date.now();
    const timeSinceLastRefresh = now - lastRefreshTimeRef.current;
    
    // Much stricter throttling - enforce a minimum of 5 seconds between refreshes
    if (timeSinceLastRefresh < 5000) {
      console.log('Heavily throttling refresh, too soon since last refresh');
      delay = Math.max(delay, 5000 - timeSinceLastRefresh);
    }
    
    // Set processing flag
    isProcessingRef.current = true;
    
    timeoutRef.current = setTimeout(() => {
      console.log('Executing batched refresh after delay');
      onSuccess();
      lastRefreshTimeRef.current = Date.now();
      
      // Release processing lock after a longer delay
      setTimeout(() => {
        isProcessingRef.current = false;
      }, 2000);
      
      // Clean up old processed events
      const cutoffTime = Date.now() - 20000; // Extended from 10s to 20s
      processedEventsRef.current.forEach((eventKey) => {
        const [timestamp] = eventKey.split('-');
        if (parseInt(timestamp, 10) < cutoffTime) {
          processedEventsRef.current.delete(eventKey);
        }
      });
      
      // Clear batched updates
      batchedUpdatesRef.current.clear();
    }, delay);
  }, [onSuccess]);

  // Completely redesigned event handler to eliminate duplicate processing
  const handleEvent = useCallback((event: CustomEvent) => {
    const detail = event.detail || {};
    const source = detail.source || 'unknown';
    const id = detail.id || '';
    const timestamp = Date.now();
    
    // Create a unique event key by type, ID, source, and status
    const eventKey = `${timestamp}-${event.type}-${id}-${source}-${detail.status || ''}`;
    
    // Enhanced deduplication logic
    // Get base event key without timestamp for comparison
    const baseEventKey = `${event.type}-${id}-${source}-${detail.status || ''}`;
    
    // Check if we've processed this exact event type recently (last 10 seconds)
    if (Array.from(processedEventsRef.current).some(key => key.includes(baseEventKey) && 
        timestamp - parseInt(key.split('-')[0], 10) < 10000)) {
      console.log('Ignoring duplicate or similar event:', baseEventKey);
      return;
    }
    
    // Record this event with timestamp to prevent duplicates
    processedEventsRef.current.add(eventKey);
    
    // Enhanced batching - batch events by type + id
    const batchKey = `${event.type}-${id}`;
    const existingBatch = batchedUpdatesRef.current.get(batchKey);
    
    if (existingBatch && timestamp - existingBatch < 5000) {
      // If we've seen this event recently, just update its timestamp
      console.log('Updating existing batch for:', batchKey);
      batchedUpdatesRef.current.set(batchKey, timestamp);
      return;
    }
    
    // New batch
    console.log('Creating new batch for event:', batchKey);
    batchedUpdatesRef.current.set(batchKey, timestamp);
    
    // Use a very consistent, much longer delay for all events
    triggerRefresh(3000); // Increased from 1500 to 3000ms
  }, [triggerRefresh]);

  useEffect(() => {
    // All event names we want to listen for
    const eventNames = [
      'follow-up-status-updated',
      'follow-up-color-updated',
      'follow-up-action-updated',
      'follow-up-created-from-todo',
      'follow-up-updated-from-todo',
      'follow-up-deleted-from-todo'
    ];
    
    // Register all events with a single handler
    eventNames.forEach(name => window.addEventListener(name, handleEvent as EventListener));
    
    return () => {
      eventNames.forEach(name => window.removeEventListener(name, handleEvent as EventListener));
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [handleEvent]);
};
