
import { useRef, useEffect } from "react";
import { X } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

interface StageSearchProps {
  searchTerm: string;
  onSearchChange: (searchTerm: string) => void;
  onClose: () => void;
}

const StageSearch = ({ searchTerm, onSearchChange, onClose }: StageSearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    console.log(`StageSearch: Search term changed to: "${value}"`);
    onSearchChange(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  return (
    <div className="flex items-center w-full max-w-[180px]">
      <Input
        ref={inputRef}
        type="text"
        placeholder="Search clients..."
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        className="h-6 text-xs"
      />
      <Button 
        variant="ghost" 
        size="icon" 
        onClick={onClose}
        className="h-6 w-6 ml-0.5 p-0"
      >
        <X className="h-3 w-3" />
      </Button>
    </div>
  );
};

export default StageSearch;
