
import { supabase } from "@/integrations/supabase/client";

export const colorService = {
  /**
   * Updates the color of a follow-up with multiple fallback approaches
   */
  async updateColor(id: string, color: string): Promise<boolean> {
    console.log('Attempting to update follow-up color only:', { id, color });
    
    try {
      // First try the new bypass function that avoids constraint issues
      const { data: bypassData, error: bypassError } = await supabase
        .rpc('update_follow_up_color_bypass', {
          p_follow_up_id: id,
          p_color: color
        });

      if (!bypassError && bypassData === true) {
        console.log('Follow-up color updated successfully with bypass function');
        // Dispatch a custom event to notify components to refresh
        window.dispatchEvent(new CustomEvent('follow-up-color-updated', {
          detail: { id, color }
        }));
        return true;
      }
      
      console.log('Bypass function attempt result:', bypassData, bypassError);
      
      // Try other approaches
      return await colorService.tryAlternativeMethods(id, color);
    } catch (error: any) {
      console.error('Error in updateColor:', error.message, error.details);
      return false;
    }
  },

  /**
   * Try alternative methods for updating color
   */
  async tryAlternativeMethods(id: string, color: string): Promise<boolean> {
    // Second approach: Try using the original RPC function
    const { data: rpcData, error: rpcError } = await supabase
      .rpc('update_follow_up_color_safely', {
        p_follow_up_id: id,
        p_color: color
      });

    if (!rpcError && rpcData === true) {
      console.log('Follow-up color updated successfully with RPC method:', rpcData);
      // Dispatch a custom event to notify components to refresh
      window.dispatchEvent(new CustomEvent('follow-up-color-updated', {
        detail: { id, color }
      }));
      return true;
    }
    
    console.log('RPC method attempt result:', rpcData, rpcError);
    
    // Try the SQL-only and direct methods
    return await colorService.tryFinalMethods(id, color);
  },

  /**
   * Try final methods for updating color
   */
  async tryFinalMethods(id: string, color: string): Promise<boolean> {
    // Third approach: Try the SQL-only function
    const { error: sqlError } = await supabase
      .rpc('update_follow_up_color_only', {
        p_follow_up_id: id,
        p_color: color
      });
      
    if (!sqlError) {
      console.log('Follow-up color updated successfully with SQL-only method');
      // Dispatch a custom event to notify components to refresh
      window.dispatchEvent(new CustomEvent('follow-up-color-updated', {
        detail: { id, color }
      }));
      return true;
    }
    
    console.log('SQL-only method attempt result:', sqlError);
    
    // Last resort: Try a direct update with delay
    await new Promise(resolve => setTimeout(resolve, 500));
    
    // Use raw query to specifically target just the color field
    const { error: finalError } = await supabase
      .from('follow_ups')
      .update({ color })
      .eq('id', id);
      
    if (!finalError) {
      console.log('Follow-up color updated successfully with delayed direct method');
      // Dispatch a custom event to notify components to refresh
      window.dispatchEvent(new CustomEvent('follow-up-color-updated', {
        detail: { id, color }
      }));
      return true;
    }
    
    console.error('All color update methods failed:', finalError);
    return false;
  }
};
