
import { useState, useEffect } from "react";
import { CalendarEvent, EventStatus } from "@/types/calendar";
import { useCalendarQueries } from "./calendar/useCalendarQueries";
import { useCalendarMutations } from "./calendar/useCalendarMutations";
import { supabase } from "@/integrations/supabase/client";

export const useCalendarEvents = () => {
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [activeWeeks, setActiveWeeks] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const { loadEvents } = useCalendarQueries();
  const { addEvent: addEventMutation, deleteEvent: deleteEventMutation, updateEventStatus: updateEventStatusMutation } = useCalendarMutations();

  // This function calculates the ISO week number (1-53)
  const getCurrentWeek = () => {
    const now = new Date();
    const oneJan = new Date(now.getFullYear(), 0, 1);
    const dayNumber = Math.floor((now.getTime() - oneJan.getTime()) / (24 * 60 * 60 * 1000));
    return Math.ceil((dayNumber + oneJan.getDay() + 1) / 7);
  };

  const getWeekRange = (weekNumber: number, year: number) => {
    const janFirst = new Date(year, 0, 1);
    const daysOffset = (janFirst.getDay() + 6) % 7; // Adjust to Monday as first day
    const firstMonday = new Date(year, 0, 1 + (7 - daysOffset) % 7);
    
    // Calculate the first day of the given week
    const firstDayOfWeek = new Date(firstMonday);
    firstDayOfWeek.setDate(firstMonday.getDate() + (weekNumber - 1) * 7);
    
    // Calculate the last day of the week (Sunday)
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
    
    return {
      start: firstDayOfWeek,
      end: lastDayOfWeek
    };
  };

  const refreshEvents = async () => {
    const calendarEvents = await loadEvents();
    if (calendarEvents) {
      const typedEvents: CalendarEvent[] = calendarEvents.map(event => ({
        ...event,
        status: event.status as EventStatus
      }));
      setEvents(typedEvents);
      
      // Get unique weeks from events and sort them
      const weeksFromEvents = [...new Set(typedEvents.map(event => event.week))];
      const sortedWeeks = weeksFromEvents.sort((a, b) => a - b);
      
      // Ensure current week is included
      const currentWeek = getCurrentWeek();
      
      // Get all weeks from 1 to current week if they don't exist
      const allWeeks = Array.from(
        { length: currentWeek }, 
        (_, i) => i + 1
      );
      
      // Combine missing weeks with existing weeks and remove duplicates
      const combinedWeeks = [...new Set([...allWeeks, ...sortedWeeks])];
      
      // Sort all weeks in descending order to show latest week on top
      const finalWeeks = combinedWeeks.sort((a, b) => b - a);
      
      setActiveWeeks(finalWeeks);
    }
    setIsLoading(false);
  };

  // Function to get events for a specific week
  const getEventsForWeek = (weekNumber: number) => {
    return events.filter(event => event.week === weekNumber);
  };

  // Add an event
  const addEvent = async (week: number, day: string, text: string) => {
    try {
      const result = await addEventMutation(week, day, text);
      if (result) {
        await refreshEvents();
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error adding event:", error);
      return false;
    }
  };

  // Delete an event
  const deleteEvent = async (week: number, day: string, text: string) => {
    try {
      await deleteEventMutation(week, day, text);
      // Update local state to remove the event
      setEvents(currentEvents => 
        currentEvents.filter(event => 
          !(event.week === week && event.day === day && event.text === text)
        )
      );
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  // Update event status - this cycles through available statuses
  const updateEventStatus = async (week: number, day: string, text: string) => {
    try {
      const event = events.find(e => e.week === week && e.day === day && e.text === text);
      if (!event) return;

      // Define the status cycle order
      const statusCycle: EventStatus[] = ["pending", "in-process", "done", "gray", "blue", "orange"];
      const currentIndex = statusCycle.indexOf(event.status as EventStatus);
      const nextIndex = (currentIndex + 1) % statusCycle.length;
      const newStatus = statusCycle[nextIndex];

      // Update in database
      await updateEventStatusMutation(week, day, text, newStatus);
      
      // Update local state
      setEvents(currentEvents => 
        currentEvents.map(e => {
          if (e.week === week && e.day === day && e.text === text) {
            return { ...e, status: newStatus };
          }
          return e;
        })
      );
    } catch (error) {
      console.error("Error updating event status:", error);
    }
  };

  useEffect(() => {
    refreshEvents();

    // Subscribe to calendar events changes
    const channel = supabase
      .channel('calendar-events-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'calendar_events'
        },
        () => {
          refreshEvents();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  return {
    events,
    activeWeeks,
    isLoading,
    addEvent,
    deleteEvent,
    updateEventStatus,
    getCurrentWeek,
    getWeekRange,
    getEventsForWeek
  };
};
