
import { Button } from "@/components/ui/button";
import { CardHeader, CardTitle } from "@/components/ui/card";
import { Users2, Plus } from "lucide-react";

interface ClientsPanelHeaderProps {
  onAddClient: () => void;
}

const ClientsPanelHeader = ({ onAddClient }: ClientsPanelHeaderProps) => {
  return (
    <CardHeader className="bg-gray-50/80 border-b p-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Users2 className="w-5 h-5 text-gray-600" />
          <CardTitle className="text-lg font-medium text-gray-700">Clients</CardTitle>
        </div>
        <Button 
          onClick={onAddClient}
          className="bg-gray-100 text-gray-700 hover:bg-gray-200"
          size="icon"
        >
          <Plus className="h-4 w-4" />
        </Button>
      </div>
    </CardHeader>
  );
};

export default ClientsPanelHeader;
