
import { useState, useCallback, useEffect } from 'react';
import { FollowUp } from '@/types/followUp';
import { Client } from '@/types/client';
import { useFollowUpFetch } from './followUps/useFollowUpFetch';
import { useFollowUpMutations } from './followUps/useFollowUpMutations';
import { useFollowUpSort } from './followUps/useFollowUpSort';

export const useFollowUps = (selectedClient: Client | null) => {
  const [showForm, setShowForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editingFollowUp, setEditingFollowUp] = useState<FollowUp | null>(null);
  
  const clientId = selectedClient?.id;
  
  const { 
    isLoading, 
    followUps, 
    setFollowUps,
    fetchFollowUps,
    invalidateClientCache
  } = useFollowUpFetch(clientId);

  // Use the sorted followUps from useFollowUpSort
  const { sortConfig, handleSort, sortedFollowUps } = useFollowUpSort(followUps);

  const handleSuccess = useCallback(() => {
    setShowForm(false);
    setEditingFollowUp(null);
    setIsSubmitting(false);
    fetchFollowUps(true);
  }, [fetchFollowUps]);

  const { 
    handleSave, 
    handleDelete, 
    handleUpdatePriority,
    handleUpdateFollowUp
  } = useFollowUpMutations(clientId, handleSuccess);

  // Wrap the save handler to manage loading state
  const handleSaveWithState = async (followUpData: Omit<FollowUp, 'id'>) => {
    setIsSubmitting(true);
    try {
      await handleSave(followUpData);
    } catch (error) {
      console.error('Error saving follow-up:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Wrap the delete handler to ensure it returns a boolean for UI state management
  const handleDeleteWithState = async (id: string): Promise<boolean> => {
    try {
      await handleDelete(id);
      return true;
    } catch (error) {
      console.error('Error in handleDeleteWithState:', error);
      return false;
    }
  };

  // Reset form on client change
  useEffect(() => {
    setShowForm(false);
    setEditingFollowUp(null);
  }, [clientId]);

  return {
    currentClient: selectedClient,
    followUps: sortedFollowUps,
    isLoading,
    showForm,
    setShowForm,
    sortConfig,
    handleSort,
    handleSave: handleSaveWithState,
    handleDelete: handleDeleteWithState,
    handleUpdatePriority,
    handleUpdateFollowUp,
    editingFollowUp,
    setEditingFollowUp,
    isSubmitting,
    fetchFollowUps,
    invalidateClientCache
  };
};
