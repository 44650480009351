import { useState, useEffect, useCallback } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Client } from "@/types/client";
import { Stage } from "@/types/stage";

export const useStages = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [stages, setStages] = useState<Stage[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();

  const fetchData = useCallback(async () => {
    try {
      console.log("Fetching stages data...");
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data: stagesData, error: stagesError } = await supabase
        .from('stages')
        .select('*')
        .eq('user_id', user.id)
        .order('order_index');

      if (stagesError) throw stagesError;
      
      if (!stagesData || stagesData.length === 0) {
        const defaultStages = [
          "Intro", "NDA", "RFQ", "OS", "Negotiation",
          "Purchase Order", "Production", "Shipment", "Delivered", "Payment"
        ];
        
        for (let i = 0; i < defaultStages.length; i++) {
          await supabase
            .from('stages')
            .insert({
              name: defaultStages[i],
              order_index: i,
              user_id: user.id
            });
        }

        const { data: updatedStages } = await supabase
          .from('stages')
          .select('*')
          .eq('user_id', user.id)
          .order('order_index');
          
        setStages(updatedStages || []);
      } else {
        setStages(stagesData);
      }

      const { data: clientStages, error: clientStagesError } = await supabase
        .from('client_stages')
        .select(`
          client_id,
          current_stage,
          bullet_color,
          is_active,
          clients (
            id,
            company,
            contact,
            phone,
            mobile,
            email,
            web,
            address,
            city,
            state,
            zip,
            notes,
            industry,
            user_id,
            created_at,
            color
          )
        `)
        .eq('user_id', user.id)
        .eq('is_active', true);

      if (clientStagesError) throw clientStagesError;

      const processedClients: Client[] = (clientStages || [])
        .filter(item => item.clients && item.current_stage)
        .map(item => ({
          ...item.clients,
          current_stage: item.current_stage,
          bullet_color: item.bullet_color || 'bg-gray-400'
        }));

      setClients(processedClients);
      setIsLoading(false);

    } catch (error: any) {
      console.error('Error fetching data:', error);
      toast({
        title: "Error",
        description: "Failed to load data",
        variant: "destructive",
      });
      setIsLoading(false);
    }
  }, [toast]);

  const handleAddStage = async (name: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const maxOrderIndex = Math.max(...stages.map(s => s.order_index), -1);

      const { data, error } = await supabase
        .from('stages')
        .insert({
          name,
          order_index: maxOrderIndex + 1,
          user_id: user.id
        })
        .select()
        .single();

      if (error) throw error;

      setStages(prev => [...prev, data]);
      toast({
        title: "Success",
        description: "Stage added successfully",
      });
    } catch (error: any) {
      console.error('Error adding stage:', error);
      toast({
        title: "Error",
        description: "Failed to add stage",
        variant: "destructive",
      });
    }
  };

  const handleEditStage = async (stageId: number, newName: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { error } = await supabase
        .from('stages')
        .update({ name: newName })
        .eq('id', stageId)
        .eq('user_id', user.id);

      if (error) throw error;

      const oldStage = stages.find(s => s.id === stageId);
      if (oldStage) {
        await supabase
          .from('client_stages')
          .update({ current_stage: newName })
          .eq('current_stage', oldStage.name)
          .eq('user_id', user.id);

        setStages(prev => 
          prev.map(stage => 
            stage.id === stageId 
              ? { ...stage, name: newName }
              : stage
          )
        );

        setClients(prev =>
          prev.map(client =>
            client.current_stage === oldStage.name
              ? { ...client, current_stage: newName }
              : client
          )
        );
      }

      toast({
        title: "Success",
        description: "Stage updated successfully",
      });
    } catch (error: any) {
      console.error('Error updating stage:', error);
      toast({
        title: "Error",
        description: "Failed to update stage",
        variant: "destructive",
      });
    }
  };

  const handleDeleteStage = async (stageId: number) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { error } = await supabase
        .from('stages')
        .delete()
        .eq('id', stageId)
        .eq('user_id', user.id);

      if (error) throw error;

      const deletedStage = stages.find(s => s.id === stageId);
      setStages(prev => prev.filter(stage => stage.id !== stageId));
      
      const updatedStages = stages
        .filter(stage => stage.id !== stageId)
        .map((stage, index) => ({
          ...stage,
          order_index: index
        }));
      
      for (const stage of updatedStages) {
        await supabase
          .from('stages')
          .update({ order_index: stage.order_index })
          .eq('id', stage.id)
          .eq('user_id', user.id);
      }

      setStages(updatedStages);

      if (deletedStage) {
        setClients(prev => 
          prev.filter(client => client.current_stage !== deletedStage.name)
        );
      }

      toast({
        title: "Success",
        description: "Stage deleted successfully",
      });
    } catch (error: any) {
      console.error('Error deleting stage:', error);
      toast({
        title: "Error",
        description: "Failed to delete stage",
        variant: "destructive",
      });
    }
  };

  const handleDeleteClientFromStage = async (clientId: number, stage: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("User not authenticated");

      const client = clients.find(client => client.id === clientId);
      if (!client) throw new Error("Client not found");
      
      const { error: updateError } = await supabase
        .from('client_stages')
        .update({ is_active: false })
        .eq('client_id', clientId)
        .eq('current_stage', stage)
        .eq('user_id', user.id);
        
      if (updateError) throw updateError;
      
      const action = `Removed from ${stage}`;
      const description = `${client.company} was removed from ${stage} stage`;
      
      await supabase
        .from('follow_ups')
        .insert({
          client_id: clientId,
          user_id: user.id,
          action,
          description,
          stage,
          status: 'pending',
          date: new Date().toISOString().split('T')[0],
          next_step: 'None',
          priority: 'medium',
          created_at: new Date().toISOString(),
          industry: client.industry || null
        });
      
      setClients(prev => 
        prev.filter(c => !(c.id === clientId && c.current_stage === stage))
      );
      
      fetchData();
      
      toast({
        title: "Success",
        description: `${client.company} was removed from ${stage}`,
      });
    } catch (error: any) {
      console.error('Error removing client from stage:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to remove client from stage",
        variant: "destructive",
      });
    }
  };

  const handleDuplicateClient = async (client: Client, targetStage: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("User not authenticated");
      
      const { data: existingStageEntries } = await supabase
        .from('client_stages')
        .select('*')
        .eq('client_id', client.id)
        .eq('current_stage', targetStage)
        .eq('user_id', user.id);
        
      if (existingStageEntries && existingStageEntries.length > 0) {
        const { error: updateError } = await supabase
          .from('client_stages')
          .update({ 
            is_active: true,
            bullet_color: client.bullet_color || 'bg-gray-400'
          })
          .eq('id', existingStageEntries[0].id);
          
        if (updateError) throw updateError;
      } else {
        const { error: stageError } = await supabase
          .from('client_stages')
          .insert({
            client_id: client.id,
            user_id: user.id,
            current_stage: targetStage,
            is_active: true,
            bullet_color: client.bullet_color || 'bg-gray-400'
          });
            
        if (stageError) throw stageError;
      }
      
      const action = `Cloned from ${client.current_stage || 'another stage'}`;
      const description = `${client.company} was added to ${targetStage} stage`;
      
      await supabase
        .from('follow_ups')
        .insert({
          client_id: client.id,
          user_id: user.id,
          action,
          description,
          stage: targetStage,
          status: 'pending',
          date: new Date().toISOString().split('T')[0],
          next_step: 'Review client details',
          priority: 'medium',
          created_at: new Date().toISOString(),
          industry: client.industry || null
        });
      
      const newClient = {
        ...client,
        current_stage: targetStage
      };
      
      setClients(prev => [...prev, newClient]);
      
      fetchData();
      
      toast({
        title: "Success",
        description: `${client.company} was added to ${targetStage}`,
      });
      
      return true;
    } catch (error: any) {
      console.error('Error duplicating client to stage:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to duplicate client",
        variant: "destructive",
      });
      throw error;
    }
  };

  const handleColorChange = async (clientId: number, color: string, stageName: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("User not authenticated");
      
      const clientToUpdate = clients.find(client => 
        client.id === clientId && client.current_stage === stageName
      );
      
      if (!clientToUpdate) {
        console.error("Client not found in stage:", { clientId, stageName });
        throw new Error("Client not found in this stage");
      }
      
      console.log("useStages: Updating client color:", {
        clientId,
        stage: stageName,
        color
      });
      
      // First, update the database for the specific client and stage
      const { error } = await supabase
        .from('client_stages')
        .update({ bullet_color: color })
        .eq('client_id', clientId)
        .eq('current_stage', stageName)
        .eq('user_id', user.id)
        .eq('is_active', true);
      
      if (error) {
        console.error("useStages: Error updating client_stages:", error);
        throw error;
      }
      
      // Then, update the local state for only this specific client in this specific stage
      setClients(prevClients => prevClients.map(client => {
        if (client.id === clientId && client.current_stage === stageName) {
          return { ...client, bullet_color: color };
        }
        return client;
      }));
      
      console.log("useStages: Client color updated successfully for stage:", stageName);
      return;
    } catch (error: any) {
      console.error('useStages: Error updating client color:', error);
      throw error;
    }
  };

  const handleAddClientToStage = async (client: Client, stageName: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        toast({
          title: "Error",
          description: "User not authenticated",
          variant: "destructive",
        });
        return;
      }
      
      const { data: existingEntries, error: checkError } = await supabase
        .from('client_stages')
        .select('*')
        .eq('client_id', client.id)
        .eq('current_stage', stageName)
        .eq('user_id', user.id);

      if (checkError) throw checkError;
      
      const existingActiveEntry = existingEntries?.find(entry => entry.is_active);
      
      if (existingActiveEntry) {
        toast({
          title: "Already in stage",
          description: `${client.company} is already in the ${stageName} stage`,
        });
        return;
      }

      const inactiveEntry = existingEntries?.find(entry => !entry.is_active);
      
      if (inactiveEntry) {
        const { error: updateError } = await supabase
          .from('client_stages')
          .update({ 
            is_active: true,
            bullet_color: 'bg-gray-400'
          })
          .eq('id', inactiveEntry.id);
          
        if (updateError) throw updateError;
      } else {
        const { error: insertError } = await supabase
          .from('client_stages')
          .insert({
            client_id: client.id,
            current_stage: stageName,
            user_id: user.id,
            is_active: true,
            bullet_color: 'bg-gray-400'
          });
          
        if (insertError) throw insertError;
      }
      
      const { error: followUpError } = await supabase
        .from('follow_ups')
        .insert({
          client_id: client.id,
          user_id: user.id,
          action: `Added to ${stageName}`,
          description: `Added ${client.company} to ${stageName} stage`,
          stage: stageName,
          status: 'pending',
          date: new Date().toISOString().split('T')[0],
          next_step: 'Review',
          priority: 'medium',
          industry: client.industry || null,
          created_at: new Date().toISOString()
        });
        
      if (followUpError) throw followUpError;
      
      const newClient = {
        ...client,
        current_stage: stageName,
        bullet_color: 'bg-gray-400'
      };
      
      setClients(prev => [...prev, newClient]);
      
      toast({
        title: "Success",
        description: `Added ${client.company} to ${stageName} stage`,
      });
      
      fetchData();
      
    } catch (error: any) {
      console.error("Error adding client to stage:", error);
      toast({
        title: "Error",
        description: error.message || "Failed to add client to stage",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    console.log("Setting up realtime subscriptions");
    
    const clientStagesChannel = supabase
      .channel('client-stages-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'client_stages'
        },
        () => {
          console.log("Client stage change detected, refreshing data");
          fetchData();
        }
      )
      .subscribe();
      
    const followUpsChannel = supabase
      .channel('follow-ups-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'follow_ups'
        },
        () => {
          console.log("Follow-up change detected, refreshing data");
          fetchData();
        }
      )
      .subscribe();
    
    fetchData();
    
    return () => {
      console.log("Cleaning up realtime subscriptions");
      supabase.removeChannel(clientStagesChannel);
      supabase.removeChannel(followUpsChannel);
    };
  }, [fetchData]);

  return {
    clients,
    stages,
    isLoading,
    fetchData,
    handleDeleteClientFromStage,
    handleDuplicateClient,
    handleColorChange,
    handleAddStage,
    handleEditStage,
    handleDeleteStage,
    handleAddClientToStage
  };
};
