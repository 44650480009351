
import { Button } from "@/components/ui/button";
import { RotateCw } from "lucide-react";
import Header from "@/components/Header";

interface DashboardHeaderProps {
  onRefresh: () => void;
}

const DashboardHeader = ({ onRefresh }: DashboardHeaderProps) => {
  return (
    <>
      <Header />
      <div className="container mx-auto p-4">
        <div className="flex items-center gap-2 mb-6">
          <h1 className="text-2xl font-bold">Dashboard</h1>
          <Button
            variant="ghost"
            size="sm"
            className="p-0 hover:bg-transparent"
            onClick={onRefresh}
            title="Refresh dashboard"
          >
            <RotateCw className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default DashboardHeader;
