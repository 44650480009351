
import React from "react";

const EmptyState: React.FC = () => {
  return (
    <tr>
      <td colSpan={6} className="py-8 text-center text-gray-500">
        <div className="flex flex-col items-center justify-center space-y-2">
          <p>No follow-ups found. Add one to get started.</p>
          <p className="text-xs text-gray-400">If you expected to see data here, try refreshing the page.</p>
        </div>
      </td>
    </tr>
  );
};

export default EmptyState;
