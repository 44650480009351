import { useState, useEffect, useCallback } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { CalendarDays, Calendar } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import TodoItem from "./todo/TodoItem";
import TodoInput from "./todo/TodoInput";
import { Button } from "@/components/ui/button";
import { Todo } from "@/types/todo";
import { Link } from "react-router-dom";
import { useCalendarEvents } from "@/hooks/useCalendarEvents";
import { followUpTodoService } from "@/services/followUps/followUpTodoService";

const ActivitiesPanel = () => {
  const { toast } = useToast();
  const [todos, setTodos] = useState<Todo[]>([]);
  const [availableStages, setAvailableStages] = useState<string[]>([]);
  const { getCurrentWeek } = useCalendarEvents();
  const [currentWeek, setCurrentWeek] = useState<number>(0);

  const updateCurrentWeek = useCallback(() => {
    const weekNum = getCurrentWeek();
    console.log('Current week calculated:', weekNum);
    setCurrentWeek(weekNum);
  }, [getCurrentWeek]);

  useEffect(() => {
    updateCurrentWeek();
    
    const intervalId = setInterval(() => {
      updateCurrentWeek();
    }, 60000);
    
    return () => clearInterval(intervalId);
  }, [updateCurrentWeek]);

  useEffect(() => {
    fetchTodos();
    fetchAvailableStages();
    
    const channel = supabase
      .channel('todos-changes')
      .on(
        'postgres_changes',
        { 
          event: '*', 
          schema: 'public', 
          table: 'todos' 
        },
        () => {
          console.log('Todos changed, refreshing...');
          fetchTodos();
        }
      )
      .subscribe();
      
    const followUpsChannel = supabase
      .channel('follow-ups-changes')
      .on(
        'postgres_changes',
        { 
          event: '*', 
          schema: 'public', 
          table: 'follow_ups' 
        },
        (payload) => {
          console.log('Follow-ups changed:', payload);
        }
      )
      .subscribe();
      
    return () => {
      supabase.removeChannel(channel);
      supabase.removeChannel(followUpsChannel);
    };
  }, []);

  const fetchAvailableStages = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('stages')
        .select('name')
        .eq('user_id', user.id);

      if (error) throw error;
      if (data) {
        setAvailableStages(data.map(stage => stage.name));
      }
    } catch (error) {
      console.error('Error fetching stages:', error);
    }
  };

  const fetchTodos = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('No user found');
        return;
      }

      const { data, error } = await supabase
        .from('todos')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      if (data) {
        const formattedTodos = data.map(todo => {
          const formattedTodo: Todo = {
            id: todo.id,
            text: todo.text || '',
            action: todo.action || '',
            client: todo.client || '',
            description: todo.description || '',
            completed: todo.completed || false,
            user_id: todo.user_id,
            created_at: todo.created_at
          };
          
          if (!formattedTodo.action && !formattedTodo.client && formattedTodo.text) {
            const parts = formattedTodo.text.split(' ');
            formattedTodo.action = parts[0] || '';
            formattedTodo.client = parts.length > 1 ? parts[1] : '';
            formattedTodo.description = parts.length > 2 ? parts.slice(2).join(' ') : '';
          }
          
          return formattedTodo;
        });
        
        const sortedTodos = formattedTodos.sort((a, b) => {
          if (a.completed === b.completed) {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
          }
          return a.completed ? 1 : -1;
        });
        
        setTodos(sortedTodos);
      }
    } catch (error: any) {
      toast({
        title: "Error",
        description: "Failed to fetch todos",
        variant: "destructive",
      });
    }
  };

  const handleAddTodo = async (action: string, client: string, description: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const text = `${action} ${client} ${description}`;

      const { error } = await supabase
        .from('todos')
        .insert([{ 
          text: text.trim(), 
          action: action.trim(), 
          client: client.trim(), 
          description: description.trim(), 
          completed: false, 
          user_id: user.id 
        }]);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Task added successfully",
      });
      fetchTodos();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to add todo",
        variant: "destructive",
      });
    }
  };

  const handleDeleteTodo = async (id: number) => {
    try {
      const { error } = await supabase
        .from('todos')
        .delete()
        .eq('id', id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Task deleted successfully",
      });
      fetchTodos();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete todo",
        variant: "destructive",
      });
    }
  };

  const handleUpdateTodo = async (id: number, action: string, client: string, description: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const text = `${action} ${client} ${description}`;

      const { error } = await supabase
        .from('todos')
        .update({ 
          text: text.trim(), 
          action: action.trim(), 
          client: client.trim(), 
          description: description.trim() 
        })
        .eq('id', id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Task updated successfully",
      });
      fetchTodos();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update todo",
        variant: "destructive",
      });
    }
  };

  const handleToggleTodo = async (id: number, newCompletedState: boolean) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('No user found');
        return;
      }
      
      const todo = todos.find(t => t.id === id);
      if (!todo) {
        console.error('Todo not found');
        return;
      }
      
      console.log(`Toggle todo ${id} - Current state: ${todo.completed}, New state: ${newCompletedState}`);
      
      const completionWeek = getCurrentWeek();
      console.log('Current completion week:', completionWeek);
      
      const { error: updateError } = await supabase
        .from('todos')
        .update({ completed: newCompletedState })
        .eq('id', id);
        
      if (updateError) {
        console.error('Error updating todo:', updateError);
        throw updateError;
      }
      
      if (newCompletedState) {
        console.log('Marking todo as completed:', todo);
        
        const success = await createFollowUpFromTodo(todo, completionWeek, false);
        
        if (success) {
          toast({
            title: "Success",
            description: "Task completed and added to follow-ups",
          });
          
          // Trigger a followup refresh event
          window.dispatchEvent(new CustomEvent('invalidate-followup-cache', {
            detail: { source: 'todo-completion', priority: 'high' }
          }));
          
          // Also trigger a scroll to followup event after a short delay
          setTimeout(() => {
            window.dispatchEvent(new CustomEvent('scroll-to-followup', {
              detail: { immediate: false, behavior: 'smooth' }
            }));
          }, 1000);
        } else {
          toast({
            title: "Warning",
            description: "Task completed but couldn't add to follow-ups",
          });
        }
      } 
      else {
        console.log('Marking todo as incomplete:', todo);
        
        const success = await removeFollowUpForTodo(todo, user.id);
        
        if (success) {
          toast({
            title: "Success",
            description: "Task marked as incomplete and removed from follow-ups",
          });
          
          // Trigger a followup refresh event
          window.dispatchEvent(new CustomEvent('invalidate-followup-cache', {
            detail: { source: 'todo-unmarked', priority: 'high' }
          }));
        } else {
          toast({
            title: "Warning",
            description: "Task marked as incomplete but couldn't find related follow-up entry",
          });
        }
      }
      
      fetchTodos();
    } catch (error) {
      console.error('Error in handleToggleTodo:', error);
      toast({
        title: "Error",
        description: "Failed to update task status",
        variant: "destructive",
      });
    }
  };

  const removeFollowUpForTodo = async (todo: Todo, userId: string): Promise<boolean> => {
    try {
      console.log('Removing follow-up for todo:', todo);
      
      return await followUpTodoService.findAndDeleteTodoFollowUp(
        todo.client, 
        todo.action, 
        todo.description, 
        userId
      );
    } catch (error) {
      console.error('Error in removeFollowUpForTodo:', error);
      return false;
    }
  };

  const createFollowUpFromTodo = async (todo: Todo, completionWeek: number, aggregate: boolean = true): Promise<boolean> => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('No user found');
        return false;
      }

      console.log('Creating follow-up from todo:', todo);
      console.log('Todo completed in week:', completionWeek);
      console.log('Aggregate entries:', aggregate);

      let clientId = await followUpTodoService.findClientIdByName(todo.client, user.id);
      
      if (!clientId) {
        console.log('No matching client found for:', todo.client);
        return false;
      }

      console.log('Found matching client ID:', clientId);

      // Only use the description for the follow-up description, the action is stored separately
      const combinedDescription = todo.description || '';

      try {
        const result = await followUpTodoService.createNewFollowUpForRecheckedTodo({
          client_id: clientId,
          user_id: user.id,
          date: new Date().toISOString().split('T')[0],
          description: combinedDescription, // Just use the description here
          next_step: '',
          action: todo.action, // Action is stored separately
          status: 'completed',
          priority: 'medium',
          stage: 'Completed',
          created_at: new Date().toISOString(),
          next_step_date: null,
          next_step_week: null,
          industry: null
        }, completionWeek);
        
        if (result.success) {
          // Trigger events to show the follow-up panel
          window.dispatchEvent(new CustomEvent('show-follow-up-panel', {
            detail: { clientId }
          }));
          
          return true;
        }
        
        // If service method fails, we'll try the fallback method
        console.log('Service method failed, trying direct creation');
      } catch (err) {
        console.error('Error in service method:', err);
      }
      
      const today = new Date();
      const dateString = today.toISOString().split('T')[0];
      
      if (aggregate) {
        const { data: existingFollowUp, error: checkError } = await supabase
          .from('follow_ups')
          .select('id, description')
          .eq('client_id', clientId)
          .eq('week', completionWeek)
          .eq('action', todo.action)
          .eq('status', 'completed');
          
        if (checkError) {
          console.error('Error checking for existing follow-up:', checkError);
        } else if (existingFollowUp && existingFollowUp.length > 0) {
          console.log('Follow-up already exists for this client/week, updating it');
          
          // Update existing follow-up with combined description
          const newDescription = `${existingFollowUp[0].description}\n${todo.action}: ${todo.description || 'Completed task'}`.trim();
          
          const { error: updateError } = await supabase
            .from('follow_ups')
            .update({
              description: newDescription
            })
            .eq('id', existingFollowUp[0].id);
            
          if (updateError) {
            console.error('Error updating existing follow-up:', updateError);
            return false;
          }
          
          // Notify about follow-up update
          window.dispatchEvent(new CustomEvent('follow-up-updated-from-todo', {
            detail: { id: existingFollowUp[0].id, clientId }
          }));
          
          return true;
        }
      }

      let stageName = 'Completed';
      
      if (!availableStages.includes('Completed')) {
        if (availableStages.includes('Intro')) {
          stageName = 'Intro';
        } else if (availableStages.length > 0) {
          stageName = availableStages[0];
        }
      }
      
      console.log('Using stage:', stageName);
      
      try {
        const { data, error } = await supabase
          .from('follow_ups')
          .insert({
            date: dateString,
            description: combinedDescription, // Just use the description here
            next_step: '',
            action: todo.action,
            status: 'completed',
            priority: 'medium',
            client_id: clientId,
            user_id: user.id,
            stage: stageName,
            week: completionWeek,
            industry: null,
            next_step_date: null,
            next_step_week: null,
            created_at: new Date().toISOString()
          })
          .select();

        if (error) {
          console.error('Error creating follow-up:', error);
          
          if (error.code === '23505') {
            console.log('Unique constraint violated, trying alternative approach');
            return await createCompletedTaskWithoutNewStage(todo, clientId, user.id, dateString, completionWeek);
          }
          
          return false;
        }
        
        console.log('Successfully created follow-up:', data);
        
        // Notify about follow-up creation
        window.dispatchEvent(new CustomEvent('follow-up-created-from-todo', {
          detail: { id: data[0].id, clientId }
        }));
        
        // Also trigger an event to show the follow-up panel
        window.dispatchEvent(new CustomEvent('show-follow-up-panel', {
          detail: { clientId }
        }));
        
        return true;
      } catch (error) {
        console.error('Unexpected error in follow-up creation:', error);
        return await createCompletedTaskWithoutNewStage(todo, clientId, user.id, dateString, completionWeek);
      }
    } catch (error) {
      console.error('Error creating follow-up from todo:', error);
      return false;
    }
  };

  // Update createCompletedTaskWithoutNewStage to use combined description
  const createCompletedTaskWithoutNewStage = async (
    todo: Todo, 
    clientId: number, 
    userId: string,
    dateString: string,
    completionWeek: number
  ): Promise<boolean> => {
    try {
      console.log('Creating completed task without new stage...');
      
      const sanitizedDesc = (todo.description || 'task')
        .toLowerCase()
        .replace(/[^a-z0-9]/g, '_')
        .substring(0, 20);
      const uniqueTaskId = Date.now().toString().substring(8);
      const taskStageName = `task_${sanitizedDesc}_${uniqueTaskId}`;
      
      const { data: existingStages } = await supabase
        .from('stages')
        .select('name')
        .eq('user_id', userId)
        .eq('name', taskStageName);
        
      if (!existingStages || existingStages.length === 0) {
        const { error: stageError } = await supabase
          .from('stages')
          .insert({
            name: taskStageName,
            user_id: userId,
            order_index: 999
          });
          
        if (stageError) {
          console.error('Failed to create stage:', stageError);
          return false;
        }
      }

      const { data, error } = await supabase
        .from('follow_ups')
        .insert({
          date: dateString,
          description: todo.description || '', // Just use the description
          next_step: '',
          action: todo.action, // Action is stored separately
          status: 'completed',
          priority: 'medium',
          client_id: clientId,
          user_id: userId,
          stage: taskStageName,
          week: completionWeek,
          industry: null,
          next_step_date: null,
          next_step_week: null,
          created_at: new Date().toISOString()
        })
        .select();

      if (error) {
        console.error('Alternative task creation also failed:', error);
        return false;
      }
      
      console.log('Successfully created task follow-up with special stage name:', data);
      
      // Notify about follow-up creation
      window.dispatchEvent(new CustomEvent('follow-up-created-from-todo', {
        detail: { id: data[0].id, clientId }
      }));
      
      // Also trigger an event to show the follow-up panel
      window.dispatchEvent(new CustomEvent('show-follow-up-panel', {
        detail: { clientId }
      }));
      
      return true;
    } catch (error) {
      console.error('Error in createCompletedTaskWithoutNewStage:', error);
      return false;
    }
  };

  return (
    <Card className="h-fit relative border shadow-sm">
      <CardHeader className="bg-gray-50/80 border-b py-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <CalendarDays className="w-5 h-5 text-gray-600" />
            <CardTitle className="text-lg font-medium text-gray-700">
              Weekly Actions 
              <span className="ml-2 text-sm text-gray-500 font-normal">Week {currentWeek}</span>
            </CardTitle>
          </div>
          <Link to="/weeks">
            <Button
              variant="ghost"
              size="sm"
              className="p-2 bg-white"
              title="View Weeks"
            >
              <Calendar className="h-4 w-4" />
            </Button>
          </Link>
        </div>
      </CardHeader>
      <CardContent className="p-3 space-y-3">
        <div className="space-y-2">
          <TodoInput onAdd={handleAddTodo} />
          <div className="space-y-1">
            {todos.map((todo) => (
              <TodoItem
                key={todo.id}
                id={todo.id}
                action={todo.action}
                client={todo.client}
                description={todo.description}
                completed={todo.completed}
                onToggle={handleToggleTodo}
                onDelete={handleDeleteTodo}
                onUpdate={handleUpdateTodo}
              />
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ActivitiesPanel;
