
import { useEffect, useRef, useCallback } from "react";
import { Client } from "@/types/client";

interface UseFollowUpClientTrackingProps {
  selectedClient: Client | null;
  activePanel: string;
  fetchFollowUps: (force?: boolean) => void;
  scrollPanelIntoView: () => void;
  invalidateClientCache: (clientId: number) => void;
}

export const useFollowUpClientTracking = ({
  selectedClient,
  activePanel,
  fetchFollowUps,
  scrollPanelIntoView,
  invalidateClientCache
}: UseFollowUpClientTrackingProps) => {
  // Use ref to track previous client and panel state to prevent unnecessary fetches
  const prevClientRef = useRef<number | null>(null);
  const prevPanelRef = useRef<string | null>(null);
  const initialRenderRef = useRef(true);
  
  // Force refresh when panel becomes active or client changes
  useEffect(() => {
    if (selectedClient?.id) {
      const currentClientId = selectedClient.id;
      const isPanelActive = activePanel === 'follow-up';
      const clientChanged = currentClientId !== prevClientRef.current;
      const panelBecameActive = isPanelActive && prevPanelRef.current !== 'follow-up';
      
      console.log(
        'FollowUpPanel effect triggered:', 
        `clientId=${currentClientId}`, 
        `clientChanged=${clientChanged}`, 
        `panelActive=${isPanelActive}`, 
        `panelBecameActive=${panelBecameActive}`
      );
      
      // Fetch if client changed, panel became active, or initial render
      if (clientChanged || panelBecameActive || initialRenderRef.current) {
        console.log(`Fetching follow-ups: client changed=${clientChanged}, panel became active=${panelBecameActive}, initialRender=${initialRenderRef.current}`);
        
        // Use a small timeout to ensure this runs after any navigation state is settled
        setTimeout(() => {
          if (selectedClient?.id === currentClientId) {
            fetchFollowUps(true);
            
            // Scroll to panel when data is fetched
            if (isPanelActive) {
              scrollPanelIntoView();
              
              // Also invalidate any caches to ensure fresh data
              invalidateClientCache(currentClientId);
            }
          }
        }, 250);
        
        initialRenderRef.current = false;
      }
      
      // Update refs to track state for next render
      prevClientRef.current = currentClientId;
      prevPanelRef.current = activePanel;
    }
  }, [selectedClient, activePanel, fetchFollowUps, scrollPanelIntoView, invalidateClientCache]);

  return {
    initialRenderRef,
    prevClientRef,
    prevPanelRef
  };
};
