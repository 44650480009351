import { useState } from "react";
import Header from "@/components/Header";
import TradeShowsPanel from "@/components/panels/trade-shows/TradeShowsPanel";
import ContactsPanel from "@/components/panels/ContactsPanel";
import ContactInfoPanel from "@/components/panels/ContactInfoPanel";
import AddTradeShowForm from "@/components/panels/trade-shows/AddTradeShowForm";
import QuickCaptureButton from "@/components/contacts/QuickCaptureButton";
import { TradeShowContact } from "@/types/tradeShow";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";

const TradeShows = () => {
  const [selectedIndustry, setSelectedIndustry] = useState<string | null>(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState<TradeShowContact | null>(null);

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="container mx-auto p-4">
        <h1 className="page-title">Trade Shows</h1>

        {/* Mobile Layout (hidden on md and up) */}
        <div className="md:hidden space-y-4">
          <TradeShowsPanel
            selectedIndustry={selectedIndustry}
            onIndustrySelect={setSelectedIndustry}
            onAddClick={() => setIsAddFormOpen(true)}
            refreshTrigger={refreshTrigger}
          />
          <div className="space-y-4">
            <ContactsPanel 
              onContactSelect={setSelectedContact} 
              selectedIndustry={selectedIndustry}
            />
            <ContactInfoPanel selectedContact={selectedContact} />
          </div>
        </div>

        {/* Desktop Layout (hidden on mobile, visible from md up) */}
        <div className="hidden md:grid md:grid-cols-12 md:gap-4">
          <div className="md:col-span-3">
            <ContactsPanel 
              onContactSelect={setSelectedContact} 
              selectedIndustry={selectedIndustry}
            />
          </div>

          <div className="md:col-span-6">
            <ContactInfoPanel selectedContact={selectedContact} />
          </div>

          <div className="md:col-span-3">
            <TradeShowsPanel
              selectedIndustry={selectedIndustry}
              onIndustrySelect={setSelectedIndustry}
              onAddClick={() => setIsAddFormOpen(true)}
              refreshTrigger={refreshTrigger}
            />
          </div>
        </div>

        {/* Quick Capture Button - Only shown in trade shows section */}
        <QuickCaptureButton />
      </div>

      <Sheet open={isAddFormOpen} onOpenChange={setIsAddFormOpen}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Add Trade Show</SheetTitle>
          </SheetHeader>
          <AddTradeShowForm
            type="exhibiting"
            selectedIndustry={selectedIndustry}
            onSuccess={() => {
              setIsAddFormOpen(false);
              setRefreshTrigger(prev => prev + 1);
            }}
            onClose={() => setIsAddFormOpen(false)}
          />
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default TradeShows;