
import { Client } from "@/types/client";
import IndustriesPanel from "@/components/panels/IndustriesPanel";
import ClientsListPanel from "@/components/panels/ClientsListPanel";
import ClientInfoPanel from "@/components/panels/ClientInfoPanel";
import FollowUpPanel from "@/components/panels/FollowUpPanel";
import WeeklyCalendarPanel from "@/components/panels/WeeklyCalendarPanel";
import RemindsPanel from "@/components/panels/RemindsPanel";
import ActivitiesPanel from "@/components/panels/ActivitiesPanel";

interface MobileDashboardProps {
  selectedClient: Client | null;
  selectedIndustry: string;
  activePanel: string;
  onClientSelect: (client: Client | null) => void;
  onIndustrySelect: (industry: string) => void;
  onClientSelectFromRemind: (clientId: number) => void;
}

const MobileDashboard = ({ 
  selectedClient, 
  selectedIndustry,
  activePanel,
  onClientSelect, 
  onIndustrySelect,
  onClientSelectFromRemind
}: MobileDashboardProps) => {
  return (
    <div className="md:hidden space-y-4">
      <IndustriesPanel 
        onIndustrySelect={onIndustrySelect} 
        selectedIndustry={selectedIndustry}
      />
      <ClientsListPanel
        selectedClient={selectedClient}
        onClientSelect={onClientSelect}
        selectedIndustry={selectedIndustry}
      />
      <ClientInfoPanel selectedClient={selectedClient} />
      <FollowUpPanel 
        selectedClient={selectedClient}
        activePanel={activePanel}
      />
      <WeeklyCalendarPanel />
      <RemindsPanel 
        selectedIndustry={selectedIndustry} 
        onClientSelect={onClientSelectFromRemind}
      />
      <ActivitiesPanel />
    </div>
  );
};

export default MobileDashboard;
