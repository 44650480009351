
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Priority, Status } from "@/types/followUp";
import { priorityOptions, statusOptions } from "@/utils/followUpUtils";

interface FormStatusPriorityProps {
  status: Status;
  priority: Priority;
  customStatus: string | null;
  onStatusChange: (value: Status) => void;
  onPriorityChange: (value: Priority) => void;
  onCustomStatusChange: (value: string) => void;
}

const FormStatusPriority = ({
  status,
  priority,
  onStatusChange,
  onPriorityChange,
}: FormStatusPriorityProps) => {
  return (
    <>
      <div className="space-y-1.5">
        <label className="text-xs font-medium">Status</label>
        <Select value={status} onValueChange={onStatusChange}>
          <SelectTrigger className="h-8 text-sm">
            <SelectValue placeholder="Select status" />
          </SelectTrigger>
          <SelectContent>
            {statusOptions.map((status) => (
              <SelectItem key={status} value={status}>
                {status}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="space-y-1.5">
        <label className="text-xs font-medium">Priority</label>
        <Select value={priority} onValueChange={onPriorityChange}>
          <SelectTrigger className="h-8 text-sm">
            <SelectValue placeholder="Select priority" />
          </SelectTrigger>
          <SelectContent>
            {priorityOptions.map((priority) => (
              <SelectItem key={priority} value={priority}>
                {priority}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </>
  );
};

export default FormStatusPriority;
