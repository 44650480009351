
import { supabase } from "@/integrations/supabase/client";
import { FollowUp } from "@/types/followUp";
import { authUtils } from '../utils/authUtils';

// Track status updates to prevent duplicate dispatches
const recentStatusUpdates = new Map<string, { status: string, timestamp: number }>();
const activeUpdates = new Set<string>();

export const statusService = {
  /**
   * Updates the status of a follow-up with multiple fallback approaches
   */
  async updateStatus(id: string, status: FollowUp['status']): Promise<boolean> {
    console.log('Attempting to update follow-up status:', { id, status });
    
    // Exit immediately if this exact update is already in progress
    const updateKey = `${id}-${status}`;
    if (activeUpdates.has(updateKey)) {
      console.log('Skipping duplicate in-progress update for ID:', id);
      return true;
    }
    
    // Mark this update as active
    activeUpdates.add(updateKey);
    
    try {
      const userId = await authUtils.getCurrentUserId();
      if (!userId) throw new Error("No authenticated user");

      // Get current follow-up data before update for event notification
      const { data: followUp } = await supabase
        .from('follow_ups')
        .select('client_id, stage, status')
        .eq('id', id)
        .single();
        
      // Don't continue if status hasn't changed (prevents infinite loops)
      if (followUp && followUp.status === status) {
        console.log('Status is already set to', status, '- skipping update');
        activeUpdates.delete(updateKey);
        return true;
      }

      // Check if we already processed this exact update recently (within 10 seconds)
      const recentUpdate = recentStatusUpdates.get(id);
      const now = Date.now();
      if (recentUpdate && 
          recentUpdate.status === status && 
          now - recentUpdate.timestamp < 10000) {
        console.log('Skipping duplicate status update for ID:', id);
        activeUpdates.delete(updateKey);
        return true;
      }
      
      // Record this update
      recentStatusUpdates.set(id, { status, timestamp: now });
      
      // Clean up old entries from the map
      for (const [key, value] of recentStatusUpdates.entries()) {
        if (now - value.timestamp > 20000) { // Increased from 10000 to 20000ms
          recentStatusUpdates.delete(key);
        }
      }
        
      if (followUp) {
        // Only deactivate stages if going from non-completed to completed
        // or changing between statuses where at least one is completed
        if (status === 'completed' || followUp.status === 'completed') {
          console.log('Deactivating conflicting stages to avoid constraint violation');
          
          await supabase
            .rpc('deactivate_client_stage', {
              p_client_id: followUp.client_id,
              p_stage: followUp.stage
            });
            
          // Small delay to ensure deactivation completes
          await new Promise(resolve => setTimeout(resolve, 300)); // Increased from 100 to 300ms
        }
      }

      // Try direct update approach
      return await statusService.tryDirectUpdate(id, status, followUp, userId, updateKey);
    } catch (error: any) {
      console.error('Error in updateStatus:', error);
      activeUpdates.delete(updateKey);
      throw error;
    }
  },

  /**
   * Try to update status using direct database update
   */
  async tryDirectUpdate(id: string, status: FollowUp['status'], followUp: any, userId: string, updateKey: string): Promise<boolean> {
    try {
      // Add a delay before updating to ensure all operations are complete
      await new Promise(resolve => setTimeout(resolve, 300));
      
      // Now try the direct update method
      const { data: directData, error: directError } = await supabase
        .from('follow_ups')
        .update({ 
          status: status
        })
        .eq('id', id)
        .select();
        
      if (!directError) {
        console.log('Direct update method successful:', directData);
        
        // Add a small delay before dispatching events
        await new Promise(resolve => setTimeout(resolve, 300));
        
        // Dispatch only one event - no need for different sources
        window.dispatchEvent(new CustomEvent('follow-up-status-updated', {
          detail: { 
            id, 
            status, 
            source: 'status-service',
            priority: 'high'
          }
        }));
        
        // Trigger cache invalidation only once with delay
        if (followUp) {
          setTimeout(() => {
            window.dispatchEvent(new CustomEvent('invalidate-followup-cache', {
              detail: { 
                clientId: followUp.client_id,
                source: 'status-update',
                priority: 'high'
              }
            }));
          }, 1000);
        }
        
        // Remove this update from active updates
        setTimeout(() => {
          activeUpdates.delete(updateKey);
        }, 5000);
        
        return true;
      }
      
      console.error('Direct update failed:', directError);
      
      // Try RPC function approach
      return await statusService.tryRpcUpdate(id, status, followUp, userId, updateKey);
    } catch (error) {
      console.error('Error in tryDirectUpdate:', error);
      activeUpdates.delete(updateKey);
      return false;
    }
  },

  /**
   * Try to update status using RPC function
   */
  async tryRpcUpdate(id: string, status: FollowUp['status'], followUp: any, userId: string, updateKey: string): Promise<boolean> {
    try {
      // Add a delay before trying RPC approach
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Second approach: Using the RPC function to handle constraint issues
      const { data: rpcData, error: rpcError } = await supabase
        .rpc('update_follow_up_status_safely', {
          p_follow_up_id: id,
          p_status: status,
          p_user_id: userId
        });
  
      if (!rpcError) {
        console.log('RPC method successful:', rpcData);
        
        // Remove this update from active updates
        setTimeout(() => {
          activeUpdates.delete(updateKey);
        }, 5000);
        
        return true;
      }
      
      console.error('RPC method failed:', rpcError);
      
      // Try with delay approach
      return await statusService.tryDelayedUpdate(id, status, followUp, updateKey);
    } catch (error) {
      console.error('Error in tryRpcUpdate:', error);
      activeUpdates.delete(updateKey);
      return false;
    }
  },

  /**
   * Try to update status after a delay
   */
  async tryDelayedUpdate(id: string, status: FollowUp['status'], followUp: any, updateKey: string): Promise<boolean> {
    try {
      // Third approach: Try with a longer pause and another direct update
      console.log('Trying update with extended delay');
      await new Promise(resolve => setTimeout(resolve, 2000)); // Increased from 1000 to 2000ms
      
      const { data: delayData, error: finalError } = await supabase
        .from('follow_ups')
        .update({ status: status })
        .eq('id', id)
        .select();
        
      if (!finalError) {
        console.log('Final update after delay successful:', delayData);
        
        // Remove this update from active updates
        setTimeout(() => {
          activeUpdates.delete(updateKey);
        }, 5000);
        
        return true;
      }
      
      console.error('All update methods failed');
      console.error('Final error:', finalError);
      activeUpdates.delete(updateKey);
      return false;
    } catch (error) {
      console.error('Error in tryDelayedUpdate:', error);
      activeUpdates.delete(updateKey);
      return false;
    }
  }
};
