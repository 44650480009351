
import { supabase } from "@/integrations/supabase/client";

export const followUpAuthService = {
  /**
   * Gets the current user session
   * @returns User ID if authenticated, null otherwise
   */
  async getCurrentUserId(): Promise<string | null> {
    try {
      const { data: session } = await supabase.auth.getSession();
      if (!session?.session?.user?.id) return null;
      return session.session.user.id;
    } catch (error) {
      console.error("Error getting current user:", error);
      return null;
    }
  }
};
