
import { supabase } from "@/integrations/supabase/client";

export const clientLookupService = {
  /**
   * Find a client ID by name with exact match
   */
  async findClientWithExactMatch(clientName: string, userId: string): Promise<number | null> {
    try {
      const { data, error } = await supabase
        .from('clients')
        .select('id')
        .eq('user_id', userId)
        .eq('company', clientName)
        .limit(1);
        
      if (error) {
        console.error('Error finding client with exact match:', error);
        return null;
      }
      
      return data && data.length > 0 ? data[0].id : null;
    } catch (error) {
      console.error('Error in findClientWithExactMatch:', error);
      return null;
    }
  },
  
  /**
   * Find a client ID by name with case-insensitive match
   */
  async findClientWithFuzzyMatch(clientName: string, userId: string): Promise<number | null> {
    try {
      const { data, error } = await supabase
        .from('clients')
        .select('id')
        .eq('user_id', userId)
        .ilike('company', `%${clientName}%`)
        .limit(1);
        
      if (error) {
        console.error('Error finding client with fuzzy match:', error);
        return null;
      }
      
      return data && data.length > 0 ? data[0].id : null;
    } catch (error) {
      console.error('Error in findClientWithFuzzyMatch:', error);
      return null;
    }
  },
  
  /**
   * Find a client ID specifically for todo follow-ups
   * This is the main method that should be used for todos
   */
  async findTodoFollowUpClientId(clientName: string, userId: string): Promise<number | null> {
    console.log('Finding client ID for:', { clientName });
    
    try {
      // First try exact match
      const exactMatch = await this.findClientWithExactMatch(clientName, userId);
      if (exactMatch) {
        console.log('Found exact client match:', exactMatch);
        return exactMatch;
      }
      
      // If no exact match, try fuzzy match
      const fuzzyMatch = await this.findClientWithFuzzyMatch(clientName, userId);
      if (fuzzyMatch) {
        console.log('Found fuzzy client match:', fuzzyMatch);
        return fuzzyMatch;
      }
      
      console.log('No matching client found');
      return null;
    } catch (error) {
      console.error('Error in findTodoFollowUpClientId:', error);
      return null;
    }
  }
};
