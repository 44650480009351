
import { Client } from "@/types/client";
import IndustriesPanel from "@/components/panels/IndustriesPanel";
import ClientsListPanel from "@/components/panels/ClientsListPanel";
import ClientInfoPanel from "@/components/panels/ClientInfoPanel";
import FollowUpPanel from "@/components/panels/FollowUpPanel";
import WeeklyCalendarPanel from "@/components/panels/WeeklyCalendarPanel";
import RemindsPanel from "@/components/panels/RemindsPanel";
import ActivitiesPanel from "@/components/panels/ActivitiesPanel";

interface DesktopDashboardProps {
  selectedClient: Client | null;
  selectedIndustry: string;
  activePanel: string;
  onClientSelect: (client: Client | null) => void;
  onIndustrySelect: (industry: string) => void;
  onClientSelectFromRemind: (clientId: number) => void;
}

const DesktopDashboard = ({ 
  selectedClient, 
  selectedIndustry,
  activePanel,
  onClientSelect, 
  onIndustrySelect,
  onClientSelectFromRemind
}: DesktopDashboardProps) => {
  return (
    <div className="hidden md:grid md:grid-cols-12 md:gap-4">
      <div className="md:col-span-3 space-y-4">
        <IndustriesPanel 
          onIndustrySelect={onIndustrySelect}
          selectedIndustry={selectedIndustry}
        />
        <ClientsListPanel
          selectedClient={selectedClient}
          onClientSelect={onClientSelect}
          selectedIndustry={selectedIndustry}
        />
        <ClientInfoPanel selectedClient={selectedClient} />
      </div>
      <div className="md:col-span-6">
        <div className="space-y-4">
          <WeeklyCalendarPanel />
          <FollowUpPanel 
            selectedClient={selectedClient}
            activePanel={activePanel}
          />
        </div>
      </div>
      <div className="md:col-span-3">
        <div className="space-y-4">
          <RemindsPanel 
            selectedIndustry={selectedIndustry} 
            onClientSelect={onClientSelectFromRemind}
          />
          <ActivitiesPanel />
        </div>
      </div>
    </div>
  );
};

export default DesktopDashboard;
