
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Client } from "@/types/client";
import { useToast } from "@/hooks/use-toast";

export const useClientsData = (selectedIndustry: string, searchQuery: string) => {
  const [clients, setClients] = useState<Client[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { toast } = useToast();
  const itemsPerPage = 40;

  const fetchClients = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('No user found');
        return;
      }

      // First, get all clients that match the search query if it exists
      let query = supabase
        .from('clients')
        .select('*')
        .eq('user_id', user.id);

      if (searchQuery) {
        query = query.ilike('company', `%${searchQuery}%`);
      }

      const { data: allClients, error: clientsError } = await query;

      if (clientsError) {
        console.error('Error fetching clients:', clientsError);
        toast({
          title: "Error",
          description: "Failed to fetch clients",
          variant: "destructive",
        });
        return;
      }

      // If an industry is selected, filter the clients
      if (selectedIndustry && allClients) {
        const selectedIndustries = selectedIndustry.split(',').map(industry => industry.trim());

        // Get all client IDs that have the selected industries
        const { data: clientIndustriesData, error: clientIndustriesError } = await supabase
          .from('client_industries')
          .select('client_id')
          .in('industry_name', selectedIndustries)
          .eq('user_id', user.id);

        if (clientIndustriesError) {
          console.error('Error fetching client industries:', clientIndustriesError);
          return;
        }

        // Get unique client IDs
        const clientIds = [...new Set(clientIndustriesData?.map(ci => ci.client_id))];

        // Filter clients based on industry and client_industries
        const filteredClients = allClients.filter(client => 
          selectedIndustries.includes(client.industry) || clientIds.includes(client.id)
        );

        setClients(filteredClients.sort((a, b) => a.company.localeCompare(b.company)));
      } else if (allClients) {
        setClients(allClients.sort((a, b) => a.company.localeCompare(b.company)));
      }
    } catch (error) {
      console.error('Error in fetchClients:', error);
      toast({
        title: "Error",
        description: "Failed to fetch clients",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    // Only fetch if there's a search query or selected industry
    if (searchQuery || selectedIndustry) {
      fetchClients();
    } else {
      setClients([]); // Clear clients when no search or industry is selected
    }
    setCurrentPage(1);
  }, [selectedIndustry, searchQuery]);

  // Set up real-time subscription
  useEffect(() => {
    const setupRealtimeSubscription = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const channel = supabase
        .channel('clients-changes')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'clients',
            filter: `user_id=eq.${user.id}`
          },
          () => {
            fetchClients(); // Refetch all clients to ensure we have the correct data
          }
        )
        .subscribe();

      // Also subscribe to client_industries changes
      const industriesChannel = supabase
        .channel('client-industries-changes')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'client_industries',
            filter: `user_id=eq.${user.id}`
          },
          () => {
            fetchClients(); // Refetch all clients when client_industries change
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
        supabase.removeChannel(industriesChannel);
      };
    };

    setupRealtimeSubscription();
  }, [selectedIndustry, searchQuery]);

  return {
    clients,
    currentPage,
    setCurrentPage,
    fetchClients,
    itemsPerPage
  };
};
