
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { preloadClientData, setNavigating } from "@/hooks/followUps/followUpCache";
import { supabase } from "@/integrations/supabase/client";

export const useClientNavigation = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  
  const navigateToClient = useCallback(async (clientId: number) => {
    try {
      console.log("ClientNavigation: Navigating to client:", clientId);
      
      // Start navigation state tracking
      setNavigating(true);
      
      // Preload client data before navigation
      const { data: { session } } = await supabase.auth.getSession();
      const userId = session?.user?.id;
      
      if (!userId) {
        console.error("No user session found");
        return;
      }
      
      await preloadClientData(clientId, userId);
      
      // Pre-notify the app about invalidation before navigation
      window.dispatchEvent(new CustomEvent('invalidate-followup-cache', {
        detail: {
          clientId,
          forceRefresh: false, // Don't force since we preloaded
          source: 'stage-panel-click',
          timestamp: Date.now()
        }
      }));

      // Use state for navigation instead of URL params for better performance
      console.log('ClientNavigation: Executing navigation to /index with client ID:', clientId);
      
      navigate('/index', { 
        state: { 
          selectedClient: { id: clientId },
          activePanel: 'follow-up',
          forceActivate: true,
          source: 'stage-panel-click',
          immediate: true,
          timestamp: Date.now(),
          preloaded: true
        },
        replace: true // Use replace to avoid breaking back button functionality
      });
      
      // Ensure the panel gets activated
      setTimeout(() => {
        console.log('ClientNavigation: Dispatching force-panel-activate event');
        window.dispatchEvent(new CustomEvent('force-panel-activate', {
          detail: { 
            panel: 'follow-up',
            clientId: clientId,
            source: 'stage-panel-click',
            immediate: true
          }
        }));
        
        // Also request scrolling to the panel
        window.dispatchEvent(new CustomEvent('scroll-to-followup', {
          detail: { 
            clientId: clientId,
            source: 'stage-panel-click',
            behavior: 'auto',
            immediate: true
          }
        }));
      }, 100);
      
    } catch (error) {
      console.error('Error navigating to client:', error);
      setNavigating(false); // Reset navigation state on error
      toast({
        title: "Error",
        description: "Failed to navigate to client details",
        variant: "destructive",
      });
    }
  }, [navigate, toast]);

  return { navigateToClient };
};
