
import { useState } from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { Trash2, Pencil, Check, X } from "lucide-react";
import { Input } from "@/components/ui/input";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate } from "react-router-dom";
import { useClientNavigation } from "@/hooks/stages/useClientNavigation"; 

interface TodoItemProps {
  id: number;
  action: string;
  client: string;
  description: string;
  completed: boolean;
  onToggle: (id: number, completed: boolean) => void;
  onDelete: (id: number) => void;
  onUpdate?: (id: number, action: string, client: string, description: string) => void;
}

const TodoItem = ({ 
  id, 
  action, 
  client, 
  description, 
  completed, 
  onToggle, 
  onDelete,
  onUpdate
}: TodoItemProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editAction, setEditAction] = useState(action);
  const [editClient, setEditClient] = useState(client);
  const [editDescription, setEditDescription] = useState(description);
  const { navigateToClient } = useClientNavigation();

  const handleSave = () => {
    if (editAction.trim() && editClient.trim() && editDescription.trim()) {
      if (onUpdate) {
        onUpdate(id, editAction, editClient, editDescription);
      }
      setIsEditing(false);
    }
  };

  const handleCancel = () => {
    setEditAction(action);
    setEditClient(client);
    setEditDescription(description);
    setIsEditing(false);
  };

  const handleToggleCheckbox = () => {
    console.log(`Toggling todo ${id} from ${completed} to ${!completed}`);
    onToggle(id, !completed);
  };

  const handleClientClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!client) return;
    
    try {
      // Search for the client by company name
      const { data, error } = await supabase
        .from('clients')
        .select('*')
        .ilike('company', client)
        .limit(1);
      
      if (error) {
        console.error('Error finding client:', error);
        return;
      }
      
      if (data && data.length > 0) {
        // Use the same navigation pattern as the stage panel
        navigateToClient(data[0].id);
      } else {
        console.log('No client found with name:', client);
      }
    } catch (error) {
      console.error('Error in client lookup:', error);
    }
  };

  return (
    <div className="flex items-center justify-between group hover:bg-gray-50 px-1 py-1 rounded">
      {!isEditing ? (
        <>
          <div className="flex items-center space-x-2 flex-grow">
            <Checkbox 
              id={`todo-${id}`} 
              checked={completed}
              onCheckedChange={handleToggleCheckbox}
              className="border-gray-400"
            />
            <div
              className={`text-xs text-gray-600 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ${
                completed ? 'line-through text-gray-400' : ''
              }`}
            >
              <span className="font-medium">{action}</span>
              {" "}
              <span 
                className="text-blue-600 text-left hover:underline cursor-pointer"
                onClick={handleClientClick}
              >
                {client}
              </span>
              {" "}
              <span>{description}</span>
            </div>
          </div>
          <div className="flex items-center space-x-1 opacity-0 group-hover:opacity-100 transition-opacity">
            <button 
              onClick={() => setIsEditing(true)}
              className="text-gray-400 hover:text-blue-500"
            >
              <Pencil className="h-3.5 w-3.5" />
            </button>
            <button 
              onClick={() => onDelete(id)}
              className="text-gray-400 hover:text-red-500"
            >
              <Trash2 className="h-3.5 w-3.5" />
            </button>
          </div>
        </>
      ) : (
        <div className="flex flex-col space-y-1 w-full">
          <div className="flex space-x-1">
            <Input
              value={editAction}
              onChange={(e) => setEditAction(e.target.value)}
              className="text-xs h-6 flex-1"
              placeholder="Action"
            />
            <Input
              value={editClient}
              onChange={(e) => setEditClient(e.target.value)}
              className="text-xs h-6 flex-1"
              placeholder="Client"
            />
          </div>
          <div className="flex space-x-1">
            <Input
              value={editDescription}
              onChange={(e) => setEditDescription(e.target.value)}
              className="text-xs h-6 flex-grow"
              placeholder="Description"
            />
            <button 
              onClick={handleSave}
              className="text-green-500 hover:text-green-600 h-6 w-6 flex items-center justify-center"
            >
              <Check className="h-4 w-4" />
            </button>
            <button 
              onClick={handleCancel}
              className="text-red-500 hover:text-red-600 h-6 w-6 flex items-center justify-center"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TodoItem;
