// Cache management for follow-up data
// This helps reduce unnecessary fetches and improves performance

type CacheEntry<T> = {
  data: T;
  timestamp: number;
};

interface FollowUpCache<T> {
  [clientId: number]: CacheEntry<T>;
}

// Cache expiry time in milliseconds (5 minutes for better freshness)
export const CACHE_EXPIRY_TIME = 300000; 

// Initialize the cache with an object for better debugging
export const followUpsCache: FollowUpCache<any[]> = {};

// Global pre-load cache for faster navigation
const preloadedClients = new Set<number>();

// Track navigation state to avoid duplicate operations
let isNavigating = false;
let lastNavigationTime = 0;

// Max recursion depth protection
let fetchDepth = 0;
const MAX_FETCH_DEPTH = 2;

// Safe getter to avoid recursive calls and stack overflow
export const isRecentlyNavigated = (): boolean => {
  return (Date.now() - lastNavigationTime) < 2000;
};

export const setNavigating = (value: boolean): void => {
  isNavigating = value;
  if (value) {
    lastNavigationTime = Date.now();
  }
};

// Safe cache getter with anti-recursion protection
export const getCachedData = <T>(clientId: number): T[] | null => {
  if (!clientId) return null;
  
  // Prevent stack overflows by limiting fetch depth
  if (fetchDepth > MAX_FETCH_DEPTH) {
    console.warn('Maximum fetch depth exceeded, returning null to prevent stack overflow');
    return null;
  }
  
  fetchDepth++;
  try {
    const cachedData = followUpsCache[clientId];
    const now = Date.now();
    
    // Only return valid (non-expired) cached data
    if (cachedData && (now - cachedData.timestamp < CACHE_EXPIRY_TIME)) {
      return cachedData.data as T[];
    }
    
    return null;
  } finally {
    fetchDepth--;
  }
};

export const setCachedData = <T>(clientId: number, data: T[]): void => {
  if (!clientId) return;
  
  followUpsCache[clientId] = {
    data,
    timestamp: Date.now()
  };
  
  // Mark as preloaded for future reference
  preloadedClients.add(clientId);
};

export const invalidateCache = (clientId?: number): void => {
  // Prevent multiple invalidations during navigation
  if (isNavigating) {
    return;
  }
  
  if (clientId) {
    delete followUpsCache[clientId];
    preloadedClients.delete(clientId);
  } else {
    Object.keys(followUpsCache).forEach(key => {
      delete followUpsCache[parseInt(key)];
      preloadedClients.delete(parseInt(key));
    });
  }
};

export const isClientPreloaded = (clientId: number): boolean => {
  return preloadedClients.has(clientId);
};

// Pre-load client data before navigation - with safeguards
export const preloadClientData = async (clientId: number, userId?: string): Promise<void> => {
  if (!clientId || preloadedClients.has(clientId) || fetchDepth > 0) return;
  
  try {
    // Dynamically import to avoid circular dependencies
    const { followUpService } = await import('@/services/followUps');
    const data = await followUpService.fetchClientFollowUps(clientId, userId);
    
    if (data && Array.isArray(data)) {
      setCachedData(clientId, data);
    }
  } catch (error) {
    console.error('Failed to preload client data:', error);
  }
};

// Set up listeners for various cache-related events
if (typeof window !== 'undefined') {
  // Listen for custom invalidation events
  window.addEventListener('invalidate-followup-cache', ((event: CustomEvent) => {
    // Set navigation state
    if (event.detail && event.detail.source === 'stage-panel-click') {
      setNavigating(true);
      // Reset after navigation is likely complete
      setTimeout(() => { setNavigating(false); }, 1000);
    }
    
    if (event.detail && event.detail.clientId) {
      invalidateCache(event.detail.clientId);
    } else {
      invalidateCache();
    }
  }) as EventListener);
  
  // Listen for background fetch completion
  window.addEventListener('background-fetch-complete', ((event: CustomEvent) => {
    if (event.detail && event.detail.clientId) {
      console.log(`Background fetch completed for client ${event.detail.clientId} with ${event.detail.dataLength} follow-ups`);
      
      // Trigger UI refresh if needed
      window.dispatchEvent(new CustomEvent('invalidate-followup-cache', {
        detail: { 
          clientId: event.detail.clientId,
          source: 'background-fetch',
          priority: 'low' 
        }
      }));
    }
  }) as EventListener);
  
  // Add preload event listener
  window.addEventListener('preload-client-data', ((event: CustomEvent) => {
    if (event.detail && event.detail.clientId) {
      preloadClientData(event.detail.clientId);
    }
  }) as EventListener);
}
