
import { Loader2 } from "lucide-react";

const StagesLoadingState = () => {
  return (
    <div className="container mx-auto p-4 max-w-7xl flex items-center justify-center" style={{ height: 'calc(100vh - 64px)' }}>
      <div className="flex flex-col items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-primary mb-4" />
        <p className="text-muted-foreground">Loading pipeline...</p>
      </div>
    </div>
  );
};

export default StagesLoadingState;
