
import { useCallback } from "react";

export const useFollowUpScroll = () => {
  // Helper function to scroll panel into view
  const scrollPanelIntoView = useCallback(() => {
    console.log('FollowUpPanel: Attempting to scroll panel into view');
    setTimeout(() => {
      const panel = document.getElementById('follow-up-panel');
      if (panel) {
        console.log('FollowUpPanel: Found panel, scrolling it into view');
        panel.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        console.warn('FollowUpPanel: Panel element not found for scrolling');
      }
    }, 200);
  }, []);

  return { scrollPanelIntoView };
};
