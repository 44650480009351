
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";

interface ClientSearchBarProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}

const ClientSearchBar = ({ searchQuery, setSearchQuery }: ClientSearchBarProps) => {
  return (
    <div className="p-3 border-b">
      <div className="relative">
        <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
        <Input
          type="text"
          placeholder="Search clients..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="pl-8"
        />
      </div>
    </div>
  );
};

export default ClientSearchBar;
