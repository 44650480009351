
// Barrel file to export all status-related services
import { priorityService } from './priorityService';
import { statusService } from './statusService';
import { colorService } from './colorService';
import { actionService } from './actionService';

export const followUpStatusService = {
  // Priority methods
  updatePriority: priorityService.updatePriority,
  
  // Status methods
  updateStatus: statusService.updateStatus,
  
  // Action methods
  updateAction: actionService.updateAction,
  
  // Color methods
  updateColor: colorService.updateColor
};
