
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import LoginForm from "@/components/auth/LoginForm";
import Header from "@/components/Header";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleLogin = async (email: string, password: string) => {
    try {
      setLoading(true);
      
      // Log the sign-in attempt for debugging
      console.log(`Attempting to sign in with email: ${email}`);
      
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        console.error("Login error:", error);
        toast({
          title: "Login Failed",
          description: error.message,
          variant: "destructive",
        });
        return;
      }

      // Log successful sign-in for debugging
      console.log("Sign in successful:", data);
      
      // Check if user is an admin
      if (data.user.email === 'jstaack@staackllc.com') {
        console.log("Admin user detected, redirecting to dashboard");
        navigate("/index");
        return;
      }
      
      // For other users, check if they have an active subscription
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('subscription_status')
        .eq('id', data.user.id)
        .single();
        
      if (profileError) {
        console.error("Error fetching profile:", profileError);
        // Redirect to plans page if there's an error fetching the profile
        toast({
          title: "Welcome",
          description: "Please choose a subscription plan to continue",
        });
        navigate("/plans");
        return;
      }
      
      if (profile && profile.subscription_status === 'active') {
        // Redirect to dashboard if user has an active subscription
        console.log("User has active subscription, redirecting to dashboard");
        navigate("/index");
      } else {
        // Redirect to plans page if user doesn't have an active subscription
        console.log("User does not have active subscription, redirecting to plans");
        toast({
          title: "Welcome Back",
          description: "Please choose a subscription plan to continue",
        });
        navigate("/plans");
      }
    } catch (error: any) {
      console.error("Unexpected error during login:", error);
      toast({
        title: "Error",
        description: "An unexpected error occurred. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-md mx-auto">
          <Card>
            <CardHeader className="space-y-1">
              <CardTitle className="text-2xl font-bold">Sign In</CardTitle>
              <CardDescription>
                Enter your credentials to access your account
              </CardDescription>
            </CardHeader>
            <CardContent>
              <LoginForm onSubmit={handleLogin} loading={loading} />
            </CardContent>
            <CardFooter className="flex flex-col space-y-4">
              <div className="text-sm text-center">
                <Link to="/reset-password" className="text-primary hover:underline">
                  Forgot your password?
                </Link>
              </div>
              <div className="text-sm text-center">
                Don't have an account?{" "}
                <Link to="/register" className="text-primary hover:underline">
                  Register
                </Link>
              </div>
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Login;
