
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import Header from "@/components/Header";

const Landing = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white">
      <Header />
      
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" role="main">
        <article className="flex flex-col items-center justify-center min-h-[80vh] text-center space-y-8 animate-fade-up">
          <img 
            src="/lovable-uploads/b9233c0d-7555-460a-afce-1cfe1ffd1ccb.png"
            alt="SalesBy CRM System - Smart Sales Management Solution"
            className="w-24 h-24 mb-4"
            width="96"
            height="96"
            loading="eager"
          />
          
          <h1 className="text-4xl md:text-6xl lg:text-7xl font-black tracking-tight text-gray-900 max-w-4xl leading-tight">
            Transform Your Sales Process with
            <span className="bg-gradient-to-r from-[#ea384c] to-[#F97316] text-transparent bg-clip-text"> SalesBy</span>
          </h1>
          
          <h2 className="text-xl md:text-2xl text-gray-600 max-w-2xl">
            The most affordable and easy-to-use cloud-based CRM. Our smart, distraction-free follow-up system helps boost productivity.
          </h2>
          
          <div className="flex flex-col items-center gap-6 mt-8">
            <Button 
              size="lg"
              onClick={() => navigate("/register")}
              className="bg-gradient-to-r from-[#ea384c] to-[#F97316] text-white hover:opacity-90 px-8 py-6 text-lg"
              aria-label="Start Your Free Trial"
            >
              7 Days Free Trial then $29/month
            </Button>

            <div className="w-full max-w-[320px] sm:max-w-[384px] animate-fade-up">
              <div className="relative w-full overflow-hidden rounded-lg bg-gradient-to-r from-[#ea384c] to-[#F97316] p-[1px]">
                <div className="relative aspect-video w-full">
                  <iframe
                    className="absolute inset-0 h-full w-full rounded-lg"
                    src="https://www.youtube.com/embed/LCSKps-NTJ4"
                    title="Salesby CRM Introduction - Transform Your Sales Process"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </article>
      </main>
    </div>
  );
};

export default Landing;
