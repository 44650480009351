
import { Client } from "@/types/client";
import { Stage } from "@/types/stage";
import StagePanel from "./StagePanel";

interface StagesGridProps {
  stages: Stage[];
  filteredStages: Stage[];
  clients: Client[];
  handleColorChange: (clientId: number, color: string, stageName: string) => Promise<void>;
  handleEditStage: (stageId: number, newName: string) => Promise<void>;
  handleDeleteStage: (stageId: number) => Promise<void>;
  handleDuplicateClient: (client: Client, targetStage: string) => Promise<void>;
  handleDeleteClientFromStage: (clientId: number, stage: string) => void;
  handleAddClientToStage: (client: Client, stage: string) => Promise<void>;
  isAddingClient: boolean;
  setIsAddingClient: (value: boolean) => void;
}

const StagesGrid = ({
  stages,
  filteredStages,
  clients,
  handleColorChange,
  handleEditStage,
  handleDeleteStage,
  handleDuplicateClient,
  handleDeleteClientFromStage,
  handleAddClientToStage,
  isAddingClient,
  setIsAddingClient
}: StagesGridProps) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2">
      {filteredStages
        .sort((a, b) => a.order_index - b.order_index)
        .map((stage) => (
          <StagePanel
            key={stage.id}
            title={stage.name}
            stageId={stage.id.toString()}
            stages={stages}
            clients={clients.filter(
              (client) => client.current_stage === stage.name
            )}
            onColorChange={handleColorChange}
            onEditStage={handleEditStage}
            onDeleteStage={handleDeleteStage}
            onDuplicateClient={handleDuplicateClient}
            onDeleteClientFromStage={handleDeleteClientFromStage}
            onAddClientToStage={handleAddClientToStage}
            globalIsAddingClient={isAddingClient}
            setGlobalIsAddingClient={setIsAddingClient}
          />
        ))}
    </div>
  );
};

export default StagesGrid;
