
import { useState, useEffect, useRef } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { CalendarDays, CalendarRange, Calendar as CalendarIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import CalendarEventForm from "../calendar/CalendarEventForm";
import CalendarTable from "../calendar/CalendarTable";
import { CalendarFormData } from "@/types/calendar";
import { useCalendarEvents } from "@/hooks/useCalendarEvents";
import { MonthlyCalendarView } from "../calendar/MonthlyCalendarView";
import { useIsMobile } from "@/hooks/use-mobile";
import { useToast } from "@/hooks/use-toast";

const WeeklyCalendarPanel = () => {
  const [showMonthView, setShowMonthView] = useState(false);
  const [date, setDate] = useState<Date>(new Date());
  const [showWeekend, setShowWeekend] = useState<string[]>([]);
  const [formData, setFormData] = useState<CalendarFormData>({
    weekNumber: "",
    day: "",
    event: "",
    status: "pending"
  });
  const [currentWeek, setCurrentWeek] = useState<number>(0);
  
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const { toast } = useToast();
  const baseWeekDays = ["Mon", "Tue", "Wed", "Thu", "Fri"];
  const weekendDays = {
    "sat": "Sat",
    "sun": "Sun"
  };
  
  const weekDays = [
    ...baseWeekDays,
    ...showWeekend.map(day => weekendDays[day as keyof typeof weekendDays])
  ];

  const { events, activeWeeks, isLoading, addEvent, deleteEvent, updateEventStatus, getCurrentWeek } = useCalendarEvents();

  useEffect(() => {
    // Update current week on component mount and every minute
    const updateCurrentWeek = () => {
      const weekNum = getCurrentWeek();
      setCurrentWeek(weekNum);
    };
    
    updateCurrentWeek();
    
    const intervalId = setInterval(updateCurrentWeek, 60000);
    
    return () => clearInterval(intervalId);
  }, [getCurrentWeek]);

  useEffect(() => {
    if (!isLoading && scrollAreaRef.current && activeWeeks.length > 0) {
      const currentWeekNumber = currentWeek || getCurrentWeek();
      const currentWeekIndex = activeWeeks.indexOf(currentWeekNumber);
      if (currentWeekIndex !== -1) {
        // Calculate the height of each week row (approximately 32px)
        const weekHeight = 32;
        // Calculate scroll position to center current week
        const scrollPosition = (currentWeekIndex * weekHeight) - (scrollAreaRef.current.clientHeight / 2) + (weekHeight / 2);
        scrollAreaRef.current.scrollTop = Math.max(0, scrollPosition);
      }
    }
  }, [isLoading, activeWeeks, currentWeek, getCurrentWeek]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const weekNum = parseInt(formData.weekNumber);
    
    if (weekNum && formData.day && formData.event) {
      const existingEventsForDay = events.filter(
        event => event.week === weekNum && event.day === formData.day
      );

      if (existingEventsForDay.length >= 20) {
        toast({
          title: "Too many events",
          description: "You can't add more than 20 events to a single day",
          variant: "destructive"
        });
        return;
      }

      const success = await addEvent(weekNum, formData.day, formData.event);
      if (success) {
        setFormData({ weekNumber: "", day: "", event: "", status: "pending" });
        toast({
          title: "Event added",
          description: "Calendar event was added successfully"
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to add event",
          variant: "destructive"
        });
      }
    }
  };

  const handleStatusChange = (week: number, day: string, text: string) => {
    updateEventStatus(week, day, text);
  };
  
  return (
    <Card className="h-fit relative border shadow-sm">
      <CardHeader className="bg-gray-50/80 border-b p-2 sticky top-0 z-10">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <CalendarDays className="w-5 h-5 text-gray-600" />
            <CardTitle className={`${isMobile ? 'text-base' : 'text-lg'} font-medium text-gray-700`}>
              Weekly Calendar
              <span className="ml-2 text-sm text-gray-500 font-normal">Week {currentWeek}</span>
            </CardTitle>
          </div>
          <div className="flex items-center gap-2">
            <ToggleGroup 
              type="multiple" 
              value={showWeekend}
              onValueChange={(value) => {
                const newValue = Array.isArray(value) ? value : [];
                setShowWeekend(newValue);
              }}
              className="bg-white border rounded-lg p-0.5 shadow-sm"
            >
              <ToggleGroupItem 
                value="sat" 
                size="sm" 
                className={`text-xs px-3 py-1.5 ${isMobile ? 'w-10' : 'w-12'} data-[state=on]:bg-[#333333] data-[state=on]:text-white rounded transition-colors`}
              >
                Sat
              </ToggleGroupItem>
              <ToggleGroupItem 
                value="sun" 
                size="sm" 
                className={`text-xs px-3 py-1.5 ${isMobile ? 'w-10' : 'w-12'} data-[state=on]:bg-[#333333] data-[state=on]:text-white rounded transition-colors`}
              >
                Sun
              </ToggleGroupItem>
            </ToggleGroup>
            <Button
              variant="ghost"
              size="icon"
              className="hover:bg-gray-100"
              onClick={() => setShowMonthView(!showMonthView)}
            >
              {showMonthView ? (
                <CalendarRange className="h-5 w-5 text-gray-600" />
              ) : (
                <CalendarIcon className="h-5 w-5 text-gray-600" />
              )}
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardContent className="p-0 flex flex-col">
        {showMonthView ? (
          <MonthlyCalendarView
            date={date}
            setDate={setDate}
            sharedCalendarClassNames={{
              months: "flex flex-col sm:flex-row space-y-4 sm:space-x-24 sm:space-y-0",
              month: "space-y-0",
              caption: "flex justify-start relative items-center text-[0.65rem] h-4",
              caption_label: "text-[0.65rem] font-medium",
              nav: "hidden",
              nav_button: "h-4 w-4 bg-transparent p-0 opacity-50 hover:opacity-100",
              table: "w-full border-collapse space-y-0",
              head_row: "flex",
              head_cell: "text-gray-500 w-6 font-normal text-[0.55rem] text-center",
              row: "flex w-full mt-0",
              cell: "h-4 w-6 text-left text-[0.65rem] p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md",
              day: "h-4 w-6 p-0 font-normal aria-selected:opacity-100 hover:bg-gray-100 rounded-sm",
              day_today: "bg-accent text-accent-foreground",
              day_outside: "text-gray-400 opacity-50",
              day_disabled: "text-gray-400 opacity-50",
              day_hidden: "invisible",
              day_selected: "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
              day_range_middle: "aria-selected:bg-accent aria-selected:text-accent-foreground",
              day_range_end: "aria-selected:bg-accent aria-selected:text-accent-foreground",
            }}
          />
        ) : (
          <div className="flex flex-col h-[300px]">
            <div className="sticky top-0 z-10 bg-white w-full">
              <CalendarEventForm 
                formData={formData}
                setFormData={setFormData}
                handleSubmit={handleSubmit}
                weekDays={weekDays}
              />
              <div className="border-b">
                <div className="w-full">
                  <table className="w-full border-collapse table-fixed">
                    <thead>
                      <tr className="divide-x">
                        <th className="w-8 p-1 text-center bg-gray-50 font-medium text-gray-600 text-xs">
                          Wk
                        </th>
                        {weekDays.map((day) => (
                          <th 
                            key={day} 
                            className="p-1 text-center bg-gray-50 font-medium text-gray-600 text-xs"
                          >
                            {day}
                          </th>
                        ))}
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
            <ScrollArea className="flex-1" ref={scrollAreaRef}>
              <div className="flex-1">
                <CalendarTable 
                  weekDays={weekDays}
                  activeWeeks={activeWeeks}
                  events={events}
                  handleDeleteEvent={deleteEvent}
                  handleStatusChange={handleStatusChange}
                  isLoading={isLoading}
                />
              </div>
            </ScrollArea>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default WeeklyCalendarPanel;
