
import { supabase } from "@/integrations/supabase/client";
import { FollowUp } from "@/types/followUp";

/**
 * Creates a new follow-up
 */
export async function createFollowUp(followUpData: Omit<FollowUp, 'id'>) {
  console.log('Attempting to create follow-up with data:', JSON.stringify(followUpData, null, 2));
  
  try {
    // Get a valid stage name
    const stageName = followUpData.stage || 'Intro';
    
    // Create a unique identifier for this follow-up to avoid conflicts
    const timestamp = new Date().toISOString();
    const uniqueData = {
      ...followUpData,
      created_at: timestamp
    };
    
    // First approach: Direct insert with the unique timestamp
    const { data, error } = await supabase
      .from('follow_ups')
      .insert([uniqueData])
      .select()
      .single();
      
    if (!error) {
      console.log('Successfully created follow-up:', data);
      return data;
    }
    
    // If there was an error, check if it's a conflict
    if (error.code === '23505') {
      console.log('Conflict detected, trying with a more unique timestamp');
      
      // Try again with an even more unique timestamp (add random number)
      const moreUniqueData = {
        ...followUpData,
        created_at: `${timestamp}-${Math.random().toString(36).substring(2, 10)}`
      };
      
      const { data: retryData, error: retryError } = await supabase
        .from('follow_ups')
        .insert([moreUniqueData])
        .select()
        .single();
        
      if (!retryError) {
        console.log('Successfully created follow-up on retry:', retryData);
        return retryData;
      }
      
      console.log('Still experiencing conflict, final fallback attempt');
    }
    
    // Get the base URL and key for the RPC call
    // Instead of using protected properties, get them from the config
    const supabaseUrl = import.meta.env.VITE_SUPABASE_URL || "https://xdhkcgssdbisyhgweaql.supabase.co";
    const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY || "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhkaGtjZ3NzZGJpc3loZ3dlYXFsIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzQwMjg4NjgsImV4cCI6MjA0OTYwNDg2OH0.lWeYbpX4PcpylMOchO4Rue8e352Yjeq8T5LDKF_MeqE";
    
    // Final fallback - use RPC function that's designed to bypass constraints
    const response = await fetch(`${supabaseUrl}/rest/v1/rpc/create_follow_up_safely`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'apikey': supabaseKey,
        'Authorization': `Bearer ${supabaseKey}`,
        'Prefer': 'return=representation'
      },
      body: JSON.stringify({
        p_client_id: followUpData.client_id,
        p_stage: stageName,
        p_user_id: followUpData.user_id,
        p_action: followUpData.action,
        p_description: followUpData.description,
        p_next_step: followUpData.next_step || 'Review',
        p_status: followUpData.status || 'pending',
        p_priority: followUpData.priority || 'medium',
        p_next_step_date: followUpData.next_step_date,
        p_next_step_week: followUpData.next_step_week,
        p_industry: followUpData.industry,
        p_custom_status: followUpData.custom_status,
        p_date: followUpData.date
      })
    });
    
    if (response.ok) {
      const fallbackData = await response.json();
      console.log('Successfully created follow-up with RPC method:', fallbackData);
      return fallbackData;
    } else {
      const errorText = await response.text();
      console.error('Error with RPC method:', errorText);
      throw new Error('Failed to create follow-up after all attempts');
    }
  } catch (err: any) {
    console.error('Error in createFollowUp:', err);
    throw err;
  }
}
