import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { FollowUp, Status, Priority } from "@/types/followUp";
import FormDateIndustry from "./form/FormDateIndustry";
import FormStatusPriority from "./form/FormStatusPriority";
import FormActionDescription from "./form/FormActionDescription";
import FormNextStep from "./form/FormNextStep";
import { Loader2 } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";

interface FollowUpFormProps {
  onSave: (followUp: Omit<FollowUp, 'id'>) => Promise<void>;
  onCancel: () => void;
  editingFollowUp: FollowUp | null;
  isSubmitting?: boolean;
}

const FollowUpForm = ({ 
  onSave, 
  onCancel, 
  editingFollowUp,
  isSubmitting = false 
}: FollowUpFormProps) => {
  const [action, setAction] = useState("");
  const [description, setDescription] = useState("");
  const [nextStep, setNextStep] = useState("");
  const [nextStepDate, setNextStepDate] = useState("");
  const [nextStepWeek, setNextStepWeek] = useState("");
  const [status, setStatus] = useState<Status>("pending");
  const [priority, setPriority] = useState<Priority>("medium");
  const [stage, setStage] = useState("Intro");
  const [industry, setIndustry] = useState("");
  const [industries, setIndustries] = useState<string[]>([]);
  const [customStatus, setCustomStatus] = useState<string | null>(null);
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]); // Current date as default
  
  useEffect(() => {
    if (editingFollowUp) {
      setAction(editingFollowUp.action);
      setDescription(editingFollowUp.description);
      setNextStep(editingFollowUp.next_step);
      setNextStepDate(editingFollowUp.next_step_date || "");
      setNextStepWeek(editingFollowUp.next_step_week ? String(editingFollowUp.next_step_week) : "");
      setStatus(editingFollowUp.status);
      setPriority(editingFollowUp.priority);
      setStage(editingFollowUp.stage);
      setIndustry(editingFollowUp.industry || "");
      setCustomStatus(editingFollowUp.custom_status || null);
      setDate(editingFollowUp.date);
    } else {
      setAction("");
      setDescription("");
      setNextStep("");
      setNextStepDate("");
      setNextStepWeek("");
      setStatus("pending");
      setPriority("medium");
      setStage("Intro");
      setIndustry("");
      setCustomStatus(null);
      setDate(new Date().toISOString().split('T')[0]);
    }
  }, [editingFollowUp]);
  
  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;
        
        const { data, error } = await supabase
          .from('industries')
          .select('name')
          .eq('user_id', user.id);
          
        if (error) throw error;
        
        if (data && data.length > 0) {
          const industryNames = data.map(i => i.name).filter(Boolean);
          setIndustries(industryNames);
        }
      } catch (error) {
        console.error("Error fetching industries:", error);
      }
    };
    
    fetchIndustries();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!stage) {
      console.error("Stage is required");
      return;
    }
    
    console.log("Submitting form with values:", {
      action, description, nextStep, nextStepDate, nextStepWeek, status, priority, stage, industry, date
    });
    
    try {
      await onSave({
        action,
        description,
        next_step: nextStep,
        next_step_date: nextStepDate || null,
        next_step_week: nextStepWeek ? parseInt(nextStepWeek, 10) : null,
        status,
        priority,
        stage,
        industry: industry || null,
        custom_status: null,
        date: date,
        client_id: 0,
        user_id: "",
        created_at: new Date().toISOString()
      });
    } catch (error) {
      console.error("Error saving follow-up:", error);
    }
  };
  
  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <FormActionDescription
        action={action}
        description={description}
        onActionChange={setAction}
        onDescriptionChange={setDescription}
      />
      
      <FormDateIndustry
        date={date}
        industry={industry || ''}
        industries={industries}
        onDateChange={setDate}
        onIndustryChange={setIndustry}
      />
      
      <FormStatusPriority
        status={status}
        priority={priority}
        customStatus={customStatus}
        onStatusChange={setStatus}
        onPriorityChange={setPriority}
        onCustomStatusChange={setCustomStatus}
      />
      
      <FormNextStep
        nextStep={nextStep}
        nextStepDate={nextStepDate}
        nextStepWeek={nextStepWeek}
        onNextStepChange={setNextStep}
        onNextStepDateChange={setNextStepDate}
        onNextStepWeekChange={setNextStepWeek}
      />
      
      <div className="flex justify-end space-x-2 pt-2">
        <Button 
          type="button" 
          variant="outline"
          onClick={onCancel}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button 
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Saving...
            </>
          ) : (
            'Save'
          )}
        </Button>
      </div>
    </form>
  );
};

export default FollowUpForm;
