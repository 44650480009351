
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Bell, Calendar } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";

interface Remind {
  id: string;
  next_step: string;
  next_step_date: string | null;
  next_step_week: number | null;
  client_name: string;
  status: string;
  industry: string | null;
  follow_up_id: string | null;
  client_id: number | null;
}

interface RemindsPanelProps {
  selectedIndustry?: string;
  onClientSelect?: (clientId: number) => void;
}

const RemindsPanel = ({ selectedIndustry, onClientSelect }: RemindsPanelProps) => {
  const [reminds, setReminds] = useState<Remind[]>([]);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editValue, setEditValue] = useState<string>("");
  const { toast } = useToast();

  const fetchReminds = async () => {
    if (!selectedIndustry) {
      setReminds([]);
      return;
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('No user found');
        return;
      }

      const selectedIndustries = selectedIndustry.split(',').map(industry => industry.trim());

      let query = supabase
        .from('reminds')
        .select(`
          *,
          follow_ups!inner (
            client_id
          )
        `)
        .eq('status', 'pending');

      query = query.or(
        selectedIndustries.map(industry => `industry.ilike.%${industry}%`).join(',')
      );

      const { data, error } = await query;

      if (error) {
        console.error('Error fetching reminds:', error);
        toast({
          title: "Error",
          description: "Failed to fetch reminds",
          variant: "destructive",
        });
        return;
      }

      if (data) {
        console.log('Fetched reminds:', data);
        const remindsWithClientId = data.map(remind => ({
          ...remind,
          client_id: remind.follow_ups?.client_id || null
        }));

        // Sort the reminds by week/date
        const sortedReminds = remindsWithClientId.sort((a, b) => {
          // Convert dates to timestamps for comparison
          const getTimestamp = (remind: Remind) => {
            if (remind.next_step_date) {
              return new Date(remind.next_step_date).getTime();
            }
            if (remind.next_step_week) {
              // Convert week to approximate date (start of the week)
              const currentYear = new Date().getFullYear();
              return new Date(currentYear, 0, (remind.next_step_week) * 7).getTime();
            }
            return Infinity; // Items without date/week go to the end
          };

          return getTimestamp(a) - getTimestamp(b);
        });

        setReminds(sortedReminds);
      }
    } catch (error) {
      console.error('Error in fetchReminds:', error);
      toast({
        title: "Error",
        description: "Failed to fetch reminds",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    console.log('Selected industry changed:', selectedIndustry);
    fetchReminds();

    const channel = supabase
      .channel('reminds-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'reminds'
        },
        (payload) => {
          console.log('Reminds changed:', payload);
          fetchReminds();
        }
      )
      .subscribe((status) => {
        console.log('Subscription status:', status);
      });

    const industriesChannel = supabase
      .channel('industries-updates')
      .on(
        'postgres_changes',
        {
          event: 'UPDATE',
          schema: 'public',
          table: 'industries'
        },
        (payload) => {
          console.log('Industries updated:', payload);
          fetchReminds();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
      supabase.removeChannel(industriesChannel);
    };
  }, [selectedIndustry]);

  const handleRemindClick = (remind: Remind) => {
    if (remind.client_id && onClientSelect) {
      onClientSelect(remind.client_id);
    }
  };

  const handleDateDoubleClick = (remind: Remind) => {
    setEditingId(remind.id);
    if (remind.next_step_date) {
      setEditValue(remind.next_step_date);
    } else if (remind.next_step_week) {
      setEditValue(remind.next_step_week.toString());
    }
  };

  const handleDateSave = async (remind: Remind) => {
    try {
      let updateData: { next_step_date?: string | null; next_step_week?: number | null } = {};
      
      // Check if the input is a valid week number
      const weekNumber = parseInt(editValue);
      if (!isNaN(weekNumber) && weekNumber > 0 && weekNumber <= 53) {
        updateData = {
          next_step_week: weekNumber,
          next_step_date: null
        };
      } else {
        // Try to parse as a date
        const date = new Date(editValue);
        if (!isNaN(date.getTime())) {
          updateData = {
            next_step_date: date.toISOString().split('T')[0],
            next_step_week: null
          };
        } else {
          toast({
            title: "Invalid Input",
            description: "Please enter a valid date (MM/DD/YY) or week number (1-53)",
            variant: "destructive",
          });
          return;
        }
      }

      const { error } = await supabase
        .from('reminds')
        .update(updateData)
        .eq('id', remind.id);

      if (error) {
        throw error;
      }

      toast({
        title: "Success",
        description: "Date updated successfully",
      });
    } catch (error) {
      console.error('Error updating date:', error);
      toast({
        title: "Error",
        description: "Failed to update date",
        variant: "destructive",
      });
    } finally {
      setEditingId(null);
      setEditValue("");
    }
  };

  const formatWhen = (remind: Remind) => {
    if (remind.next_step_date) {
      // Format to MM/DD/YY - get the date parts
      const date = new Date(remind.next_step_date);
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const year = date.getFullYear().toString().slice(2); // Get last 2 digits of year
      return `${month}/${day}/${year}`;
    }
    if (remind.next_step_week) {
      return `Wk ${remind.next_step_week}`;
    }
    return "-";
  };

  const truncateNextStep = (text: string) => {
    if (text.length <= 18) return text;
    return text.slice(0, 18) + "...";
  };

  return (
    <Card className="h-fit relative border shadow-sm">
      <CardHeader className="bg-gray-50/80 border-b py-2">
        <div className="flex items-center gap-2">
          <Bell className="w-5 h-5 text-gray-500" />
          <span className="panel-title">Reminders</span>
          {selectedIndustry && (
            <span className="text-xs text-gray-500">({selectedIndustry})</span>
          )}
        </div>
      </CardHeader>
      <CardContent className="p-2">
        <ScrollArea className="h-[338px]">
          <div className="space-y-1 pr-4">
            {!selectedIndustry ? (
              <p className="text-xs text-gray-500 text-center py-2">Select an industry to view reminders</p>
            ) : reminds.length === 0 ? (
              <p className="text-xs text-gray-500 text-center py-2">No reminders to display</p>
            ) : (
              <>
                {reminds.map((remind) => (
                  <div
                    key={remind.id}
                    className="py-0.5 px-2 hover:bg-gray-50 rounded-sm relative pl-4"
                  >
                    <div className="absolute left-1 top-[0.35rem] w-2.5 h-2.5 rounded-full bg-yellow-400" />
                    <div className="grid grid-cols-[1fr,2fr,1fr] gap-2 items-baseline">
                      <span className="text-xs text-gray-600 truncate text-left">
                        {remind.client_name}
                      </span>
                      <div className="flex items-start gap-1">
                        {remind.client_id ? (
                          <button 
                            onClick={() => onClientSelect?.(remind.client_id as number)}
                            className="text-xs text-gray-600 leading-none hover:underline text-left"
                            title={remind.next_step}
                          >
                            {truncateNextStep(remind.next_step)}
                          </button>
                        ) : (
                          <p 
                            className="text-xs text-gray-600 leading-none text-left"
                            title={remind.next_step}
                          >
                            {truncateNextStep(remind.next_step)}
                          </p>
                        )}
                      </div>
                      <div className="flex items-center gap-1 justify-end">
                        <Calendar className="w-3 h-3 text-gray-400" />
                        {editingId === remind.id ? (
                          <Input
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            onBlur={() => handleDateSave(remind)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleDateSave(remind);
                              } else if (e.key === 'Escape') {
                                setEditingId(null);
                                setEditValue("");
                              }
                            }}
                            className="h-5 text-xs px-1 py-0 w-24"
                            autoFocus
                          />
                        ) : (
                          <span 
                            className="text-xs text-gray-600 leading-none cursor-pointer hover:underline"
                            onDoubleClick={() => handleDateDoubleClick(remind)}
                          >
                            {formatWhen(remind)}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </ScrollArea>
      </CardContent>
    </Card>
  );
};

export default RemindsPanel;
