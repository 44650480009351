
import { useDashboardState } from "@/hooks/useDashboardState";
import DashboardHeader from "@/components/dashboard/DashboardHeader";
import MobileDashboard from "@/components/dashboard/MobileDashboard";
import DesktopDashboard from "@/components/dashboard/DesktopDashboard";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const Index = () => {
  const {
    selectedClient,
    selectedIndustry,
    activePanel,
    handleClientSelect,
    handleClientSelectFromRemind,
    handleRefresh,
    fetchClientById,
    setSelectedIndustry,
    setActivePanel
  } = useDashboardState();

  // Track if we've processed the initial scroll
  const hasScrolledRef = useRef(false);
  const location = useLocation();

  // Basic URL parameter handling
  useEffect(() => {
    // Extract query parameters manually from location.search
    const params = new URLSearchParams(location.search);
    const showFollowUp = params.get('followUp') === 'true';
    const clientId = params.get('clientId');
    
    if (showFollowUp && clientId && !hasScrolledRef.current) {
      console.log('Index: URL params indicate follow-up should be shown for client', clientId);
      
      // Convert clientId to number
      const clientIdNum = parseInt(clientId, 10);
      if (!isNaN(clientIdNum)) {
        // Set active panel immediately
        setActivePanel('follow-up');
        
        // Fetch the client data
        fetchClientById(clientIdNum);
        
        // Mark as scrolled
        hasScrolledRef.current = true;
      }
    }
  }, [location.search, setActivePanel, fetchClientById]);

  // Simple event listener for follow-up panel scrolling
  useEffect(() => {
    const handleScrollRequest = (event) => {
      const panel = document.getElementById('follow-up-panel');
      if (panel) {
        panel.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };

    window.addEventListener('scroll-to-followup', handleScrollRequest);
    
    return () => {
      window.removeEventListener('scroll-to-followup', handleScrollRequest);
    };
  }, []);

  return (
    <div className="min-h-screen bg-background">
      <DashboardHeader onRefresh={handleRefresh} />
      <div className="container mx-auto p-4">
        <MobileDashboard 
          selectedClient={selectedClient}
          selectedIndustry={selectedIndustry}
          activePanel={activePanel}
          onClientSelect={handleClientSelect}
          onIndustrySelect={setSelectedIndustry}
          onClientSelectFromRemind={handleClientSelectFromRemind}
        />
        
        <DesktopDashboard 
          selectedClient={selectedClient}
          selectedIndustry={selectedIndustry}
          activePanel={activePanel}
          onClientSelect={handleClientSelect}
          onIndustrySelect={setSelectedIndustry}
          onClientSelectFromRemind={handleClientSelectFromRemind}
        />
      </div>
    </div>
  );
};

export default Index;
