
import { supabase } from "@/integrations/supabase/client";

/**
 * Deletes a follow-up and its associated reminds
 */
export async function deleteFollowUp(id: string) {
  console.log('Attempting to delete follow-up:', id);
  
  // First, delete associated reminds to avoid foreign key constraints
  try {
    const { error: remindsError } = await supabase
      .from('reminds')
      .delete()
      .eq('follow_up_id', id);

    if (remindsError) {
      console.error('Error deleting associated reminds:', remindsError.message, remindsError.details);
      throw remindsError;
    }

    // Then delete the follow-up itself
    const { error } = await supabase
      .from('follow_ups')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting follow-up:', error.message, error.details);
      throw error;
    }
    
    console.log('Follow-up deleted successfully');
    return true;
  } catch (error) {
    console.error('Exception in deleteFollowUp:', error);
    throw error;
  }
}
