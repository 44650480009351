
import { Priority } from "@/types/followUp";

export const getPriorityColor = (priority: Priority) => {
  switch (priority) {
    case "high":
      return "bg-red-500";
    case "medium":
      return "bg-yellow-500";
    case "low":
      return "bg-green-500";
    case "none":
      return "bg-gray-400";
    default:
      return "bg-gray-400";
  }
};

export const actionOptions = [
  "Call",
  "Email",
  "Meeting",
  "Follow-up",
  "Quote",
  "Proposal",
  "Contract",
  "Other"
];

export const statusOptions = ["completed", "pending"] as const;
export const priorityOptions = ["high", "medium", "low", "none"] as const;
