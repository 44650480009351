import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { X } from "lucide-react";
import { BasicInfoFields } from "./form/BasicInfoFields";
import { ContactFields } from "./form/ContactFields";
import { AddressFields } from "./form/AddressFields";
import { CompanyContactsFields } from "./form/CompanyContactsFields";
import { Client } from "@/types/client";

interface ClientFormProps {
  onSuccess: () => void;
  onCancel: () => void;
  initialData?: Client;
}

interface Contact {
  name: string;
  phone: string;
  mobile: string;
  email: string;
}

const ClientForm = ({ onSuccess, onCancel, initialData }: ClientFormProps) => {
  const { toast } = useToast();
  const [formData, setFormData] = useState({
    company: initialData?.company || "",
    contact: initialData?.contact || "",
    phone: initialData?.phone || "",
    mobile: initialData?.mobile || "",
    email: initialData?.email || "",
    web: initialData?.web || "",
    address: initialData?.address || "",
    city: initialData?.city || "",
    state: initialData?.state || "",
    zip: initialData?.zip || "",
    notes: initialData?.notes || "",
    industry: initialData?.industry || "",
    selectedIndustries: [] as string[],
  });

  const [additionalContacts, setAdditionalContacts] = useState<Contact[]>([]);

  // Fetch existing additional contacts and industries when editing
  useEffect(() => {
    const fetchData = async () => {
      if (!initialData?.id) return;

      try {
        console.log('Fetching data for client:', initialData.id);
        // Fetch contacts
        const { data: contactsData, error: contactsError } = await supabase
          .from('company_contacts')
          .select('*')
          .eq('client_id', initialData.id);

        if (contactsError) throw contactsError;

        if (contactsData) {
          console.log('Fetched contacts:', contactsData);
          const contacts = contactsData.map(contact => ({
            name: contact.name || '',
            phone: contact.phone || '',
            mobile: contact.mobile || '',
            email: contact.email || ''
          }));
          setAdditionalContacts(contacts);
        }

        // Fetch industries
        const { data: industriesData, error: industriesError } = await supabase
          .from('client_industries')
          .select('industry_name')
          .eq('client_id', initialData.id);

        if (industriesError) throw industriesError;

        if (industriesData) {
          console.log('Fetched industries:', industriesData);
          const industries = industriesData.map(i => i.industry_name);
          setFormData(prev => ({
            ...prev,
            selectedIndustries: industries
          }));
        }
      } catch (error: any) {
        console.error('Error fetching data:', error);
        toast({
          title: "Error",
          description: "Failed to load client data",
          variant: "destructive",
        });
      }
    };

    fetchData();
  }, [initialData?.id]);

  const handleChange = (field: string, value: string | string[]) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Submitting form with data:', formData);
    console.log('Additional contacts:', additionalContacts);
    
    // Only validate industry selection for new clients
    if (!initialData && !formData.selectedIndustries.length) {
      toast({
        title: "Error",
        description: "Please select at least one industry for new clients",
        variant: "destructive",
      });
      return;
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No user found");

      let clientId: number;

      // Set primary industry as the first selected industry
      const clientData = {
        ...formData,
        industry: formData.selectedIndustries.length > 0 
          ? formData.selectedIndustries[0] 
          : initialData?.industry || "",
      };
      delete clientData.selectedIndustries;

      if (initialData) {
        console.log('Updating existing client:', initialData.id);
        // Update existing client
        const { error } = await supabase
          .from("clients")
          .update(clientData)
          .eq('id', initialData.id);

        if (error) throw error;
        clientId = initialData.id;

        console.log('Deleting existing contacts and industries');
        // Delete existing additional contacts and industries
        const [contactsDeleteError, industriesDeleteError] = await Promise.all([
          supabase
            .from("company_contacts")
            .delete()
            .eq('client_id', initialData.id)
            .then(res => res.error),
          supabase
            .from("client_industries")
            .delete()
            .eq('client_id', initialData.id)
            .then(res => res.error)
        ]);

        if (contactsDeleteError) throw contactsDeleteError;
        if (industriesDeleteError) throw industriesDeleteError;

        toast({
          title: "Success",
          description: "Client updated successfully",
        });
      } else {
        console.log('Creating new client');
        // Add new client
        const { data: newClientData, error } = await supabase
          .from("clients")
          .insert({
            ...clientData,
            user_id: user.id,
            created_at: new Date().toISOString(),
          })
          .select()
          .single();

        if (error) throw error;
        if (!newClientData) throw new Error("Failed to create client");
        
        clientId = newClientData.id;
        console.log('New client created with ID:', clientId);

        toast({
          title: "Success",
          description: "Client added successfully",
        });
      }

      // Handle additional contacts
      if (additionalContacts.length > 0) {
        console.log('Inserting additional contacts:', additionalContacts);
        const { error: contactsError } = await supabase
          .from("company_contacts")
          .insert(
            additionalContacts.map(contact => ({
              client_id: clientId,
              user_id: user.id,
              ...contact
            }))
          );

        if (contactsError) throw contactsError;
      }

      // Handle industries
      if (formData.selectedIndustries.length > 0) {
        console.log('Inserting industries:', formData.selectedIndustries);
        const { error: industriesError } = await supabase
          .from("client_industries")
          .insert(
            formData.selectedIndustries.map(industry => ({
              client_id: clientId,
              industry_name: industry,
              user_id: user.id
            }))
          );

        if (industriesError) throw industriesError;
      }
      
      onSuccess();
    } catch (error: any) {
      console.error("Error saving client:", error);
      toast({
        title: "Error",
        description: error.message || "Failed to save client",
        variant: "destructive",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-1.5">
      <div className="flex justify-between items-center mb-1">
        <h3 className="text-sm font-medium">
          {initialData ? 'Edit Client' : 'Add New Client'}
        </h3>
        <Button 
          type="button" 
          variant="ghost" 
          size="icon"
          onClick={onCancel}
          className="h-5 w-5"
        >
          <X className="h-3 w-3" />
        </Button>
      </div>

      <div className="max-h-[calc(100vh-12rem)] overflow-y-auto pr-2">
        <div className="grid grid-cols-2 gap-1.5">
          <BasicInfoFields formData={formData} onChange={handleChange} />
          <ContactFields formData={formData} onChange={handleChange} />
          <AddressFields formData={formData} onChange={handleChange} />

          <div className="col-span-2">
            <Label htmlFor="notes" className="text-xs">Notes</Label>
            <Textarea
              id="notes"
              value={formData.notes}
              onChange={(e) => handleChange("notes", e.target.value)}
              className="h-16 min-h-[4rem] text-xs resize-none"
            />
          </div>

          <div className="col-span-2">
            <CompanyContactsFields
              contacts={additionalContacts}
              onChange={setAdditionalContacts}
            />
          </div>
        </div>
      </div>

      <div className="flex gap-1.5 justify-end pt-1.5 border-t mt-1.5">
        <Button type="button" variant="outline" onClick={onCancel} className="h-7 text-xs">
          Cancel
        </Button>
        <Button type="submit" className="h-7 text-xs">
          {initialData ? 'Save Changes' : 'Add Client'}
        </Button>
      </div>
    </form>
  );
};

export default ClientForm;