import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Checkbox } from "@/components/ui/checkbox";
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";

interface BasicInfoFieldsProps {
  formData: {
    company: string;
    contact: string;
    industry: string;
    selectedIndustries?: string[];
  };
  onChange: (field: string, value: string | string[]) => void;
}

export const BasicInfoFields = ({ formData, onChange }: BasicInfoFieldsProps) => {
  const [industries, setIndustries] = useState<{ name: string; color: string; }[]>([]);

  useEffect(() => {
    const fetchIndustries = async () => {
      const { data: industriesData, error } = await supabase
        .from('industries')
        .select('name, color')
        .order('name');
      
      if (error) {
        console.error('Error fetching industries:', error);
        return;
      }

      if (industriesData) {
        setIndustries(industriesData);
      }
    };

    fetchIndustries();

    const channel = supabase
      .channel('industries-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'industries'
        },
        () => {
          fetchIndustries();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  const handleIndustryToggle = (industryName: string) => {
    const currentIndustries = formData.selectedIndustries || [];
    const updatedIndustries = currentIndustries.includes(industryName)
      ? currentIndustries.filter(i => i !== industryName)
      : [...currentIndustries, industryName];
    
    onChange("selectedIndustries", updatedIndustries);
    // Keep the primary industry field for backward compatibility
    if (updatedIndustries.length > 0 && !formData.industry) {
      onChange("industry", updatedIndustries[0]);
    }
  };

  return (
    <>
      <div className="col-span-2">
        <Label htmlFor="industries" className="text-xs after:content-['*'] after:ml-0.5 after:text-red-500">Industries</Label>
        <div className="border rounded-md mt-1">
          <ScrollArea className="h-[100px] w-full">
            <div className="p-2 space-y-1.5">
              {industries.map((industry) => (
                <div key={industry.name} className="flex items-center space-x-2">
                  <Checkbox
                    id={`industry-${industry.name}`}
                    checked={(formData.selectedIndustries || []).includes(industry.name)}
                    onCheckedChange={() => handleIndustryToggle(industry.name)}
                  />
                  <label
                    htmlFor={`industry-${industry.name}`}
                    className="text-xs leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer"
                  >
                    <div className="flex items-center gap-1.5">
                      <div className="h-1.5 w-1.5 rounded-full bg-gray-400" />
                      {industry.name}
                    </div>
                  </label>
                </div>
              ))}
            </div>
          </ScrollArea>
        </div>
      </div>

      <div className="col-span-2">
        <Label htmlFor="company" className="text-xs">Company Name *</Label>
        <Input
          id="company"
          value={formData.company}
          onChange={(e) => onChange("company", e.target.value)}
          required
          className="h-7 text-xs"
        />
      </div>

      <div className="col-span-2">
        <Label htmlFor="contact" className="text-xs">Contact</Label>
        <Input
          id="contact"
          value={formData.contact}
          onChange={(e) => onChange("contact", e.target.value)}
          className="h-7 text-xs"
        />
      </div>
    </>
  );
};