
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { actionOptions } from "@/utils/followUpUtils";

interface FormActionDescriptionProps {
  action: string;
  description: string;
  onActionChange: (value: string) => void;
  onDescriptionChange: (value: string) => void;
}

const FormActionDescription = ({ 
  action, 
  description, 
  onActionChange, 
  onDescriptionChange 
}: FormActionDescriptionProps) => {
  return (
    <div className="space-y-3">
      <div className="space-y-1.5">
        <Label className="text-xs font-medium">Action Type</Label>
        <Select value={action} onValueChange={onActionChange}>
          <SelectTrigger className="h-8 text-sm">
            <SelectValue placeholder="Select action type" />
          </SelectTrigger>
          <SelectContent className="bg-white">
            {actionOptions.map((action) => (
              <SelectItem key={action} value={action}>
                {action}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="space-y-1.5">
        <Label className="text-xs font-medium">Description</Label>
        <Textarea
          value={description}
          onChange={(e) => onDescriptionChange(e.target.value)}
          placeholder="Enter follow-up description"
          rows={3}
          className="resize-none"
        />
      </div>
    </div>
  );
};

export default FormActionDescription;
